import React from "react";
import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Utility from "../../../utils/Utility";

export const StatementTemplate = (props: any) => {

    const { PDFData, fileName } = props;

    Font.register({
        family: "MS PGothic",
        src: `${process.env.PUBLIC_URL}/assets/fonts/NotoSansJP.ttf`,
    });

    const utility = new Utility();
    const titleTextSize = 16; 
    let headingTextSize;
    let bodyTextSize;
    let tableTextSize;
    let pointsTextSize;
    let statementDateMarginLeft;
    let statementBuisnessMarginLeft;
    let statementBusinessNumberMarginLeft;

    function isAlphanumeric(str:any) {
        return /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]*$/.test(str);
    }

    // Split the corporateName long text into an array of smaller chunks
    const corporateName = PDFData?.corporateName || ""; 
    const chunksCorporateName = [];
    let chunkSize;

    if(isAlphanumeric(corporateName)){
        if(corporateName.length <= 108){
            headingTextSize = 12;
            bodyTextSize = 10.5;
            tableTextSize = 10.5;
            pointsTextSize = 10.5;
            chunkSize = 27;
            statementDateMarginLeft = 385;
            statementBuisnessMarginLeft = 60;
            statementBusinessNumberMarginLeft = 5;
        }else if(corporateName.length <= 162){
            headingTextSize = 12;
            bodyTextSize = 9.5;
            tableTextSize = 9.5;
            pointsTextSize = 9.5;
            chunkSize = 27;
            statementDateMarginLeft = 395;
            statementBuisnessMarginLeft = 58;
            statementBusinessNumberMarginLeft = 7;
        }else{
            headingTextSize = 11;
            bodyTextSize = 9.5;
            tableTextSize = 9.5;
            pointsTextSize = 9.5;
            chunkSize = 32;
            statementDateMarginLeft = 395;
            statementBuisnessMarginLeft = 58;
            statementBusinessNumberMarginLeft = 7;
        }
    }else{
        if(corporateName.length <= 102){
            headingTextSize = 12;
            bodyTextSize = 10.5;
            tableTextSize = 10.5;
            pointsTextSize = 10.5;
            chunkSize = 26;
            statementDateMarginLeft = 385;
            statementBuisnessMarginLeft = 60;
            statementBusinessNumberMarginLeft = 5;
        }else if(corporateName.length <= 154){
            headingTextSize = 12;
            bodyTextSize = 9.5;
            tableTextSize = 9.5;
            pointsTextSize = 9.5;
            chunkSize = 26;
            statementDateMarginLeft = 395;
            statementBuisnessMarginLeft = 57;
            statementBusinessNumberMarginLeft = 5;
        }else{
            headingTextSize = 11;
            bodyTextSize = 9.5;
            tableTextSize = 9.5;
            pointsTextSize = 9.5;
            chunkSize = 29;
            statementDateMarginLeft = 395;
            statementBuisnessMarginLeft = 57;
            statementBusinessNumberMarginLeft = 5;
        }
    }
    
    for (let i = 0; i < corporateName.length; i += chunkSize) {
        chunksCorporateName.push(corporateName.substring(i, i + chunkSize));
    }

    const styles = StyleSheet.create({
        page: { fontSize: tableTextSize, padding: 60, lineHeight: 1.5, flexDirection: "column", fontFamily: "MS PGothic" },
        spaceBetween: { flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between", color: "#3E3E3E" },
        titleContainer: { flexDirection: "row", marginTop: 15 },
        bold: { fontWeight: 900 },
        verticalAlign: { paddingTop: 2, paddingLeft: 2, position: "absolute" },
        textAlign: { right: 2 },
        statementTitle: { fontSize: titleTextSize, textAlign: "center", fontFamily: "MS PGothic" },
        statementInvoice: { fontSize: bodyTextSize, textAlign: "center", fontFamily: "MS PGothic" },
        statementDate: { fontSize: bodyTextSize, marginLeft: statementDateMarginLeft, marginTop: -16, fontFamily: "MS PGothic" },
        statementCreator: { marginBottom: 10, fontSize: headingTextSize, lineHeight: "1.5" , textDecoration: "underline", fontFamily: "MS PGothic", maxWidth: "70%"},
        statementBuisness: { fontSize: bodyTextSize, marginLeft: statementBuisnessMarginLeft, fontFamily: "MS PGothic" },
        statementBusinessNumber: { fontSize: bodyTextSize, marginLeft: statementBusinessNumberMarginLeft, fontFamily: "MS PGothic" },
        statementSettlementPeriod: { fontSize: bodyTextSize, marginTop: -15, textDecoration: "underline", fontFamily: "MS PGothic" },
        statementFirstTableTitle: { fontSize: bodyTextSize, fontWeight: "bold", fontFamily: "MS PGothic" },
        statementSecondTableTitle: { fontSize: bodyTextSize, fontWeight: "bold", fontFamily: "MS PGothic" },
        statementThirdTableTitle: { fontSize: bodyTextSize, fontWeight: "bold", fontFamily: "MS PGothic" },
        theader: { marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderColor: "black", border: 1, fontFamily: "MS PGothic" },
        trow: { marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderColor: "black", borderLeft: 1, borderRight: 1, borderBottom: 1, fontFamily: "MS PGothic" }
    });

    return (
        <Document title={fileName}>
            <Page size="A4" style={styles.page}>
                <View style={styles.titleContainer}>
                    <View style={styles.spaceBetween}>
                        <Text style={styles.statementTitle}>{PDFData?.title}</Text>
                        <Text style={styles.statementInvoice}>請求書 No. {PDFData?.invoiceNumber}</Text>
                    </View>
                </View>
                <View style={styles.titleContainer}>
                    <View>
                        <Text style={styles.statementDate}>{PDFData?.issueDate}</Text>
                    </View>
                </View>
                <View style={styles.titleContainer}>
                    <View style={styles.spaceBetween}>
                        <Text style={styles.statementCreator}>
                            {chunksCorporateName.map((chunk, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && "\n"} {/* Add newline character if not the first chunk */}
                                    {chunk} {/* Render the chunk of text */}
                                </React.Fragment>
                            ))} 御中
                        </Text>
                        <View>
                            <Text style={styles.statementBuisness}>{PDFData?.businessName}</Text>
                            <Text style={styles.statementBusinessNumber}>事業者番号: {PDFData?.businessNumber}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.titleContainer}>
                    <View>
                        <Text style={styles.statementSettlementPeriod}>対象期間: {utility.getCustomDateYMDJP(PDFData?.settlementPeriod)}</Text>
                    </View>
                </View>

                <View style={styles.titleContainer}>
                    <View>
                        <Text style={styles.statementFirstTableTitle}>売上預り金明細</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.theader}>
                        <Text></Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderTop: 1, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>税込金額</Text>
                    </View>
                    <View style={styles.theader}>
                        <Text style={styles.verticalAlign}>消費税額</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>10%対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesAmountRegular)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesTaxAmountRegular)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>軽減 8%対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesAmountReduced)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesTaxAmountReduced)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>非課税対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesAmountNonTaxable)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesTaxAmountNonTaxable)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>送料(10%対象)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesAmountShipping)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.salesTaxAmountShipping)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>合計(税込)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.totalSalesAmount)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.salesDetail?.totalSalesTaxAmount)} 円</Text>
                    </View>
                </View>

                <View style={styles.titleContainer}>
                    <View>
                        <Text style={styles.statementSecondTableTitle}>返金分</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.theader}>
                        <Text ></Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderTop: 1, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>税込金額</Text>
                    </View>
                    <View style={styles.theader}>
                        <Text style={styles.verticalAlign}>消費税額</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>10%対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundAmountRegular)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundTaxAmountRegular)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>軽減 8%対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundAmountReduced)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundTaxAmountReduced)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>非課税対象</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundAmountNonTaxable)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundTaxAmountNonTaxable)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>送料(10%対象)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundAmountShipping)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.refundTaxAmountShipping)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "100%", flexDirection: "row", marginTop: -20 }}>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>合計(税込)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 1, height: 10, borderBottom: 1, borderColor: "black" }}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.totalRefundAmount)} 円</Text>
                    </View>
                    <View style={styles.trow}>
                        <Text style={styles.verticalAlign}>{utility.numberWithCommas(PDFData?.refundDetail?.totalRefundTaxAmount)} 円</Text>
                    </View>
                </View>

                <View style={styles.titleContainer}>
                    <View>
                        <Text style={styles.statementThirdTableTitle}>販売手数料</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", border: 1 }}>
                        <Text style={styles.verticalAlign}>販売手数料対象金額</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderTop: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.salesCommission?.totalRefundAmount)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
                        <Text style={styles.verticalAlign}>販売手数料(内税)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.salesCommission?.totalCainzFee)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
                        <Text style={styles.verticalAlign}>消費税額(10%)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.salesCommission?.totalCainzFeeTax)} 円</Text>
                    </View>
                </View>

                <View style={{ width: "75%", flexDirection: "row" }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", border: 1 }}>
                        <Text style={styles.verticalAlign}>注文金額合計(送料額込)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderTop: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.totalOrderAmount)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
                        <Text style={styles.verticalAlign}>ポイント付与額(不課税)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.grantedPointAmount)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
                        <Text style={styles.verticalAlign}>Cainz 販売手数料額(税込)</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.totalFeeAmount)} 円</Text>
                    </View>
                </View>
                <View style={{ width: "75%", flexDirection: "row", marginTop: -20 }}>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
                        <Text style={styles.verticalAlign}>入金額</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: tableTextSize, padding: 8, flex: 2, height: 10, borderColor: "black", borderRight: 1, borderBottom: 1 }}>
                        <Text style={[styles.verticalAlign, styles.textAlign]}>{utility.numberWithCommas(PDFData?.depositAmount)} 円</Text>
                    </View>
                </View>

                <View style={{ width: "90%", flexDirection: "row", marginTop: 20, fontSize: pointsTextSize }}>
                    <View>
                        <Text>(1) 販売手数料は、毎月月末締切、翌々月の月末支払より差引きさせて頂きます。</Text>
                    </View>
                </View>
                <View style={{ width: "90%", flexDirection: "row", fontSize: pointsTextSize }}>
                    <View>
                        <Text>(2)  お支払い金額は末日に、ご指定いただいております銀行口座にお振込み致します。</Text>
                    </View>
                </View>
                <View style={{ width: "90%", flexDirection: "row", marginLeft: 11, fontSize: pointsTextSize }}>
                    <View>
                        <Text>このとき振込手数料は、カインズの負担となります。</Text>
                    </View>
                </View>
                <View style={{ width: "90%", flexDirection: "row", fontSize: pointsTextSize }}>
                    <View>
                        <Text>(3) 売上又は手数料に関するお問い合わせは、営業担当までお願い致します。</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

