import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import UserPagination from "../../../models/User/usersPagination";
import UserModel from "../../../models/User/userModel";
import PaginationModel from "../../../models/Pagination/PaginationModel";
import { LIST_LIMIT } from "../../../constants/constants";


class ExhibitionUserListService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }


    async exhibitionUserList(page_id: number, search?: string, limit = LIST_LIMIT.EXHIBITION_USER_LIST_LIMIT){

        const response = search ? 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EXHIBITOR_USERS}?page=${page_id}&limit=${limit}&search=${search}`, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EXHIBITOR_USERS}?page=${page_id}&limit=${limit}`, this.payload, this.headers).getNew();
        let exhibitionUserListPaginationModel:UserPagination = new UserPagination();
        const exhibitionUserList:UserModel[] = []; 
        if(response?.data?.success == true)
        {
            
            response?.data?.data?.data?.forEach((item: UserModel) => {
                let exhibitionUser:UserModel = new UserModel();
                exhibitionUser = UserModel.parseJson(item);
                exhibitionUserList.push(exhibitionUser);
            });
            
            
            let paginationData:PaginationModel = new PaginationModel();
            paginationData = PaginationModel.parseJson(response?.data?.data);

            exhibitionUserListPaginationModel = UserPagination.parseJson(exhibitionUserList,paginationData);
            return exhibitionUserListPaginationModel;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async deleteInvitedExhibitor(itemId: number) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.DELETE_EXHIBITOR, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_INVITED_EXHIBITOR}`+itemId, this.payload, this.headers).delete();
        let responseMessage: string;

        if(response?.data?.success==true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async deleteExhibitorUser(itemId: number) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.DELETE_EXHIBITOR, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_EXHIBITOR}`+itemId, this.payload, this.headers).delete();
        let responseMessage: string;

        if(response?.data?.success==true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async showExhibitionUserDetail(exhibitor_id: number){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(`${API.LOCAL_BASE_URL}${mockAPIPath.EXHIBITOR_USERS}`, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EXHIBITOR_USER_DETAILS}${exhibitor_id}`, this.payload, this.headers).getNew();
        let exhibitionUserDetail: UserModel = new UserModel();
        if(response?.data?.success == true)
        {
            exhibitionUserDetail = UserModel.parseJson(response.data.data); 
            return exhibitionUserDetail;
            
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async inviteExhibitionUser(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.INVITE_USER}`, this.payload, this.headers).post();
        let isUserIvited;
        if(response?.data?.success==true) {
            isUserIvited = response?.data;
            return isUserIvited;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }
    async verifyInviteLink(code: any){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_INVITE_LINK}${code}`, this.payload,this.headers).put();
        let isInviteLinkVerified: string;
        if(response?.data?.success==true) {
            isInviteLinkVerified = response?.data?.message;
            return isInviteLinkVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async availableEmail() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EMAIL_VERIFY}`, this.payload, this.headers).post();
        let isUserEmailAvailable;
        if(response?.data?.success == true) {
            isUserEmailAvailable = response?.data;
            return isUserEmailAvailable;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async updateEmail(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_EMAIL}`, this.payload, this.headers).put();
        let isUserEmailUpdated;
        if(response?.data?.success == true) {
            isUserEmailUpdated = response?.data;
            return isUserEmailUpdated;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async updateAdminName(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_NAME}`, this.payload, this.headers).put();
        let isUserEmailUpdated;
        if(response?.data?.success == true) {
            isUserEmailUpdated = response?.data;
            return isUserEmailUpdated;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }
}

export default ExhibitionUserListService;