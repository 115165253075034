import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type InitialState = {
    newPermissionDetails : {
        accessToAllSeller?:string,
        userType?: number,
        roleId?:number,
        roleName?:string,
        emailPermissions?: [{
            name?:string,
            key?:string,
            value?:number
        }],
        modules?: {
            admin?: [{
                key?: string,
                moduleId?:number,
                name?: string,
                panel?:number
                permissions?: {
                    download?: number,
                    execute?: number,
                    view?: number,
                }
            }],
            seller?: [{
                key?: string,
                moduleId?:number,
                name?: string,
                panel?:number
                permissions?: {
                    download?: number,
                    execute?: number,
                    view?: number,
                }
            }],
        }
    }
};

const initialState: InitialState = {
    newPermissionDetails : {
        accessToAllSeller:"",
        roleId:1,
        roleName:"",
        userType: 0,
        emailPermissions: [{
            name:"",
            key:"",
            value:0
        }],
        modules: {
            admin: [{
                key: "",
                moduleId:1,
                name: "",
                panel:0,
                permissions: {
                    download: 1,
                    execute: 1,
                    view: 1,
                }
            }],
            seller: [{
                key: "",
                moduleId:1,
                name: "",
                panel:1,
                permissions: {
                    download: 1,
                    execute: 1,
                    view: 1,
                }
            }],
        }
    }
};

const newPermissionSlice = createSlice({
    name: "newPermission",
    initialState,
    reducers: {
        NEW_SET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.newPermissionDetails = action.payload;
        },
        NEW_RESET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.newPermissionDetails = action.payload;
        }
    }
});

export const newPermissionInitialValue : InitialState = initialState;
export default newPermissionSlice.reducer;
export const newPermissionActions = newPermissionSlice.actions;