import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { SalesHistoryWithPaginationType } from "../../../interfaces/salesHistory";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class SalesHistoryServices {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read(),"accept-language":process.env.REACT_APP_LANGUAGE };
    }

    async salesHistoryList(
        page_id: number,
        limit = LIST_LIMIT.SALES_HISTORY_LIST_LIMIT,
        from : Date | string,
        to  : Date | string, 
       
    ) {
        const response = this.isHitDummyURL? await new AxiosUtils( `${mockAPIPath.SALES_HISTORY}`, this.payload,  this.headers).get() 
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SALES_HISTORY}?page=${page_id}&limit=${limit}&from=${from}&to=${to}`, this.payload, this.headers).get();
      
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =   response.data.data;   
            
            const SalesHistoryData: SalesHistoryWithPaginationType = {
                salesHistoryLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return SalesHistoryData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadSalesHistoryList(
        from : Date | string,
        to  : Date | string, 
       
    ) {
        const response = this.isHitDummyURL? await new AxiosUtils( `${mockAPIPath.SALES_HISTORY}`, this.payload,  this.headers).get() 
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SALES_HISTORY}?&from=${from}&to=${to}&download=true`, this.payload, this.headers).get();
      
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =   response.data.data;   
            
            const SalesHistoryData: SalesHistoryWithPaginationType = {
                salesHistoryLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return SalesHistoryData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async DummyUrlService() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.DUMMY_URL}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.DUMMY_URL}`,
                this.payload,
                this.headers
            ).post();
        let responseMessage: string;
        if(response?.data?.success==true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async salesSatementService(settlementCode?: string) {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.SALES_STATEMENT}`, this.payload, this.headers).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SALES_STATEMENT}?settlementPeriod=${settlementCode}`, this.payload, this.headers).get();
        if(response?.data?.success) {
            const { data } = response.data;
            return data;
        } else if(response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}
export default SalesHistoryServices;

