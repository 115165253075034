import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {  useLocation } from "react-router";
import CONSTANTS from "../../../constants/constants";
import AuthService from "../services/AuthService";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import SellerRegisterDetails from "./SellerRegisterDetails";
// import VerifyInvitation from "../../common/VerifyInvitation";

const Register = (props: any) => {
    const [t] = useTranslation();
    const { login, handleLoader, showSnackbar } = props;
    const navigate = useNavigate();
    const locationn = useLocation();
    const prevPath = locationn?.state?.prevPath ?? "";
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    //     null
    // );
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    // const [error, setError] = useState({
    //     nameError: "",
    //     passwordError: "",
    //     confirmPasswordError: "",
    // });
    // const [userDetails, setUserDetails] = useState({});
    const [nextStep, setNextStep] = useState(false);

    const userInfo = {
        code: searchParams.get("code"),
        userType: searchParams.get("type"),
        fullName: name,
        password: password,
        confirmPassword: confirmPassword,
    };


    // validation

    const [, forceUpdate] = useState(0);

    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;

    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("registerpage.password_does_not_match"),
                max: t("registerpage.max_200_char"),
                regex: t("registerpage.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation

    const handleOnBlur = (e: any) => {

        const { name } = e.target;

        validator.current.showMessageFor(name);

    };

    useEffect(() => {
        !searchParams.get("code")
            ? navigate(ROUTES.OMS)
            : !prevPath
                ? navigate(`${ROUTES.VERIFY_INVITATION}?code=${searchParams.get("code")}`)
                : null;
    });
    const handleChange = (e: any) => {
        const { value, name } = e.target;
        name === "name"
            ? setName(value.trim())
            : name === "password"
                ? setPassword(value)
                : setConfirmPassword(value);
        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            if(Number(searchParams.get("type")) == CONSTANTS.USER_TYPE_EXHIBITOR){
                handleLoader(true);
                // dispatch(commonActions.SAVE_LOGIN_USER_NAME(name));
                const payload = {
                    code: searchParams.get("code"),
                    userType: searchParams.get("type"),
                    fullName: name,
                    password: password,
                    confirmPassword: confirmPassword,
                };
                const isHitDummyURL = false;
                new AuthService(payload, isHitDummyURL)
                    .registerStepFirst()
                    .then((res) => {
                        handleLoader(false);
                        // setIsStoreCreated(res.data.data.isStoreCreated);
                        // setHeader(res.data.data.accessToken);
                        const { isStoreCreated, accessToken, userType, email, name , storeId, storeName, storeCode } = res;
                        // login(accessToken);
                        // if (isStoreCreated == false && accessToken) {
                        login(accessToken, isStoreCreated, userType, email, name , storeId, storeName, storeCode );
                        navigate(ROUTES.OMS);
                    // }
                    })
                    .catch((err) => {
                        handleLoader(false);
                        // const { success, message } = err.response.data;
                        showSnackbar(err?.message,false);
                   
                    });
            }else{
                setNextStep(true);          
            }
          
        } else {
            setNextStep(false);
            validator.current.showMessages();
        }
    //
    // navigate("/register-details", { state: { "name": name, "password": password, "confirmPassword": confirmPassword, "email": searchParams.get("code") } })
    };


    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleClickShowPassword = () => {
    //     setPasswordType("password");
    // };

    const handleMouseDownPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    // const handleClickShowConfirmPassword = () => {
    //     passwordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password"); 
    // };

    const handleMouseDownConfirmPassword = () => {
        confirmPasswordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password"); 
    };

    // const open = Boolean(anchorEl);

    return (
        <>
            {/* Register page starts here */}
            {
                nextStep ? (<SellerRegisterDetails login={login} userInfo={userInfo} showSnackbar={showSnackbar} handleLoader={handleLoader}/>) : (  <Container>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}        
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img className="logo-img" alt="cainz"src={imagePath("logo-large.png")}/>
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    type={name}
                                    fullWidth
                                    name="name"
                                    label={t("registerpage.full_name")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("name", name, [
                                    "required",
                                    { max: 200 },
                                ])}
                            </Grid>
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownConfirmPassword}
                                                    // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleMouseDownPassword}
                                                    // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { passwordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={passwordType}
                                    name="password"
                                    label={t("registerpage.password")}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            <Grid sx={{ mb: 1 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownConfirmPassword}
                                                    // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleMouseDownConfirmPassword}
                                                    // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { confirmPasswordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={confirmPasswordType}
                                    fullWidth
                                    name="confirmPassword"
                                    label={t("registerpage.confirm_password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("confirmPassword", confirmPassword, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    {in:password},
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            
                            <Typography sx={{width: "auto", color:"#201d1d94"}}>
                                {t("updatepassword.password_suggestion")}
                            </Typography>
    
                            <Grid sx={{ my: 2 }}>
                                <Button fullWidth onClick={handleSubmit}>
                                    {t("registerpage.register")}
                                </Button>
                            </Grid>
                        </Grid>
                          
                       
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                </Container>)}
          

            {/* Register page ends here */}
        </>
    );
};

export default Register;
