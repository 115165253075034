import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import { LIST_LIMIT } from "../../../constants/constants";
import i18n from "../../../i18n";

class packagingSlipService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read(),"lang": i18n.language == "en-GB" ? "en" : "ja" };
    }

    async packingSlip(orderId = "", limit = LIST_LIMIT.OMS_SHIPPED_LIST_LIMIT) {
        
        const url = this.isHitDummyURL
            ? mockAPIPath.PACKING_SLIP
            : `${API.BASE_URL}${serverAPIPath.PACKING_SLIP}?orderId=${orderId}&limit=${limit}`;
    
        const response = await fetch(url, {
            method: "GET",
            headers: { ...this.headers }
        });
        if (response.ok) {
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
        }
    }

    async refreshAccessToken() {
        const response = await new AxiosUtils("").refreshAccessToken();
        let newToken;
        if (response?.data?.success) {
            newToken = response?.data?.data?.access_token;
            new Cookies("access-token", newToken).save(); // Save new access token in cookie
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
  
}
export default packagingSlipService;