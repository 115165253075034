import CryptoJS from "crypto-js";

/**
 * @export Crypt
 * @description The Utility is responsible for the operations to encrypt and decrypt.
 */
export class Crypt {
    private key;
    // private iv;
    private mode;
    private keySize;
    private padding;

    constructor() {
        this.key = CryptoJS.SHA256(process.env.REACT_APP_ENCRYPTION_KEY ?? "");
        // this.iv = CryptoJS.SHA256(process.env.ENCRYPTION_IV ?? "");
        this.mode = CryptoJS.mode.CBC;
        this.padding = CryptoJS.pad.Pkcs7;
        this.keySize = 32;
    }


    decryptWithCryptoJS = (cipher:any,iv:any) => {
        try{
            if(!cipher){
                return "";
            }
            const key = this.key;
            iv = CryptoJS.SHA256(iv);
            // const iv = this.iv;
            const plainText = CryptoJS.AES.decrypt(cipher, key, {
                keySize: this.keySize,
                iv: iv,
                mode: this.mode,
                padding: this.padding
            });
    
            return plainText.toString(CryptoJS.enc.Utf8);

        }catch(err){           
            return "";
        }
    };

}