import { API } from "../../../config";
import LoginModel from "../../../models/SellerLogin/LoginModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath } from "../config/apiPath";


class AdminAuthService {

    private payload: object;
    private headers: object;

    constructor(payload = {}, headers?: string) {
        this.payload = payload;
        this.headers = { "access-token": headers };
    }

    async adminLogin() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ADMIN_AUTH_ON_SELLER_PANEL}`, this.payload, this.headers).post();
        let userDetails: LoginModel = new LoginModel();
        if(response?.data?.success==true) {
            userDetails = LoginModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

export default AdminAuthService;