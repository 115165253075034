import { useEffect } from "react";
import CONSTANTS from "../constants/constants";


const lazyTypingUtils = (props: any) => {
    const [apiCall, dependency,time] = props;
    const timeDelay = time ? time : CONSTANTS.SEARCH_DELAY_TIME;

    useEffect(() => {
        const timer = setTimeout(() => {
            apiCall();
        }, timeDelay); // Adjust the delay (in milliseconds) according to your needs
        return () => {
            clearTimeout(timer);
        };
    }, [dependency]);

    return null;
};

export default lazyTypingUtils;
