import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate, Link } from "react-router-dom";
import HeaderTitle from "../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import ProfileService from "./services/ProfileService";
import { userActions } from "../../redux/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Skeleton from "../common/Skeleton";
import { ROUTES } from "../../constants/routes";
import { commonActions } from "../../redux/features/common/commonSlice";
import CONSTANTS from "../../constants/constants";


const Profile = (props: any) => {
    const { setNewSellerLogin ,showSnackbar } = props;
    const dispatch = useAppDispatch();
    const name = useAppSelector((state) => state.common.loggedInUserName);
    const storeCode = useAppSelector((state) => state.common.loggedInStoreCode);
    const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType);
    const [skeleton, setSkeleton] = useState(true);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<any>(useAppSelector(state => state.user.userDetails));
    const [dataCompleteLoaded,SetDataCompleteLoaded] = useState(false);


    useEffect(() => {
        if(loggedInUserType!=CONSTANTS.USER_TYPE_EXHIBITOR){
            getUserDetails();
        }
    }, []);



    window.onfocus = function (ev) {
        if(localStorage.getItem("storeCode")!=storeCode && (loggedInUserType==CONSTANTS.USER_TYPE_ADMIN || loggedInUserType==CONSTANTS.USER_TYPE_SUPER_ADMIN )){
            setNewSellerLogin(true);
            window.name="tab 1";
            // dispatch(commonActions.IS_NEW_SELLER_LOGIN(true));
            // getUserDetailForRedux();
        }
    };
    
    const getUserDetails = () => {
        const isHitDummyURL = false;
        new ProfileService({}, isHitDummyURL)
            .sellerInfo()
            .then((res:any) => {
                setUserDetails(res);
                SetDataCompleteLoaded(true);
                dispatch(userActions.USER_DETAILS(res)); 
                localStorage.setItem("storeCode",res?.store[0]?.storeCode);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const getUserDetailForRedux = () => {
        const isHitDummyURL = false;
        new ProfileService({}, isHitDummyURL)
            .sellerInfo()
            .then((res:any) => {
                setUserDetails(res);
                dispatch(commonActions.SAVE_STORE_CODE(res?.store[0]?.storeCode));
                dispatch(commonActions.SAVE_STORE_NAME(res?.store[0]?.storeName));
                dispatch(userActions.USER_DETAILS(res)); 
                localStorage.setItem("storeCode",res?.store[0]?.storeCode);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    return (
        <>
            {/* Page header title */}

            <HeaderTitle title={t("profile.page_title")} />

            {/* Page header title */}
            <Container>
                <Grid container spacing={2}>
                    {/*My seller profile card*/}
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Card>
                            <CardContent sx={{ p: 0 }}>
                                <Box className="space-between" sx={{ p: 1 }}>
                                    <Typography variant="h6">
                                        {t("profile.my_seller_profile")}
                                    </Typography>
                                    {dataCompleteLoaded ?  <Link to={ROUTES.STORE_DETAILS}>
                                        <EditOutlinedIcon color="primary" />
                                    </Link> :     
                                        <CircularProgress color="warning" thickness={1} size="1.75rem"/>
                                    }
                                </Box>
                                <Grid container sx={{ p: 3 }}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography sx={{ p: { xs: "0", md: "0", lg: 1 } }}>
                                            {t("store_details.corporate_name")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                                        <Typography
                                            sx={{
                                                p: { xs: "0", md: "0", lg: 1 },
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {userDetails?.store?.[0]?.corporateName  ?? <Skeleton count={1}  height={20} variant="rectangular" my={0}  />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography sx={{ p: { xs: "0", md: "0", lg: 1 } }}>
                                            {t("profile.store_name")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                                        <Typography
                                            sx={{
                                                p: { xs: "0", md: "0", lg: 1 },
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {userDetails?.store?.[0]?.storeName  ?? <Skeleton count={1}  height={20} variant="rectangular" my={0} />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography sx={{ p: { xs: "0", md: "0", lg: 1 } }}>
                                            {t("profile.name")} 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                                        <Typography
                                            sx={{
                                                p: { xs: "0", md: "0", lg: 1 },
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {userDetails?.name  ?? <Skeleton count={1}  height={20} variant="rectangular" my={0} />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography sx={{ p: { xs: "0", md: "0", lg: 1 } }}>
                                            {t("invitation_page.email_address")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography
                                            sx={{
                                                p: { xs: "0", md: "0", lg: 1 },
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {userDetails?.email  ?? <Skeleton count={1}  height={20} variant="rectangular" my={0} />}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {/*My seller profile card*/}

                        {/* Listing status */}
                        {/* <Card sx={{ my: 3 }}>
                            <CardContent sx={{ p: 0 }}>
                                <Box className="space-between" sx={{ p: 1 }}>
                                    <Typography variant="h6">
                                        {t("profile.listing_status")}
                                    </Typography>
                                </Box>
                                <Grid container sx={{ p: 3 }}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography sx={{ px: 1 }}>
                                            {t("profile.current_listing_status")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                                        <Typography sx={{ px: 1 }}>
                                            {t(
                                                "profile.active_(Listings available for sale on Cainz)"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card> */}
                        {/*Listing status*/}
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                        {/*Personal infornation*/}
                        {/* <Card sx={{ mb: 3 }}>
                            <CardContent className="bg-gray" sx={{ px: 1 }}>
                                <Typography variant="h6">
                                    {t("profile.personal_information")}{" "}
                                </Typography>
                            </CardContent>
                            <Grid container sx={{ p: 3 }}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    sx={{ mb: 2, display: "flex", justifyContent: "flex-start" }}
                                >
                                    <Typography
                                        className="profileInfo"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => navigate(ROUTES.ABOUT_US)}
                                    >
                                        {t("profile.about_us")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card> */}
                        {/*Personal infornation*/}

                        {/*Policies*/}
                        {/* <Card sx={{ mb: 3 }}>
                            <CardContent className="bg-gray" sx={{ px: 1 }}>
                                <Typography variant="h6"> {t("profile.policies")} </Typography>
                            </CardContent>

                            <Container>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.PRIVACY_POLICY)}
                                        >
                                            {t("profile.privacy_policy")}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.SHIPPING_POLICY)}
                                        >
                                            {t("profile.shipping_policy")}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.FAQS)}
                                        >
                                            {t("profile.faqs")}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.CUSTOM_POLICY)}
                                        >
                                            {t("profile.custom_policy")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Card> */}
                        {/*Policies*/}
                        <Card>
                            <CardContent className="bg-gray" sx={{ px: 1 }}>
                                <Typography variant="h6"> {t("store_details.payment_information")} </Typography>
                            </CardContent>

                            <Container>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.PAYEE_INFO)}
                                        >
                                            {t("store_details.payee_information")}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <Typography
                                            className="profileInfo"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate(ROUTES.BILLING_INFO)}
                                        >
                                            {t("store_details.billing_information")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Profile;
