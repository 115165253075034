import React, { useEffect, useRef, useState } from "react";
import { Button, Container, FormControl,CircularProgress, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LIST_LIMIT, SHEET_NAME, } from "../../constants/constants";
import HeaderTitle from "../common/HeaderTitle";
import Pagination from "../common/Pagination";
import Skeleton from "../common/Skeleton";
import MUIDataTable from "mui-datatables";
import ActivityLogService from "./service/ActivityLogService";
import {ActivityLogType} from "../../interfaces/activityLogInterface";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";
import SearchIcon from "@mui/icons-material/Search";
import SimpleReactValidator from "simple-react-validator";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import CONSTANTS from "../../constants/constants";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import { useAppSelector } from "../../redux/hooks";
import Utility from "../../utils/Utility";



const ActivityLog = (props: any) => {

    const [t] = useTranslation();
    const firstPage = 1;
    const utility = new Utility;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [ activityLogs, setActivityLogs ]= useState<ActivityLogType[]>();
    const apiData: any[][] = [];
    const [valueFrom, setValueFrom] = useState<any>(null);
    const [valueTo, setValueTo] = useState<any>(null);
    const [screen, setScreen] = useState("");
    const [searchUserName, setSearchUserName] = useState("");
    const [loading, setLoading] = useState(false);
    // const [success, setSuccess] = useState(false);
    // const [listDownload, setListDownload] = useState<any>([]);
    const [resetData, setResetData] = useState(false);
    const [, forceUpdate] = useState(0);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.EXHIBITION_USER_LIST_LIMIT : perPageLimit );
    const [screenNameFilterOptions, setScreenNameFilterOptions] =useState<any>();

    const {  showSnackbar } = props;

    const columns = [
        {
            name: t("activity_log.s_no"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("activity_log.date_time"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("activity_log.user_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("activity_log.screen_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("activity_log.action_name"),
            options: {
                filter: true,
                sort: false,
            },
        }
    ];

    useEffect(() => {
        forceUpdate(1);
    });

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(()=>{
        activityLogList(firstPage, limit,true);
    },[]);

    useEffect(()=>{
        if(screen || resetData){
            activityLogList(firstPage, limit);
        }
    },[screen, resetData]);

    const activityLogList = (currentPage: number, limit: any ,isPageRendered=false) => {
        setResetData(false);
        setIsSearchChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new ActivityLogService(payload, isHitDummyURL)
            .getActivityLog(currentPage, limit, toParam ?? "", fromParam ?? "", searchUserName ?? "", screen ?? "")
            .then((res:any) => {
                if(isPageRendered){
                    setScreenNameFilterOptions(res?.screenNames);
                }
                setCurrentPage(currentPage);
                setLimit(limit);
                setActivityLogs(res?.activityLogs);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);    
            })
            .catch((err) => {
                setSkeleton(false);
                showSnackbar(err?.message, false);
            });
    };



    const downloadActivityLog = () => {
        showSnackbar(t("bulk_product_index.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        setLoading(true);
        // setSuccess(false);
        new ActivityLogService(payload, isHitDummyURL)
            .downloadActivityLogList( toParam ?? "", fromParam ?? "", searchUserName ?? "", screen ?? "")
            .then((res: any) => {
                handleOnExport(res?.activityLogs);
                setSkeleton(false);
            })
            .catch((err) => {
                setSkeleton(false),setLoading(false);
                showSnackbar(err?.message, false);
            });
    };
    
    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            downloadData.push({
                "日時":item?.createdAt?.split("T")[0],
                "ユーザー名":item?.userName,
                "画面名":item?.screenName,
                "アクション":item?.actionName,  
                "メッセージ":item?.message,   
            });
        });
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(downloadData);
        // XLSX.utils.book_append_sheet(wb, ws, "activity-log");
        // XLSX.writeFile(wb, "activity-log.xlsx");
        const fileName=`log_${utility.getCurrentTimestamp()}.xlsx`;
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.ACTIVITY_LOG);
        // setSuccess(true);
        setLoading(false);
    };


    const apiTableData = () => {
        activityLogs?.map(
            (item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.createdAt,
                    item?.userName,
                    item?.screenName,
                    item?.actionName
                );
            }
        );
    };
    apiTableData();


    const handleChange = (event: SelectChangeEvent) => {
        setScreen(event.target.value);
    };
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearchUserName(value);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            activityLogList(CONSTANTS.FIRST_PAGE, limit);
        }
    };

    const onsearchApicall = () =>{
        if(isSearchChange){
            activityLogList(CONSTANTS.FIRST_PAGE, limit);
        }
    };
    
    const propItem = [
        onsearchApicall, searchUserName
    ];
    lazyTypingUtils(propItem);


    const handleSubmit = () =>{
        if (validator.current.allValid()) {
            activityLogList(firstPage, limit);     
        }
        else {
            validator.current.showMessages();
        }
    };

    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };
    const handleDateFrom = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleDateTo = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };

    const resetAllFilter = () =>{
        setValueTo(null);
        setValueFrom(null);
        setFromParam("");
        setToParam("");
        setScreen("");
        setSearchUserName("");
        setResetData(true);
    };



    return (
        <>
            <HeaderTitle title={t("activity_log.activity_log")} />

            <Container sx={{ my:4 }}>
                <Grid container spacing={2}>    
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                        <TextField
                            label={t("activity_log.search_by_user_name")}                         
                            onChange={handleSearchChange}
                            value={searchUserName}
                            onKeyUp={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                        />                   
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("activity_log.screen_name")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="select_screen"
                                value={screen}
                                label="Screen name"
                                MenuProps={{
                                    style: { maxHeight: 250,},
                                }}
                                onChange={handleChange}>
                                {screenNameFilterOptions?.sort()
                                    .map((item:any, index:any)=>{
                                        return(
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>    
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <Grid container spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} className="date-picker-main" >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        className="date-picker-default sr_history_filter"
                                        label={t("sales_history.from")}
                                        value={valueFrom}
                                        inputFormat="yyyy/MM/dd"
                                        onChange={handleDateFrom}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params} 
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    style: { pointerEvents: "none" },
                                                }}  

                                            />)}
                                        autoFocus={true}
                                        disableFuture
                                        // maxDate={valueTo} 
                                        shouldDisableDate={shouldDisableDateFrom} 
                                        showToolbar={false}
                                        componentsProps={{
                                            actionBar: {
                                                // The actions will be the same between desktop and mobile
                                                actions: []
                                            }
                                        }}
                                    />  
                                    {validator.current.message("valuefrom", valueFrom, [
                                        "required",
                                    ])}    

                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        className="date-picker-default sr_history_filter"
                                        label={t("sales_history.to")}
                                        value={valueTo}
                                        inputFormat="yyyy/MM/dd"
                                        onChange={handleDateTo}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params} 
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    style: { pointerEvents: "none" },
                                                }}  

                                            />)}                                         
                                        autoFocus={true}
                                        disableFuture
                                        shouldDisableDate={shouldDisableDateTo}
                                        // minDate={valueFrom}
                                        showToolbar={false}
                                        componentsProps={{
                                            actionBar: {
                                                actions: []
                                            }
                                        }}
                                    />  
                                    {validator.current.message("valueto", valueTo, [
                                        "required",
                                    ])}
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={2} sx={{mt:1}}>
                        <Button 
                            variant="contained"
                            sx={{textTransform: "capitalize"}}
                            onClick={handleSubmit}>
                            {t("pr.submit")}
                        </Button>

                        <Button 
                            variant="contained"                           
                            sx={{textTransform: "capitalize", ml:2}}
                            onClick={resetAllFilter}> {t("pr.reset")}
                        </Button>
                    </Grid>
            
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button variant="contained" 
                            disabled={loading || activityLogs?.length == 0 || !activityLogs}
                            onClick={()=>downloadActivityLog()}
                            sx={{textTransform: "capitalize", float:"right", textAlign:"right"}}
                            className="download_excel">
                                
                            {t("sales_history.download")}
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Container>   
            
            {skeleton ? (
                <Skeleton
                    varient="rectangular"
                    width={0}
                    height={60}
                    count="10"
                    my={0}
                />
            ) : (
                <>
                    <div className="main">
                        <Container className="minimum_column  first_table_cell_small_width">
                      
                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t(
                                        "table_text.search_placeholder"
                                    ).toString(),
                                    responsive: "standard",
                                    onSearchChange: (searchText: any) => {
                                        setSearchUserName(searchText ? searchText : "");
                                    },
                                }}
                            />
                        
                     
                        </Container>
                    </div>
                </>

            )}
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={activityLogList}
                totalPage={totalPages}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
            {/* Table data ends here */}
        </>
    );
};

export default ActivityLog;
