import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, IconButton, Modal, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Marquee from "react-fast-marquee";

export default function NotificationMessage(props: any) {
    const { isCookiePresent, isMsgRequired, msgVisibility } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [isLongMessage, setIsLongMessage] = useState(false);
    const messageRef = useRef<HTMLDivElement>(null);
    const [textWidth, setTextWidth] = useState<number>(getTextWidth(isMsgRequired, "16px Arial"));
    const [windowWidth, getDimension] = useState<number>(window.innerWidth);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function getTextWidth(text:any, font:any) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
    
        if (context) { 
            context.font = font || getComputedStyle(document.body).font;
            return context.measureText(text).width;
        } else {
            return 0; 
        }
    }
    
    //Dynamic message animation width calculation
    const setDimension = () => {
        getDimension(window.innerWidth);
    };
    useEffect(()=> {
        setTextWidth(getTextWidth(isMsgRequired, "19px Arial"));
        setDimension();
    },[isMsgRequired]);
    
    useEffect(() => {
        window.addEventListener("resize", setDimension);
    
        return(() => {
            window.removeEventListener("resize", setDimension);
        });
    }, [windowWidth]);
        
    const checkIfLongMessage = () => {
        if (messageRef.current) {
            const lineHeight = parseInt(getComputedStyle(messageRef.current).lineHeight);
            const maxLines = 4;
            const maxHeight = lineHeight * maxLines;
            setIsLongMessage(messageRef.current.scrollHeight > maxHeight);
        }
    };

    useEffect(() => {
        checkIfLongMessage();
        
        const handleResize = () => {
            checkIfLongMessage();
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMsgRequired,msgVisibility]);

    return (
        <Box 
            className={` ${isCookiePresent ? "notification_msg word_wrap" : "msg_position word_wrap"}`}
            component="div"
        >
            <Grid sx={{ p:2 }} > 
                { msgVisibility == "0" ?
                    <Typography
                        variant="inherit"
                        noWrap
                        component="div"
                        style={{ textAlign: "center" }}
                    >                 
                        {textWidth > window.innerWidth ? (
                            <Marquee className="white_space">      
                                {isMsgRequired}   
                                <Grid component="span" style={{ display: "inline-block", width: "50px" }}></Grid>                
                            </Marquee>
                        ) : (
                            isMsgRequired
                        )}  
                    </Typography>
                    : 
                    <>
                        <Typography
                            variant="inherit"
                            component="div"
                            ref={messageRef}
                            style={{
                                whiteSpace: "pre-wrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: isLongMessage ? 4 : "none", 
                                WebkitBoxOrient: "vertical",
                                lineHeight: "24px",
                                textAlign:"center"
                            }}
                        >
                            {isMsgRequired}
                        </Typography>                  
                        {isLongMessage && (
                            <Link 
                                className="float_right system-update-read-more" 
                                component="button" 
                                onClick={handleOpen} 
                                underline="none"
                            >
                                {t("header.read_more")}
                            </Link>
                        )}
                    </>
                }
                <Grid container >
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="notification-modal-title"
                        aria-describedby="notification-modal-description"
                    >
                        <Box className="center-model center-model-information" >
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h6" component="p">
                                    {t("header.information")}
                                </Typography>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Typography 
                                id="notification-modal-description" 
                                className="notification-modal-description"
                            >
                                {isMsgRequired}
                            </Typography>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        </Box>
    );
}