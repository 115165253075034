import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class BillingInformationServices {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async saveBillingInfo(billingID: number | null) {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.BILLING_ADDRESS}${billingID}`, this.payload,this.headers).put();
        let responseMsg: string;
        if(response?.data?.success) {
            responseMsg = response?.data?.message;
            return responseMsg;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}
export default BillingInformationServices;

