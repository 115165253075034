export const mockAPIPath = {
    IMAGE_UPLOAD: "./data/ErrorMessageCheck.json",
    IMAGE_LIST: "./data/ErrorMessageCheck.json",
    SINGLE_IMAGE_LIST: "./data/ErrorMessageCheck.json",
    UPLOAD_IMAGE:"./data/ErrorMessageCheck.json",
    IMAGE_SUBMIT:"./data/ErrorMessageCheck.json"

};

export const serverAPIPath = {
    IMAGE_UPLOAD: "/products/image/zip-file",
    IMAGE_LIST: "/products",
    SINGLE_IMAGE_LIST:"/products/",
    UPLOAD_IMAGE:"/products/image",
    IMAGE_SUBMIT:"/products/image/collect"
    
};





