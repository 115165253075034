import {
    Container,
    Typography,
    TextField,
    Grid
} from "@mui/material";
import React, { useState } from "react";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import ProfileService from "../services/ProfileService";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import RichTextEditor from "../../common/RichTextEditor";
import { userActions } from "../../../redux/features/user/userSlice";

const CustomPolicy = (props: any) => {

    const { showSnackbar, handleLoader } = props;
    
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [customTitle, setCustomTitle] = useState<any>(useAppSelector(state => state.user.userDetails?.store?.[0].policy?.customPolicyTitle));
    const customDescription = useAppSelector(state => state.user.userDetails?.store?.[0].policy?.customPolicyDescription);
    // const [alertMessage, setAlertMessage] = useState("");
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    // const [success, setSuccess] = useState(false);

    // const setMessage = (msg: string) => {
    //     setAlertMessage(msg);
    //     setOpenSnackBar(true);
    // };

    const saveCustom = (data: any) => {
        handleLoader(true);
        dispatch(userActions.SAVE_CUSTOM_TITLE(customTitle));
        dispatch(userActions.SAVE_CUSTOM_DESCRIPTION(data));
        const payload = { customPolicyTitle: customTitle ,customPolicyDescription: data };
        const isHitDummyURL = false;
        new ProfileService(payload, isHitDummyURL).sellerBussinessInfo().then(res => {
            handleLoader(false);
            showSnackbar(res, true);
        }).catch(err => {
            handleLoader(false);       
            showSnackbar(err?.message, false);
        });
    };

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setCustomTitle(value);
    };

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };

    return (
        <>
            {/* Page header title */}
            <div className="main">
                <HeaderTitle
                    title={t("profile.custom_policy")}
                />
                  
                {/* Page header title */}

                <Container>
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {t("profile.custom_policy")}
                    </Typography>
                    <Grid item lg={12} sx={{py: 5}}>
                        <TextField 
                            // sx={{py: 5}}
                            label={t("profile.title")}
                            value={customTitle ?? ""}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Grid>
                    <RichTextEditor data={customDescription} saveData={saveCustom} />
                </Container>
            </div>
        </>
    );
};

export default CustomPolicy;
