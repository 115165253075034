import { API } from "../../../config";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";

class prepareForShipmentServices {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async prepareForShipment() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_PREPARE_FOR_SHIPMENT}`,
                this.payload,
                this.headers
            ).put()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_PREPARE_FOR_SHIPMENT}`,
                this.payload,
                this.headers
            ).put();
        let responseMessage: string;
        if (response?.data?.success == true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}
export default prepareForShipmentServices;
