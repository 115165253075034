import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import SellerReportsPaginationModel from "../../../models/SellerReports/SellerReportsWithPagination";
import SellerReportsModel from "../../../models/SellerReports/SellerReportsModel";
import PaginationModel from "../../../models/Pagination/PaginationModel";
import LatestSellerModel from "../../../models/SellerReports/LatestSellerReportsModel";
import CONSTANTS, { LIST_LIMIT } from "../../../constants/constants";
class SellerReportinfo {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async SellerReportsData(page_id: number, limit= LIST_LIMIT.BULK_PRODUCT_UPLOAD_REPORTS, appliedFilter = 0) {
    // let SellerInfosList;
        let response;
        if (appliedFilter == CONSTANTS.ALL_REPORTS_FILTER) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&limit=${limit}`,
                this.payload,
                this.headers
            ).getNew();
        }
        if (appliedFilter == CONSTANTS.UPLOADED_REPORTS_FILETR) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&status=${CONSTANTS.UPLOADED_REPORTS_STATUS}&error=false&limit=${limit}`,
                this.payload,
                this.headers
            ).getNew();
        }
        if (appliedFilter == CONSTANTS.PENDING_REPORTS_FILTER) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&status=${CONSTANTS.UPLOADED_REPORTS_STATUS}&error=true&limit=${limit}`,
                this.payload,
                this.headers
            ).getNew();
        }
        let sellerReportsPaginationModel: SellerReportsPaginationModel =
      new SellerReportsPaginationModel();
        const sellerReportsList: SellerReportsModel[] = [];

        if (response?.data?.success == true) {

            response?.data?.data?.data?.forEach((item: any) => {
                let sellerReports: SellerReportsModel = new SellerReportsModel();
                sellerReports = SellerReportsModel.parseJson(item);
                sellerReportsList.push(sellerReports);
            });

            let paginationData: PaginationModel = new PaginationModel();
            paginationData = PaginationModel.parseJson(response?.data?.data);

            sellerReportsPaginationModel = SellerReportsPaginationModel.parseJson(
                sellerReportsList,
                paginationData
            );
            return sellerReportsPaginationModel;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async LatestSellerReportsData() {
        const response = await new AxiosUtils(
            `${API.BASE_URL}${serverAPIPath.LATEST_SELLER_INFO_REPORTS}`,
            this.payload,
            this.headers
        ).getNew();
        const latestSellerList: LatestSellerModel[] = [];
        if (response?.data?.success == true) {
            let adminUser: LatestSellerModel = new LatestSellerModel();
            adminUser = LatestSellerModel.parseJson(response?.data?.data);
            latestSellerList.push(adminUser);
            return latestSellerList[0];
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default SellerReportinfo;
