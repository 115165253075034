import React, { useState, useEffect, useRef } from "react";
import { Container, Grid, Box, Button, Typography, TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Popover
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import HeaderTitle from "../../common/HeaderTitle";
import ProductListApi from "../services/productList";
import Skeleton from "../../common/Skeleton";
import { LIST_LIMIT, PRODUCT_LIST, SHEET_NAME } from "../../../constants/constants";
import MUIDataTable from "mui-datatables";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import Utility from "../../../utils/Utility";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DownloadButton from "../../common/DownloadBtn";
import { ROUTES } from "../../../constants/routes";
import { commonActions } from "../../../redux/features/common/commonSlice";

const ProductList = (props:any) => {
    const { showSnackbar } = props;
    const { t } = useTranslation();
    const node = useRef<HTMLDivElement>(null);

    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const productListCurrentPage = useAppSelector((state:any) => state?.common?.productList);
    const productListFilter = useAppSelector((state:any) => state?.common?.productListFilter);
    const hideColumnFilter = useAppSelector((state:any) => state?.common?.hideColumnFilter);
    const utility = new Utility;
    const [products, setProducts] = useState<any>();
    const [currentPage, setCurrentPage] = useState(productListCurrentPage?.currentPage ?? 1);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState<string>(productListFilter?.search);
    const apiData: any[][] = [];
    const [skeleton, setSkeleton] = useState(true);
    const [appliedFilter1, setAppliedFilter1] = useState<any>(productListFilter?.flag);
    const [appliedFilter2, setAppliedFilter2] = useState<any>(productListFilter?.productCode);
    const [appliedFilter3, setAppliedFilter3] = useState<any>(productListFilter?.individualShippingFee);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.BULK_PRODUCT_LIST : perPageLimit );
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [colFilter,setColFilter] =useState<string>(productListFilter?.colFilter);
    const [direction,setDirection]=useState<string>(productListFilter?.direction ?? "desc");
    const [colName,setColName]=useState<string>("");
    const [hideColumnArr,setHideColumnArr] =useState<any>(hideColumnFilter ? hideColumnFilter : []);
    const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLButtonElement | null>(null);
    const [showFilterOptions,setShowFilterOptions] =useState<boolean>(false);
    const [showDownloadOptions , setShowDownloadOptions]=useState<boolean>(false);
    const [shippingFeeOptions,setShippingFeeOptions]=useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        shippingFeeFilterOptionList();
        totalPages != 0 ? productData(firstPage, limit) : null;
    }, []);

    /**
    *
    * @param changedColumn - changedColumn represent column header name to which sorting is applied
    * @description - handle sorting functionality in product list
    */
    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName=changedColumn == "Product code" || changedColumn=="商品コード" ? "shukkamoto_mng_hinban":
            changedColumn == "Cainz product code" || changedColumn == "カインズ商品コード" ? "daihyo_jan_cd":
                changedColumn == "Product Name" || changedColumn=="商品名" ? "prd_nm_kj":
                    changedColumn == "Product price including tax" || changedColumn=="税込商品価格" ? "bai_tanka_idx_zeikomi_1":
                        changedColumn == "Individual shipping fee" || changedColumn=="個別送料" ? "kobetsu_soryo_kn_ec":
                            changedColumn == "publication start date" || changedColumn=="公開開始日" ? "online_from_dt_ec":
                                changedColumn == "publication end date" || changedColumn=="公開終了日" ? "online_to_dt_ec":
                                    changedColumn == "Last reflected date" || changedColumn=="最終反映実施日" ? "last_reflection_date":
                                        "";            
        setColFilter(columnName);

    };
    
    const columns = [
        {
            name: t("product_list.sno"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: t("product_list.product_code"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("商品コード") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="shukkamoto_mng_hinban" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>        
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("product_list.cainz_product_code"),
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="daihyo_jan_cd" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("product_list.product_name"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("商品名") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="prd_nm_kj" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>                   
                        </Box>
                    );
                }, 
            },
        },
        {
            name: t("product_list.product_price_including_tax"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("税込商品価格") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="bai_tanka_idx_zeikomi_1" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>             
                        </Box>
                    );
                }, 
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("product_list.individual_shipping_fee"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("個別送料") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="kobetsu_soryo_kn_ec" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>            
                        </Box>
                    );
                }, 
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("product_list.release_flag_sales_posting_flag"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("公開フラグ") ? false : true,

            },
        },
        {
            name: t("product_list.release_start_date_sales_post_start_date"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("公開開始日") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="online_from_dt_ec" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>        
                        </Box>
                    );
                }, 
            },
        },
        {
            name: t("product_list.release_end_date_sales_Posting_end_date"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("公開終了日") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="online_to_dt_ec" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>        
                        </Box>
                    );
                }, 
            },
        },
        {
            name: t("product_list.last_reflected_date"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("最終反映実施日") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="last_reflection_date" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>      
                        </Box>
                    );
                }, 
            },
        },
       
    ];

    useEffect(() => {
        if (appliedFilter1 || appliedFilter2||colFilter||direction || appliedFilter3) {
            productData(currentPage, limit);
        }
    }, [appliedFilter1, appliedFilter2,colFilter,direction, appliedFilter3]);


    const onsearchApicall = () =>{
        if(isSearchChange){
            productData(firstPage, limit);
        }

    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.IS_PRODUCT_LIST({currentPage: 1, limit: 15}));
            dispatch(commonActions.PRODUCT_LIST_COLUMN_FILTER({search: "", flag: "none", productCode: "none", individualShippingFee: "none", colFilter: "", direction: ""}));
            dispatch(commonActions.HIDE_COLUMN_FILTER([]));
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);

    /**
    *
    * @param currentPage - request currentPage for pagination
    * @param limit - request limit for pagination
    * @returns product list data
    * @description - Api call for product list data
    */
    const productData = (currentPage: number, limit: number) => {
        setIsSearchChange(false);
        setShowDownloadOptions(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ProductListApi(payload, isHitDummyURL)
            .productlistDataNew(currentPage,search.trim(), limit, appliedFilter1, appliedFilter2, appliedFilter3, colFilter,direction)
            .then((res) => {
                setProducts(res);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
                dispatch(commonActions.IS_PRODUCT_LIST({currentPage: 1, limit: 15}));
                dispatch(commonActions.PRODUCT_LIST_COLUMN_FILTER({search: "", flag: "none", productCode: "none", individualShippingFee: "none", colFilter: "", direction: ""}));
                dispatch(commonActions.HIDE_COLUMN_FILTER([]));
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
        apiTableData();
    };

    const shippingFeeFilterOptionList = () => {
        const payload = {};
        const isHitDummyURL = false;
        new ProductListApi(payload, isHitDummyURL)
            .shippingFeeFilterOptions()
            .then((res) => {
                setShippingFeeOptions(res);                
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
        apiTableData();
    };

    /**
    *
    * @returns product list data for download
    * @description - Api call for product list data in case of download
    */
    const downloadProductData = (fileType:any) => {
        setShowDownloadOptions(false);
        showSnackbar(t("bulk_product_index.please_wait"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new ProductListApi(payload, isHitDummyURL)
            .productlistDownload(search.trim(), appliedFilter1, appliedFilter2 , appliedFilter3, fileType, colFilter, direction)
            .then((res) => {
                if(typeof res == "string"){
                    showSnackbar(res, true);
                    setLoading(false);
                } else {
                    handleOnExport(res?.bulkProductLogs);
                }
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setLoading(false);
            });
        apiTableData(); 
    };

    /**
    *
    * @param downloadArray - DownloadArray has data that needed to be downloaded
    * @description - Function For Downloading The Excel file
    */
    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        let storeCode=null;
        downloadArray?.map((item :any)=>{
            storeCode =item?.storeCode;
            downloadData.push({
                "商品コード":item?.productCode,
                "カインズ商品コード":item?.cainzProductCode,
                "商品名":item?.productName,
                "ブランド":item?.brand,
                "製造元":item?.manufacturer,
                "消費税区分":item?.consumptionTaxCategory,
                "税込商品価格":item?.productPrice,
                "個別送料":item?.shippingFee,
                "最大注文数量":item?.maximumOrderQuantity,
                "酒類区分":item?.liquorCategory,
                "公開フラグ":item?.saleflag,
                "公開開始日":item?.salePublicatonStartDate,
                "公開終了日":item?.salePublicatonEndDate,
                "商品概要説明":item?.productOutlineExplanation,
                "配送日数":item?.deliveryTimeWording,
                "ECカテゴリ":item?.categoryCode,
                "スペック項目名1":item?.specCategoryAttributeName1,
                "スペック項目値1":item?.specCategoryAttributeValue1,
                "スペック項目名2":item?.specCategoryAttributeName2,
                "スペック項目値2":item?.specCategoryAttributeValue2,
                "スペック項目名3":item?.specCategoryAttributeName3,
                "スペック項目値3":item?.specCategoryAttributeValue3,
                "スペック項目名4":item?.specCategoryAttributeName4,
                "スペック項目値4":item?.specCategoryAttributeValue4,
                "スペック項目名5":item?.specCategoryAttributeName5,
                "スペック項目値5":item?.specCategoryAttributeValue5,
                "スペック項目名6":item?.specCategoryAttributeName6,
                "スペック項目値6":item?.specCategoryAttributeValue6,
                "スペック項目名7":item?.specCategoryAttributeName7,
                "スペック項目値7":item?.specCategoryAttributeValue7,
                "スペック項目名8":item?.specCategoryAttributeName8,
                "スペック項目値8":item?.specCategoryAttributeValue8,
                "スペック項目名9":item?.specCategoryAttributeName9,
                "スペック項目値9":item?.specCategoryAttributeValue9,
                "スペック項目名10":item?.specCategoryAttributeName10,
                "スペック項目値10":item?.specCategoryAttributeValue10,
            });
        });
        const fileName=`商品一覧_${storeCode}_${utility.getCurrentTimestamp()}.xlsx`;
        const numFmt="text";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.BULK_PRODUCT_UPLOAD_PRODUCT_LIST,numFmt);
        setLoading(false);
    };

    /**
    *
    * @description - Make Product name linkable to order list page
    */ 
    const productNameLink = (name: any, id: number) => {
        return (
            <Link to={`${process.env.REACT_APP_PRODUCT_BASE_URL}/${id}.html`} target="_blank" rel="noopener noreferrer" className="Order_id_link">
                {name}
            </Link>
        );
    };
    const textAlignRight = (value: any) => {
        return (
            <Box className={`${value ? "product_text_right " : "product_text_right"}`}>{new Utility().numberWithCommas(value)}</Box>
        );
    };

    const productLinkNavigate = (id:any) => {
        dispatch(commonActions.IS_PRODUCT_LIST({currentPage: currentPage,limit: limit}));
        dispatch(commonActions.PRODUCT_LIST_COLUMN_FILTER({search: search, flag: appliedFilter1, productCode: appliedFilter2, individualShippingFee: appliedFilter3, colFilter: colFilter, direction: direction}));
        dispatch(commonActions.HIDE_COLUMN_FILTER(hideColumnArr));
        navigate(ROUTES.SINGLE_PRODUCT_DETAILS , {state:{id}});
    };

    const productCodeLink = (code:any , id:any) => {
        return (
            <Box component="a" onClick={() => productLinkNavigate(id) } rel="noopener noreferrer" className="Order_id_link">{code}</Box>
        );
    };

    const apiTableData = () => {
        {
            products?.bulkProductLogs?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    productCodeLink(item?.productCode,item?.id),
                    item?.cainzProductCode,
                    productNameLink(item?.productName, item?.cainzProductCode),                  
                    textAlignRight(item?.productPrice),
                    textAlignRight(item?.shippingFee),
                    item?.saleflag,
                    item?.salePublicatonStartDate,
                    item?.salePublicatonEndDate,
                    item?.finalReflectionCompletionDate,
                   
                );
            });
        }
    };
    apiTableData();

    /**
    *
    * @description - Function to handle search functionality in product list
    */ 
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    /**
    *
    * @description - Function to handle filter in product list
    */ 
    const handlePublicFlag = (e: any) => {
        const { value } = e.target;
        setAppliedFilter1(value);
    };

    /**
    *
    * @description - Function to handle filter in product list
    */ 
    const handleProductCode = (e: any) => {
        const { value } = e.target;
        setAppliedFilter2(value);
    };

    const handleIndividualShiipingFee = (e: any) => {
        const { value } = e.target;
        setAppliedFilter3(value);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            productData(currentPage,limit);
        }
    };

    const handleLiveImagesCloseModal =()=>{
        setAnchorEl(null);
    };

    /**
    *
    * @description - Function to open instruction popover in product list
    */ 
    const handleInsPopoverOpen = (event: any) => {
        setAnchorElPopover(event.currentTarget);
    };

    /**
    *
    * @description - Function to close instruction popover in product list
    */  
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    useEffect(() => {
        const handleClickOutside = (event: { target: any; }) => {
            if (node.current && !node.current.contains(event.target)) {
                setShowDownloadOptions(false);
               
            } 
        };              
        document.addEventListener("mousedown", handleClickOutside);       
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);                                      
 
    return (
        <>
            <Popover
                open={Boolean(anchorElPopover)}
                anchorEl={anchorElPopover}
                onClose={handleInsPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                className="instruction_popover">

                <Box className="instruction_popover_box">
                    <Typography sx={{pb:2}}>{t("product_list.instruction_no_1")}</Typography>
                    <Typography sx={{pb:2}}>{t("product_list.instruction_no_2")}</Typography>
                    <Typography sx={{pb:2}}>{t("product_list.instruction_no_3")}</Typography>
                    <Typography sx={{pb:2}}>{t("product_list.instruction_no_4")}</Typography>
                </Box>
            </Popover>

            <Popover
                onClose={handleLiveImagesCloseModal}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                className="Image_upload_popover">
           
            </Popover>

            <Box className="manage_inventory">
                <Container sx={{ my: 4 }}>
                    <Grid container spacing={2} sx={{ mb: 5 }}  className="image-upload-search heading_grid">
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <HeaderTitle title={t("product_list.product_list")} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className="product_list_inst_icon" >
                            <Box component="span">
                                <Typography className="about_uploading_image">{t("product_list.outer_text")}
                                    <InfoRoundedIcon                                         
                                        onClick={handleInsPopoverOpen} 
                                        color="primary" 
                                        className="image_upload_instruction_icon"/>
                                </Typography>
                            </Box> 
                        </Grid>    
              
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ py: 1}}>
                            <TextField
                                label={t("product_list.search_place_holder_product")}
                                onChange={handleSearchChange}
                                onKeyUp={handleKeyPress}
                                value={search}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={5} lg={7}>
                            {showFilterOptions ?
                                <FilterAltOffIcon className="filter_icon" onClick={()=>setShowFilterOptions(false)}/>
                                :
                                <FilterAltIcon className="filter_icon" onClick={()=>setShowFilterOptions(true)}/>
                            }
                        </Grid>       
                
                        <Grid item xs={10} sm={4} md={3} lg={2} ref={node}  >
                            {totalCount <= PRODUCT_LIST.PRODUCT_LIST_DOWNLOAD_LIMIT_10000 ? 
                                <DownloadButton loading={loading}  route={window.location.pathname} functionToExecute={()=>downloadProductData("")} className="wd_100" btnName= {t("product_list.batch_download")} disableCondition={skeleton || loading || products?.bulkProductLogs?.length == 0 || !products?.bulkProductLogs}/>
                                : totalCount <= PRODUCT_LIST.PRODUCT_LIST_DOWNLOAD_LIMIT_300000 ? 
                                    <>
                                        <DownloadButton loading={loading} route={window.location.pathname} functionToExecute={()=>setShowDownloadOptions(!showDownloadOptions)} className="wd_100" btnName= {t("product_list.batch_download")} disableCondition={skeleton || loading || products?.bulkProductLogs?.length == 0 || !products?.bulkProductLogs} endIconRequired={showDownloadOptions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/>}/>
                                        {showDownloadOptions ?
                                            <><Box sx={{position:"relative"}}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ marginTop:"1px", textTransform: "capitalize"}}
                                                    className="product_list_Download_button margin_excel_download_product"
                                                    disabled={skeleton ||  (totalCount <= PRODUCT_LIST.PRODUCT_LIST_DOWNLOAD_LIMIT_50000 ? false : true) }
                                                    onClick={()=>downloadProductData("xlsx")}
                                                >
                                                    <Box component={"span"} sx={{fontSize:16}}>  
                                                        {t("product_list.excel_download")} 
                                                        <Box component={"div"} sx={{fontSize:10}}>
                                                            {t("product_list.excel_download_error")} 
                                                        </Box>
                                                    </Box>
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ marginTop:"1px", textTransform: "capitalize"}}
                                                    className="product_list_Download_button margin_csv_download_product"
                                                    disabled={skeleton}
                                                    onClick={()=>downloadProductData("csv")}
                                                >
                                                    <Box component={"span"} sx={{fontSize:16}}>
                                                        {t("product_list.csv_download")}
                                                        <Box component={"div"} sx={{fontSize:10}}>
                                                            {t("product_list.csv_download_error")}
                                                        </Box>
                                                    </Box>                                                                                   
                                                </Button>
                                            </Box>
                                            </>
                                            : null
                                        }                              
                                    </> 
                                    :    
                                    <>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            endIcon={<ArrowDropDownIcon />}
                                            disabled={true}
                                        >
                                            {t("product_list.batch_download")}                                          
                                        </Button>
                                        <Typography>{t("product_list.download_limit_exceed")}</Typography>
                                    </>
                            }
                        </Grid>
                        {showFilterOptions ? 
                            <>
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-multiple-name-label">{t("product_list.public_flag")}</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            size="small"
                                            value={appliedFilter1}
                                            onChange={(e) =>handlePublicFlag(e)}
                                            fullWidth
                                            input={<OutlinedInput label={t("product_list.public_flag")} />}
                                        >
                                            <MenuItem value={"false"}>

                                                {t("product_list.private")}

                                            </MenuItem>

                                            <MenuItem value={"true"}>

                                                {t("product_list.public")}

                                            </MenuItem>

                                            <MenuItem value={"none"} >

                                                {t("product_list.all")}

                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-multiple-name-label">{t("product_list.cainz_product_code")}</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            size="small"
                                            value={appliedFilter2}
                                            onChange={(e) =>handleProductCode(e)}
                                            fullWidth
                                            input={<OutlinedInput label={t("product_list.cainz_product_code")} />}
                                        >                                   
                                            <MenuItem value="true">
                                                {t("product_list.registered")}
                                            </MenuItem>
                                            <MenuItem value="false">
                                                {t("product_list.unregistered")}
                                            </MenuItem>

                                            <MenuItem value="none">
                                                {t("product_list.all")}

                                            </MenuItem>                                 
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-multiple-name-label">{t("product_list.individual_shipping_fee")}</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            size="small"
                                            value={appliedFilter3}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 350,  
                                                },
                                            }}   
                                            onChange={(e) =>handleIndividualShiipingFee(e)}
                                            fullWidth
                                            input={<OutlinedInput label={t("product_list.individual_shipping_fee")} />}
                                        >         
                                            <MenuItem value="none">
                                                {t("product_list.all")}
                                            </MenuItem>       
                                            {shippingFeeOptions?.map((item:any)=>{
                                                return(
                                                    <MenuItem key={item?.id} value={item?.fee}>
                                                        {item?.fee}
                                                    </MenuItem>   
                                                );
                                            })}                                                 
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>                        
                            :
                            null}
                      
                    </Grid>
                </Container>
                {/* Table header ends here */}

                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <>
                        <div className="main">
                            <Container className="product_list_table no_data_text_align_center first_table_cell_small_width">
                                <MUIDataTable
                                    title={""}
                                    columns={columns}
                                    data={apiData}
                                    options={{
                                        pagination: false,
                                        print: false,
                                        download: false,
                                        filter: false,
                                        search: false,
                                        selectableRowsHideCheckboxes: true,
                                        textLabels: {
                                            body: {
                                                noMatch: t("table_text.no_match").toString(),
                                            },
                                            viewColumns: {
                                                title: t("table_text.show_columns").toString(),
                                                titleAria: "Show/Hide Table Columns",
                                            },
                                            toolbar: {
                                                search: t("table_text.search").toString(),
                                                viewColumns: t("table_text.view_columns").toString(),
                                            },
                                        },
                                        searchPlaceholder: t("table_text.search_placeholder").toString(),
                                        responsive: "standard",
                                        onSearchChange: (searchText: any) => {
                                            setSearch(searchText ? searchText : "");
                                        },
                                        onViewColumnsChange: (changedColumn:string,action:string) => {
                                            const tempHideColArr=[...hideColumnArr];
                                            if(action==="remove"){
                                                changedColumn=="Product code"?
                                                    tempHideColArr.push("商品コード"):
                                                    changedColumn=="Product Name"?
                                                        tempHideColArr.push("商品名"):
                                                        changedColumn=="Product price including tax"?
                                                            tempHideColArr.push("税込商品価格"):
                                                            changedColumn=="Individual shipping fee"?
                                                                tempHideColArr.push("個別送料"):
                                                                changedColumn=="public flag"?
                                                                    tempHideColArr.push("公開フラグ"):
                                                                    changedColumn=="publication start date"?
                                                                        tempHideColArr.push("公開開始日"):
                                                                        changedColumn=="publication end date"?
                                                                            tempHideColArr.push("公開終了日"):
                                                                            changedColumn=="Last reflected date"?
                                                                                tempHideColArr.push("最終反映実施日"):
                                                                                tempHideColArr.push(changedColumn);
                                                setHideColumnArr(tempHideColArr);
                                            }
                                            else{
                                                let index =-1;
                                                changedColumn=="Product code"?
                                                    index = tempHideColArr.indexOf("商品コード"):
                                                    changedColumn=="Product Name"?
                                                        index = tempHideColArr.indexOf("商品名"):
                                                        changedColumn=="Product price including tax"?
                                                            index = tempHideColArr.indexOf("税込商品価格"):
                                                            changedColumn=="Individual shipping fee"?
                                                                index = tempHideColArr.indexOf("個別送料"):
                                                                changedColumn=="public flag"?
                                                                    index = tempHideColArr.indexOf("公開フラグ"):
                                                                    changedColumn=="publication start date"?
                                                                        index = tempHideColArr.indexOf("公開開始日"):
                                                                        changedColumn=="publication end date"?
                                                                            index = tempHideColArr.indexOf("公開終了日"):
                                                                            changedColumn=="Last reflected date"?
                                                                                index = tempHideColArr.indexOf("最終反映実施日"):
                                                                                index = tempHideColArr.indexOf(changedColumn);
                                                tempHideColArr.splice(index,1);
                                                setHideColumnArr(tempHideColArr);
                                            }
                                        }  
                                    }}
                                />
                            </Container>
                        </div>
                    </>
                )}
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={productData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Box>
        </>
    );
};

export default ProductList;

