import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "\"Segoe UI\"",
            "Roboto",
            "\"Helvetica Neue\"",
            "Arial",
            "sans-serif",
            "\"Apple Color Emoji\"",
            "\"Segoe UI Emoji\"",
            "\"Segoe UI Symbol\"",
        ].join(","),
    },
    palette: {
        primary: {
            main: "#00684d",
        },
        secondary: {
            main: "#ffc107",
        },
    },
    components: {
        
        MuiButton: {
            styleOverrides: {
                text: {
                    backgroundColor: "#00684d",
                    fontSize: "1rem",
                    textTransform:"capitalize",
                    padding: "1rem",
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "#00684d",
                    },
                },
            },
        },
        MUIDataTable:{
            styleOverrides:{
                root: {
                    boxShadow: "none!important",
                    backgroundColor: "transparent!important",
                }
            }
        },
        
        MUIDataTableViewCol:{
            styleOverrides:{
                root: {
                    padding:" 16px 24px 16px 24px!important",
                }
            }
        },
        // MuiPaper: {
        //     styleOverrides: {
        //         elevation1: {
        //             // boxShadow: "none",
        //         },
        //     },
        // },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    minWidth: "auto", 
                },
            },
        },
        
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.1)"

                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    display:"inline-flex",
                    flexDirection: "column",
                    position: "relative",
                    minWidth:0,
                    border: 0,
                    verticalLlign: "middle",
                }
            }
        },
        MuiTableCell: {
            styleOverrides:{
                root:{
                    verticalAlign: "baseline",
                    borderBottom:" 1px solid rgba(224, 224, 224, 1)",
                    // width:"100%"
                }
            }
        },
        
    },
});

export default theme;
