class UserModel {
    private _userId!: number;
    private _invitationId!: number;
    private _name!: string;
    private _email!: string;
    private _userType!: number;
    private _accessToken!: string;
    private _status!: number;
    private _statusText!: string;

    public static parseJson(itemUser: UserModel) {
        const users: UserModel = new UserModel();
        users.userId = itemUser.userId;
        users.invitationId = itemUser.invitationId;
        users.name = itemUser.name;
        users.email = itemUser.email;
        users.userType = itemUser.userType;
        users.accessToken = itemUser.accessToken;
        users.status = itemUser.status;
        users.statusText = itemUser.statusText;
        return users;
    }

    public get userId() {
        return this._userId;
    }
    public set userId(userId: number) {
        this._userId = userId;
    }
    public get invitationId() {
        return this._invitationId;
    }
    public set invitationId(invitationId: number) {
        this._invitationId = invitationId;
    }
    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
    public get email() {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }
    public get userType() {
        return this._userType;
    }
    public set userType(userType: number) {
        this._userType = userType;
    }
    public get accessToken() {
        return this._accessToken;
    }
    public set accessToken(accessToken: string) {
        this._accessToken = accessToken;
    }
    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }
    public get statusText() {
        return this._statusText;
    }
    public set statusText(status: string) {
        this._statusText = status;
    }
}

export default UserModel;