import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import { LIST_LIMIT } from "../../../constants/constants";
import {ActivityLogWithPaginationType} from "../../../interfaces/activityLogInterface";


class ActivityLogService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async getActivityLog(
        page_id: number,
        limit = LIST_LIMIT.ACTIVITY_LOG_LIST_LIMIT,
        to  : Date | string, 
        from : Date | string,
        name:string,
        screen:string
    ){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(`${API.LOCAL_BASE_URL}${mockAPIPath.ACTIVITY_LOG}`).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ACTIVITY_LOG}?page=${page_id}&limit=${limit}&to=${to}&from=${from}&name=${name}&screen=${screen}`, this.payload, this.headers).get();
        if(response?.data?.success) {
            const { data, screenNames, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;
            const activityData : ActivityLogWithPaginationType = {
                activityLogs: data,
                screenNames:screenNames,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount
                }
            };
            return activityData;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
    
    async downloadActivityLogList (
        to  : Date | string, 
        from : Date | string,
        name:string,
        screen:string
    ){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(`${API.LOCAL_BASE_URL}${mockAPIPath.ACTIVITY_LOG}`).get() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ACTIVITY_LOG}?to=${to}&from=${from}&name=${name}&screen=${screen}&download=true`, this.payload, this.headers).get();
   
        if(response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;
            
            const activityData : ActivityLogWithPaginationType = {
                activityLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount
                }
            };
            return activityData;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }


}

export default ActivityLogService;