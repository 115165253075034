import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Modal,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Typography from "@mui/material/Typography";
import OTPService from "../../Auth/services/OTPService";
import ExhibitionUserListService from "../service/ExhibitionUserListService";
import AuthService from "../../Auth/services/AuthService";

const EmailOTP = (props: any) => {
    const { showSnackbar, handleLoader, logout } = props;

    /* Language translate function*/
    const [t] = useTranslation();
    /* Language translate function*/


    const [otp, setOtp] = useState<any>();
    // const [error, setError] = useState("");
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    // const [alertMessage, setAlertMessage] = useState("");
    const location = useLocation();
    const email = location?.state?.email;
    // const [successAlert, setSuccessAlert] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    // const [fullWidthL, setFullWidthL] = useState<number>(0);

    const [, forceUpdate] = useState(0);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                SingleByteNumber: {
                    message: t("registerpage.single_byte_numbers"),
                    rule: (value) => isSingleByte(value),
                },
            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "otp" ? setOtp(value) : null;
        validator.current.hideMessageFor(name);
    };

    const updateEmail = () => {
        const payload = { email };
        const isHitDummyURL = false;
        new ExhibitionUserListService(payload, isHitDummyURL)
            .updateEmail()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res.message, true);
                setModalOpen(true);
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email: email, otp: +otp };
            const isHitDummyURL = false;
            new OTPService(payload, isHitDummyURL)
                .verify()
                .then(() => {
                    updateEmail();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };

    const handleLogout = () => {
        new AuthService().logout();
        logout();
    };

    return (
        <>
            {/* VerifiedOTP page starts here */}
            <Container>
                <Grid container spacing={4}>
                    {/* offset grid with column*/}
                    <Grid item xs={12} md={3}></Grid>
                    {/* main grid column! */}
                    <Grid item xs={12} md={6}>
                        <Box className="mt_300">
                            <Grid item xs={12} md={12}>
                                <Grid>
                                    <Typography
                                        variant="h6"
                                        className="text_center"
                                        sx={{ py: 2 }}
                                    >
                                        {t("otp_email.enterotp")}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label={t("otp_email.enterotp")}
                                        variant="outlined"
                                        onChange={(e) => handleChange(e)}
                                        name="otp"
                                        type="otp"
                                        value={otp}
                                        inputProps={{ maxLength: 4 }}
                                        onInput={(e) => handleInputType(e)}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("otp", otp, ["required","SingleByteNumber"])}
                                    <Grid item xs={12} md={12} sx={{ my: 2 }}>
                                        <Button fullWidth onClick={handleSubmit}>
                                            {t("otp_email.submit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {/* offset grid with column*/}
                    <Grid item xs={12} md={3}></Grid>
                </Grid>

                {/* offset grid with column*/}
                <Grid item xs={12} md={3}></Grid>
            </Container>
            <Modal
                open={modalOpen}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box className="center-model" sx={{ p: 4 }}>
                    <Typography
                        id="delete-modal-title"
                        variant="h6"
                        component="h2"
                    ></Typography>
                    <Typography id="delete-modal-description" sx={{ m: 2 }}>
                        {t("exhibition_user_list.email_update_message")}
                    </Typography>
                    <Button sx={{ m: 2, p: 1 }} onClick={() => handleLogout()}>
                        {t("exhibition_user_list.ok")}
                    </Button>
                </Box>
            </Modal>
            {/* VerifiedOTP page starts here */}
        </>
    );
};

export default EmailOTP;
