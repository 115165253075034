class ImageModel {
    [x: string]: any;
    private _id!: number;
    private _url!: string;

    public static parseJson(itemImage: ImageModel) {
        const images: ImageModel = new ImageModel();
        images.id = itemImage.id;
        images.url = itemImage.url;
        return images;
    }

    public get id() {
        return this._id;
    }
    public set id(id: number) {
        this._id = id;
    }
    public get url() {
        return this._url;
    }
    public set url(url: string) {
        this._url = url;
    }
}

export default ImageModel;