import React, { useEffect, useState, useRef } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    Autocomplete,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";
import { SINGLE_PRODUCT_DETAILS } from "../../../constants/constants";
import SimpleReactValidator from "simple-react-validator";
import { mockAPIPath } from "../config/singleProductDetailsApi";
import SingleProductDetailsService from "../services/SingleProductDetailsService";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import ScrollabledInput from "../../common/ScrollabledInput/Index";

const SingleProductDetails = (props: any) =>{

    const [productCode,setProductCode] = useState<any>("");
    const [productName,setProductName] = useState<any>("");
    const [cainzProductCode,setCainzProductCode] = useState<any>("");
    const [brand,setBrand] = useState<any>("");
    const [manufacturer,setManufacturer] = useState<any>("");
    const [consumptionTaxCategory,setConsumptionTaxCategory] = useState<any>("");
    const [ecCategory,setEcCategory] = useState<any>("");
    const [productPriceIncludingTax,setProductPriceIncludingTax] = useState<any>("");
    const [individualShippingFee,setIndividualShippingFee] = useState<any>("");
    const [maxOrderQuantity,setMaxOrderQuantity] = useState<any>("");
    const [deliveryTimeWording,setDeliveryTimeWording] = useState<any>("");
    const [liquorCategory,setLiquorCategory] = useState<any>("");
    const [saleFlag,setSaleFlag] = useState<any>("");
    const [productOutlineExplaination,setProductOutlineExplaination] = useState<any>("");
    const [productDetails,setProductDetails] = useState<any>("");
    const [valueFrom, setValueFrom] = React.useState<Date | null>(null);
    const [valueTo, setValueTo] = React.useState<Date | null>(null);
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const { t, i18n } = useTranslation();
    const [specCat, setSpecCat] = useState<any>([{id: Date.now() , attribute: "" , value: ""}]);
    const specCatDropdownres = i18n.language == "en-GB" ? SINGLE_PRODUCT_DETAILS.SPECIAL_CATEGORY : SINGLE_PRODUCT_DETAILS.SPECIAL_CATEGORY_JP ;
    const [specCatDropdown,setSpecCatDropdown] = useState(specCatDropdownres);
    const [alreadyExist,setAlreadyExist] = useState([]);
    const [shippingFeeres,setShippingFeeres]= useState([]);
    const [ecCategoryres,setEcCategoryres]= useState([]);
    const [formDetails,setFormDetails] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [specialCategoryres,setSpecialCategoryres] = useState<any>([]);
    const { handleLoader, showSnackbar } = props;
    const dragItem = React.useRef<any>(null);
    const dragOverItem = React.useRef<any>(null);
  
    //const handle drag sorting
    const handleSort = () => {
        //duplicate items
        const specCatItems = [...specCat];
  
        //remove and save the dragged item content
        const draggedItemContent = specCatItems.splice(dragItem.current, 1)[0];
  
        //switch the position
        specCatItems.splice(dragOverItem.current, 0, draggedItemContent);
  
        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;
  
        //update the actual array
        setSpecCat(specCatItems);
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
    };

    const handleDateFrom = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleDateTo = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };

    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };

    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    const handleAddSpecialCategory = () => {
        if(specCat.length < 10){
            const values = [...specCat];
            values.push({id: Date.now() , attribute: "" , value: ""});
            setSpecCat([...values]);
        }
    };

    const handleRemoveSpecialCategory = (idToRemove:any) => {
        setSpecCat(specCat.filter((field: any) => field.id !== idToRemove));
    };

    const SingleProductDetailsValidation = () => {
        if (validator.current.allValid()) {
            // handleLoader(true); 
            const payload={
                "productCode": productCode,
                "cainzProductCode": "5000000010115",
                "productName": productName,
                "brand": brand,
                "manufacturer": manufacturer,
                "saleflag": saleFlag,
                "salePublicatonStartDate": fromParam,
                "salePublicatonEndDate": toParam, 
                "maximumOrderQuantity": maxOrderQuantity,
                "consumptionTaxCategory": consumptionTaxCategory,
                "productPrice": productPriceIncludingTax,
                "shippingFee": individualShippingFee,
                "liquorCategory": liquorCategory,
                "deliveryTimeWording": deliveryTimeWording,
                "productOutlineExplanation": productOutlineExplaination,
                "ecCategory": ecCategory,
                "description": productDetails ? productDetails : "",
                "specCategoryAttributeName1": specCat.length >= 1 && specCat[0].attribute ? specCat[0].attribute : "",
                "specCategoryAttributeValue1": specCat.length >= 1 && specCat[0].value ? specCat[0].value : "",
                "specCategoryAttributeName2": specCat.length >= 2 && specCat[1].attribute ? specCat[1].attribute : "",
                "specCategoryAttributeValue2": specCat.length >= 2 && specCat[1].value ? specCat[1].value : "",
                "specCategoryAttributeName3": specCat.length >= 3 && specCat[2].attribute ? specCat[2].attribute : "",
                "specCategoryAttributeValue3": specCat.length >= 3 && specCat[2].value ? specCat[2].value : "",
                "specCategoryAttributeName4": specCat.length >= 4 && specCat[3].attribute ? specCat[3].attribute : "",
                "specCategoryAttributeValue4": specCat.length >= 4 && specCat[3].value ? specCat[3].value : "",
                "specCategoryAttributeName5": specCat.length >= 5 && specCat[4].attribute ? specCat[4].attribute : "",
                "specCategoryAttributeValue5": specCat.length >= 5 && specCat[4].value ? specCat[4].value : "",
                "specCategoryAttributeName6": specCat.length >= 6 && specCat[5].attribute ? specCat[5].attribute : "",
                "specCategoryAttributeValue6": specCat.length >= 6 && specCat[5].value ? specCat[5].value : "",
                "specCategoryAttributeName7": specCat.length >= 7 && specCat[6].attribute ? specCat[6].attribute : "",
                "specCategoryAttributeValue7": specCat.length >= 7 && specCat[6].value ? specCat[6].value : "",
                "specCategoryAttributeName8": specCat.length >= 8 && specCat[7].attribute ? specCat[7].attribute : "",
                "specCategoryAttributeValue8": specCat.length >= 8 && specCat[7].value ? specCat[7].value : "",
                "specCategoryAttributeName9": specCat.length >= 9 && specCat[8].attribute ? specCat[8].attribute : "",
                "specCategoryAttributeValue9": specCat.length >= 9 && specCat[8].value ? specCat[8].value : "",
                "specCategoryAttributeName10": specCat.length >= 10 && specCat[9].attribute ? specCat[9].attribute : "",
                "specCategoryAttributeValue10": specCat.length >= 10 && specCat[9].value ? specCat[9].value : "",
            };
            const isHitDummyURL = false;
            new SingleProductDetailsService(payload, isHitDummyURL)
                .saveSingleProductDetails()
                .then((res: any) => {
                    setFormDetails(res.isProductDetails);
                    // console.log(formDetails);
                })
                .catch((err: any) => {
                    // console.log("hello",err?.message);
                    // handleLoader(false);
                    // showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    function onSumbit(){
        SingleProductDetailsValidation();  
    }

    // const ShippingFeeFilterOptions = () => {
    //     const payload={};
    //     const isHitDummyURL = false;
    //     new SingleProductDetailsService(payload, isHitDummyURL)
    //         .shippingFeeFilterOptions()
    //         .then((res: any) => {
    //             setShippingFeeres(res);
    //         })
    //         .catch((err: any) => {
    //             showSnackbar(err?.message, false);
    //         });
    // };
       
    // const EcCategoryFilterOptions = () => {
    //     const payload={};
    //     const isHitDummyURL = false;
    //     new SingleProductDetailsService(payload, isHitDummyURL)
    //         .ecCategoryFilterOptions()
    //         .then((res: any) => {
    //             setEcCategoryres(res);
    //         })
    //         .catch((err: any) => {
    //             showSnackbar(err?.message, false);
    //         });
    // };

    const getSingleProductDetails = () => {
        const payload={};
        const isHitDummyURL = false;
        const newSpecialCategory :any[] = [];
        handleLoader(true);
        new SingleProductDetailsService(payload, isHitDummyURL)
            .showSingleProductDetails(location.state?.id)
            .then((res: any) => {
                handleLoader(false);
                setProductCode(res?.productCode);
                setProductName(res?.productName);
                setCainzProductCode(res?.cainzProductCode);
                setBrand(res?.brand);
                setManufacturer(res?.manufacturer);
                setConsumptionTaxCategory(res?.consumptionTaxCategory);
                setProductPriceIncludingTax(res?.productPrice);
                setIndividualShippingFee(res?.shippingFee);
                setMaxOrderQuantity(res?.maximumOrderQuantity);
                setDeliveryTimeWording(res?.deliveryTimeWording);
                setLiquorCategory(res?.liquorCategory);
                setSaleFlag(res?.saleflag);
                setValueFrom(res?.salePublicatonStartDate);
                setValueTo(res?.salePublicatonEndDate);
                setProductOutlineExplaination(res?.productOutlineExplanation);
                setProductDetails(res?.description); 
                setEcCategory(`${res?.categoryName} (${res?.categoryCode})`);
                newSpecialCategory.push({name:res?.specCategoryAttributeName1,value:res?.specCategoryAttributeValue1},{name:res?.specCategoryAttributeName2,value:res?.specCategoryAttributeValue2},{name:res?.specCategoryAttributeName3,value:res?.specCategoryAttributeValue3},{name:res?.specCategoryAttributeName4,value:res?.specCategoryAttributeValue4},{name:res?.specCategoryAttributeName5,value:res?.specCategoryAttributeValue5},{name:res?.specCategoryAttributeName6,value:res?.specCategoryAttributeValue6},{name:res?.specCategoryAttributeName7,value:res?.specCategoryAttributeValue7},{name:res?.specCategoryAttributeName8,value:res?.specCategoryAttributeValue8},{name:res?.specCategoryAttributeName9,value:res?.specCategoryAttributeValue9},{name:res?.specCategoryAttributeName10,value:res?.specCategoryAttributeValue10});
                setSpecialCategoryres(newSpecialCategory);
            })
            .catch((err: any) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    useEffect(()=>{
        // EcCategoryFilterOptions();
        // ShippingFeeFilterOptions();
        if(location.state === null){
            navigate(ROUTES.PRODUCT_LIST);
        }else{
            window.scrollTo(0, 0);
            getSingleProductDetails();
        }
    },[]);

    const [, forceUpdate] = useState(0);
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("product_details.this_field_cannot_be_empty"),
            },
            validators: {
                productCodeValidation: {
                    message: t("product_details.max_char",{size:20}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                productNameValidation: {
                    message: t("product_details.max_char",{size:150}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                brandValidation: {
                    message: t("product_details.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                manufacturerValidation: {
                    message: t("product_details.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                productPriceIncludingTaxValidation: {
                    message: t("product_details.lessthan_value",{lessthan: 1000000}),
                    rule: (value: number, params: number) => {
                        return value > 0 && value < params;
                    }
                },
                smallerShippingFeeValidation: {
                    message: t("product_details.smaller_shipping_fee"),
                    rule: (value: number) => {
                        return false;
                    }
                },
                maxOrderQuantityValidation: {
                    message: t("product_details.max_value",{max: 500}),
                    rule: (value: number, params: number) => {
                        return value > 0 && value <= params;
                    }
                },
                deliveryTimeWordingValidation: {
                    message: t("product_details.max_char",{size:20}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                productOutlineExplainationValidation: {
                    message: t("product_details.max_char",{size:250}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                productDetailsValidation: {
                    message: t("product_details.max_char",{size:1200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                specialCategoryItemValidation: {
                    message: t("product_details.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                specialCategoryValueValidation: {
                    message: t("product_details.max_char",{size:250}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                forbiddenCharactersValidation: {
                    message: t("product_details.invalid_character"),
                    rule: (value: string) => {
                        return !SINGLE_PRODUCT_DETAILS.FORBIDDEN_CHARACTERS.test(value);
                    }
                },
            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

 
    return(
        <>
            {/* Page header title */}
            <HeaderTitle title={t("product_details.product_details_title")} />
            {/* Page header title */}
        
            <Container>
                <>
                    {/* product information section starts here */}
                    <Box className="ragistration_box">
                        <Typography variant="h6">
                            {t("product_details.product_information")}
                        </Typography>
                        <Box className="bg-gray">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        required
                                        type="text"
                                        value={productCode}
                                        name="productCode"
                                        label={t("product_details.product_code")}
                                        onChange={(e) => {
                                            setProductCode(e.target.value);
                                            validator.current.hideMessageFor("productCode");                                            
                                        }}
                                        onBlur={(e)=>handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("productCode", productCode, ["required" , {productCodeValidation: 20}, {forbiddenCharactersValidation: String}])} */}

                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        name="cainzproductcode"
                                        value={cainzProductCode}
                                        label={t("product_details.cainz_product_code")}
                                    />
                                </Grid>
                                <Grid className="scrollable-input-parent-padding" item xs={12} sm={12} md={12}>
                                    <ScrollabledInput
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={productName}
                                        name="productName"
                                        label={t("product_details.product_name")}
                                        required
                                        onChange={(e:any) => {
                                            setProductName(e.target.value);
                                            validator.current.hideMessageFor("productName");
                                        }}
                                        onBlur={(e:any) => handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("productName", productName, ["required", {productNameValidation: 150}, {forbiddenCharactersValidation: String}])} */}
                                </Grid>
                                <Grid className="scrollable-input-parent-padding" item xs={12} sm={6} md={6}>
                                    <ScrollabledInput
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={brand}
                                        name="brand"
                                        label={t("product_details.brand")}
                                        required
                                        onChange={(e:any) => {
                                            setBrand(e.target.value);
                                            validator.current.hideMessageFor("brand");
                                        }}
                                        onBlur={(e:any) => handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("brand", brand, [{brandValidation: 100}, {forbiddenCharactersValidation: String}])} */}
                                </Grid>
                                <Grid className="scrollable-input-parent-padding" item xs={12} sm={6} md={6}>
                                    <ScrollabledInput
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={manufacturer}
                                        name="manufacturer"
                                        label={t("product_details.manufacturer")}
                                        required
                                        onChange={(e:any) => {
                                            setManufacturer(e.target.value);
                                            validator.current.hideMessageFor("manufacturer");
                                        }}
                                        onBlur={(e:any) => handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("manufacturer", manufacturer, [{manufacturerValidation: 200}, {forbiddenCharactersValidation: String}])} */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={consumptionTaxCategory}
                                        label={t("product_details.consumption_tax_category")}
                                    />
                                    {/* {validator.current.message("consumptionTaxCategory", consumptionTaxCategory, ["required"])} */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={liquorCategory}
                                        label={t("product_details.liquor_category")}
                                    />
                                    {/* {validator.current.message("liquorCategory", liquorCategory, ["required"])} */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        required
                                        type="number"
                                        value={productPriceIncludingTax}
                                        name="productPriceIncludingTax"
                                        label={t("product_details.product_price_including_tax")}
                                        onChange={(e) => {
                                            setProductPriceIncludingTax(parseInt(e.target.value));
                                            validator.current.hideMessageFor("productPriceIncludingTax");
                                        }}
                                        onBlur={(e)=>handleOnBlur(e)}
                                        onInput={(e) => handleInputType(e)}
                                    />
                                    {/* {validator.current.message("productPriceIncludingTax", productPriceIncludingTax, ["required", {productPriceIncludingTaxValidation: 1000000} ])} */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={individualShippingFee}
                                        label={t("product_details.individual_shipping_fee")}
                                    />
                                    {/* <FormControl fullWidth disabled>
                                        <InputLabel id="individualShippingFee">{t("product_details.individual_shipping_fee")}</InputLabel>
                                        <Select
                                            labelId="individualShippingFee"
                                            name="individualShippingFee"
                                            label={t("product_details.individual_shipping_fee")}
                                            value={individualShippingFee}
                                            fullWidth
                                            sx={{backgroundColor: "white"}}
                                            onChange={(e) => {
                                                setIndividualShippingFee(parseInt(e.target.value));
                                                validator.current.hideMessageFor("individualShippingFee");
                                            }}
                                            onBlur={(e)=>handleOnBlur(e)}
                                        >
                                            {(shippingFeeres.map((item:any) => {return item.fee;})).map((item,index)=> (<MenuItem key={index} value={item}>{item.toLocaleString()}</MenuItem>))}
                                        </Select>
                                    </FormControl> */}
                                    {/* {validator.current.message("individualShippingFee", individualShippingFee, ["required"])} */}
                                    {/* {(productPriceIncludingTax && individualShippingFee && productPriceIncludingTax <= individualShippingFee) ? validator.current.message("individualShippingFee", individualShippingFee, [{smallerShippingFeeValidation : Number}]) : null} */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="number"
                                        required
                                        value={maxOrderQuantity}
                                        name="maxOrderQuantity"
                                        label={t("product_details.maximum_order_quantity")}
                                        onChange={(e) => {
                                            setMaxOrderQuantity(parseInt(e.target.value));
                                            validator.current.hideMessageFor("maxOrderQuantity");
                                        }}
                                        onBlur={(e)=>handleOnBlur(e)}
                                        onInput={(e) => handleInputType(e)}
                                    />
                                    {/* {validator.current.message("maxOrderQuantity", maxOrderQuantity, ["required", {maxOrderQuantityValidation: 500}])} */}
                                </Grid>
                                <Grid className="scrollable-input-parent-padding" item xs={12} sm={6} md={6}>
                                    <ScrollabledInput
                                        type = "text"
                                        value={deliveryTimeWording}
                                        name="deliveryTimeWording"
                                        label={t("product_details.delivery_time_wording")}
                                        required
                                        disabled
                                        onChange={(e:any) => {
                                            setDeliveryTimeWording(e.target.value);
                                            validator.current.hideMessageFor("deliveryTimeWording");
                                        }}
                                        onBlur={(e:any) => handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("deliveryTimeWording", deliveryTimeWording, ["required", {deliveryTimeWordingValidation: 20}, {forbiddenCharactersValidation: String}])} */}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {/* Sales Information starts here */}

                    <Box className="ragistration_box">
                        <Typography variant="h6">
                            {t("product_details.sales_information")}
                        </Typography>
                        <Box className="bg-gray">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"    
                                        value={saleFlag}
                                        label={t("product_details.sale_flag")}
                                    />
                                    {/* <FormControl fullWidth disabled>
                                        <InputLabel id="saleFlag">{t("product_details.sale_flag")}</InputLabel>
                                        <Select
                                            labelId="saleFlag"
                                            name="saleFlag"
                                            label={t("product_details.sale_flag")}
                                            value={saleFlag}
                                            fullWidth
                                            sx={{backgroundColor: "white"}}
                                            onChange={(e) => {
                                                setSaleFlag(e.target.value);
                                                validator.current.hideMessageFor("saleFlag");
                                            }}
                                            onBlur={(e)=>handleOnBlur(e)}
                                        >
                                            {(i18n.language == "en-GB" ? SINGLE_PRODUCT_DETAILS.SALE_FLAG : SINGLE_PRODUCT_DETAILS.SALE_FLAG_JP).map((item,index)=> (<MenuItem key={index} value={item}>{item}</MenuItem>))}
                                        </Select>
                                    </FormControl> */}
                                    {/* {validator.current.message("saleFlag", saleFlag, ["required"])} */}
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} className="date-picker-main" >
                                        <DatePicker
                                            label={t("product_details.sale_publication_start_date")}
                                            value={valueFrom}
                                            inputFormat="yyyy/MM/dd"
                                            onChange={handleDateFrom}
                                            disabled
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params} 
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                        style: { pointerEvents: "none" },
                                                    }}  
                                                    name="valueFrom"
                                                    required
                                                    onBlur={(e)=>handleOnBlur(e)}
                                                />)}
                                            autoFocus={true}
                                            shouldDisableDate={shouldDisableDateFrom} 
                                            showToolbar={false}
                                            componentsProps={{
                                                actionBar: {
                                                    // The actions will be the same between desktop and mobile
                                                    actions: []
                                                }
                                            }}
                                        />  
                                        {/* {validator.current.message("valueFrom", valueFrom, ["required",])}                                                             */}
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} >
                                        <DatePicker
                                            label={t("product_details.sale_publication_end_date")}
                                            value={valueTo}
                                            inputFormat="yyyy/MM/dd"
                                            onChange={handleDateTo}
                                            disabled
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params} 
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                        style: { pointerEvents: "none" },
                                                    }}  
                                                    name="valueTo"
                                                    required
                                                    onBlur={(e)=>handleOnBlur(e)}
                                                />)}                                         
                                            autoFocus={true}
                                            shouldDisableDate={shouldDisableDateTo}
                                            showToolbar={false}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: []
                                                }
                                            }}
                                        />  
                                        {/* {validator.current.message("valueTo", valueTo, ["required",])} */}
                                    </LocalizationProvider>
                                </Grid>

                            </Grid>
                        
                        </Box>
                    </Box>

                    {/* Categories starts here */}

                    <Box className="ragistration_box">
                        <Typography variant="h6">
                            {t("product_details.categories")}
                        </Typography>
                        <Box className="bg-gray">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        value={ecCategory}
                                        label={t("product_details.ec_category")}
                                    />
                                    {/* <FormControl fullWidth disabled>
                                        <InputLabel id="ecCategory">{t("product_details.ec_category")}</InputLabel>
                                        <Select
                                            defaultValue={ecCategory}
                                            labelId="ecCategory"
                                            name="ecCategory"
                                            label={t("product_details.ec_category")}
                                            value={ecCategory}
                                            fullWidth
                                            sx={{backgroundColor: "white"}}
                                            onChange={(e) => {
                                                setEcCategory(e.target.value);
                                                validator.current.hideMessageFor("ecCategory");
                                            }}
                                            onBlur={(e)=>handleOnBlur(e)}
                                        >
                                            {(ecCategoryres.map((item:any) => {return `${item.name} (${item.id})`;})).map((item,index)=> (<MenuItem key={index} value={item}>{item}</MenuItem>))}
                                        </Select>
                                    </FormControl> */}
                                    {/* {validator.current.message("ecCategory", ecCategory, ["required"])} */}
                                </Grid>

                            </Grid>
                        
                        </Box>
                    </Box>

                    {/* product description starts here */}

                    <Box className="ragistration_box">
                        <Typography variant="h6">
                            {t("product_details.product_description")}
                        </Typography>
                        <Box className="bg-gray">
                            <Grid container sx={{flexGrow: 30}} spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        className="bg-white"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            rows: 4,
                                            multiline: true,
                                            inputComponent: "textarea"
                                        }}
                                        type="text"    
                                        value={productOutlineExplaination}
                                        name="productOutlineExplaination"
                                        label={t("product_details.product_outline_explanation")}
                                        required
                                        // onKeyDown={(e)=>{
                                        //     if(e.key === "Enter"){
                                        //         e.preventDefault();
                                        //     }
                                        // }}
                                        // onChange={(e) => {
                                        //     setProductOutlineExplaination(e.target.value);
                                        //     validator.current.hideMessageFor("productOutlineExplaination");
                                                
                                        // }}
                                        // onBlur={(e)=>handleOnBlur(e)}
                                    />
                                    {/* {validator.current.message("productOutlineExplaination", productOutlineExplaination, ["required", {productOutlineExplainationValidation: 250}, {forbiddenCharactersValidation: String}])} */}
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            rows: 4,
                                            multiline: true,
                                            inputComponent: "textarea"
                                        }}
                                        type="text"
                                        className="product_text_area"
                                        value={productDetails}
                                        name="productDetails"
                                        label={t("product_details.product_details")}
                                        onKeyDown={(e)=>{
                                            if(e.key === "Enter"){
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setProductDetails(e.target.value);
                                            validator.current.hideMessageFor("productDetails");
                                        }}
                                        onBlur={(e)=>handleOnBlur(e)}
                                    />
                                    {validator.current.message("productDetails", productDetails, [{productDetailsValidation: 1200}, {forbiddenCharactersValidation: String}])}
                                </Grid> */}

                            </Grid>
                        
                        </Box>
                    </Box>

                    {/* special categories starts here */}
                    
                    <Box className="special_category_box">
                        <Typography variant="h6">
                            {t("product_details.special_categories")}
                        </Typography>
                        {/* <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            
                            <Button variant="contained" disabled={specCat.length >= 10} sx={{float: "right"}} onClick={handleAddSpecialCategory}>ADD</Button>
                        </Grid> */}
                        <Box className="bg-gray">
                            
                            {specialCategoryres.map((item:any , index:any) => (
                                ((item.name !== "" || item.value !== "") || (index === 0)) &&
                                    <Grid container key={index} sx={{flexGrow: 30}} spacing={2} pb={2} pt={2}>
                                        <Grid className="scrollable-input-parent-padding" item xs={12} sm={5} md={5}>
                                            <ScrollabledInput
                                                type = "text"
                                                value={item?.name}
                                                name="name"
                                                //required
                                                disabled
                                                label={`${t("product_details.spec_category_attribute_name")}${index+1}`}
                                            />
                                        </Grid>
                                        <Grid className="scrollable-input-parent-padding" item xs={12} sm={7} md={7}>
                                            <ScrollabledInput
                                                type = "text"
                                                value={item?.value}
                                                name="value"
                                                //required
                                                disabled
                                                label={`${t("product_details.spec_category_attribute_name_right")}${index+1}`}
                                            />
                                        </Grid>
                                    </Grid>
                            ))
                            }
                        </Box>
                        {/* {
                            specCat.length ? 
                                <Box className="bg-gray">
                                
                                    {
                                        specCat.map((field:any , index:any)=>(
                                            <>
                                        
                                                <div
                                                    style={{ cursor: "move" }}
                                                    key={field.id}
                                                    draggable
                                                    onDragStart={(e) => (dragItem.current = index)}
                                                    onDragEnter={(e) => (dragOverItem.current = index)}
                                                    onDragEnd={handleSort}
                                                    onDragOver={(e) => e.preventDefault()}
                                                >
                                                    
                                                    <Grid container  key={field.id} alignItems={"center"} spacing={2} pb={2} pt={2}>
                                                        <Grid container justifyContent={"center"} item xs={12} sm={0.5} md={0.5}>
                                                            <DragIndicatorIcon/>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} sm={3.5} md={3.5} mb={1}>
                                                            <Autocomplete
                                                                value={specCat[index].attribute}
                                                                fullWidth
                                                                freeSolo
                                                                options={specCatDropdown}
                                                                renderInput={(params) => <TextField  {...params} value={specCat[index].attribute}  fullWidth name="field.attribute" label={`${t("product_details.spec_category_attribute_name")} ${index+1}`} required
                                                                />}
                                                                onChange ={(e,value) => {
                                                                    const res = specCat.map((e:any) => e.attribute);
                                                                    if (res.includes(value)){
                                                                        setSpecCatDropdown(specCatDropdown.filter((attr: any) => attr !== value));
                                                                    }
                                                                    const newForm = [...specCat];
                                                                    newForm[index].attribute = value;
                                                                    setSpecCat(newForm);
                                                                }}
                                                                onInputChange={(e,value) => {
                                                                    const res = specCat.map((e:any) => e.id != field.id && e.attribute);
                                                                    if (res.includes(value)){
                                                                        setAlreadyExist((errList:any) => {
                                                                            errList[field.id] = field.id;
                                                                            return errList;
                                                                        });
                                                                    }else{
                                                                        setAlreadyExist((errList:any) => {
                                                                            errList[field.id] = null;
                                                                            return errList;
                                                                        });
                                                                    }
                                                                    const newForm = [...specCat];
                                                                    newForm[index].attribute = value;
                                                                    setSpecCat(newForm);
                                                                }}
                                                                
                                                            />
                                                            { (specCat[index].value || specCat[index].attribute) && (!specCat[index].attribute ? (<Box style={{color: "red", position:"absolute",}}>{t("product_details.this_field_cannot_be_empty")}</Box>) : (specCat[index].attribute.length > 100 ? (<div style={{color: "red", position:"absolute"}}>{t("product_details.max_char",{size:100})}</div>) : ((SINGLE_PRODUCT_DETAILS.FORBIDDEN_CHARACTERS.test(specCat[index].attribute)) ? (<div style={{color: "red", position:"absolute"}}>{t("product_details.invalid_character")}</div>) : (alreadyExist[field.id] && field.id === alreadyExist[field.id] ? (<div style={{color: "red", position:"absolute"}}>{t("product_details.already_exist")}</div>) : null) )))}
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6.5} mb={1}>
                                                            <TextField
                                                                value={specCat[index].value}
                                                                fullWidth
                                                                type="text"
                                                                name="field.value"
                                                                label={`${t("product_details.spec_category_item_value")} ${index+1}`}
                                                                required
                                                                onChange={(e) => {
                                                                    const newForm = [...specCat];
                                                                    newForm[index].value = e.target.value;
                                                                    setSpecCat(newForm);
                                                                }}
                                                                // onBlur={(e) => specialCategoryOnBlur(e,index,field.id)}
                                                            />
                                                            { (specCat[index].value || specCat[index].attribute) && (!specCat[index].value ? (<Box style={{color: "red", position:"absolute"}}>{t("product_details.this_field_cannot_be_empty")}</Box>) : (specCat[index].value.length > 250 ? (<div style={{color: "red", position:"absolute"}}>{t("product_details.max_char",{size:250})}</div>) : ((SINGLE_PRODUCT_DETAILS.FORBIDDEN_CHARACTERS.test(specCat[index].value)) ? (<div style={{color: "red", position:"absolute"}}>{t("product_details.invalid_character")}</div>) : null )))}
                                                        </Grid>
                                                        <Grid item xs={12} sm={2} md={1.5}>
                                                            <Button variant="contained" disabled={specCat.length <= 1} sx={{float: "right"}} onClick={() => handleRemoveSpecialCategory(field.id)}>DELETE</Button>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>
                                        ))
                                    }
                        
                                </Box>
                                : null
                        } */}
                    </Box>

                    {/* CANCEL and SUBMIT buttons */}

                    <Box className="ragistration_box">
                        <Grid container item alignItems={"center"} gap={2} xs={12} sm={12} md={12}>
                            <Button variant="contained"  onClick={()=> navigate(ROUTES.PRODUCT_LIST)}>{t("product_details.back")}</Button>
                            {/* <Button onClick={onSumbit} >SUBMIT</Button> */}
                        </Grid>
                    </Box>
                </>

            </Container>
        </>
    );

};

export default SingleProductDetails;