import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class ReturnOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async returndOrder() {
        let returnOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? ( returnOrderService = await new AxiosUtils(
                `${mockAPIPath.RETURN_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (returnOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.RETURN_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return  returnOrderService;
    }
}

export default ReturnOrderDetail;

