import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

export class DeclineOrderDetail {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "Content-Type": "multipart/form-data", "access-token": new Cookies("access-token").read() };
    }

    async declineOrder() {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.DECLINE_ORDER, this.payload,this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DECLINE_ORDER}`, this.payload,this.headers).post();
        let selectreason: string;
        if(response?.data?.success) {
            selectreason = response?.data?.message;
            return selectreason;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

