export const mockAPIPath = {
    STORELIST : "./data/storelist.json",
    INVITE_USER : "",
    VERIFY_INVITE_LINK : "./data/verifyInviteLink.json"
};

export const serverAPIPath = {
    STORELIST : "/store",
    INVITE_USER : "/invite/user/",
    VERIFY_INVITE_LINK : "/invite/verify-invite-link/"
};