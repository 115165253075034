/* eslint-disable no-useless-escape */
const CONSTANTS = {
    EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    PASSWORD_REGEX:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-=+{}\[\]\|;:',.<>"\/?])[A-Za-z\d`~!@#$%^&*()_\-=+{}\[\]\|;:',.<>"\/?]{8,16}$/,
    TRACKING_ID_REGEX: /^[^|?/@#$%^&*(),.:;[\]{}=+`~-]+$/,
    USER_TYPE_SUPER_ADMIN: 0,
    USER_TYPE_ADMIN: 1,
    USER_TYPE_SELLER: 2,
    USER_TYPE_EXHIBITOR: 3,
    ALL_REPORTS_FILTER: 0,
    UPLOADED_REPORTS_FILETR: 1,
    PENDING_REPORTS_FILTER: 2,
    UPLOADED_REPORTS_STATUS: 1,
    PENDING_REPORTS_STATUS: 0,
    PASSWORD_TEXT: "**********",
    REGION: "jp",
    REMOVE_OPTION: "removeOption",
    CLEAR: "clear",
    OTP_PASSWORD_TYPE: 0,
    OTP_EMAIL_TYPE: 1,
    OTP_ERROR_MSG: "確認コードが一致しません",
    RESTRICTED_KEYWORD:["cainz"],
    CARRIER_MENU_OMS: [
        "日本郵便",
        "佐川急便",
        "ヤマト運輸",
        "西濃運輸",
        "その他",
    ],
    FIRST_PAGE: 1,
    DEPOSIT_STATUS_REFUND_PROCESS: "返金処理済み",
    SEARCH_DELAY_TIME: 1000,


    SHEET_HEADER: [
        "商品コード",
        "カインズ商品コード",
        "商品名",
        // "商品名（カナ）",
        "ブランド",
        "製造元",
        "消費税区分",
        "税込商品価格",
        "個別送料",
        "最大注文数量",
        "酒類区分",
        "公開フラグ",
        "公開開始日",
        "公開終了日",
        "商品概要説明",
        "配送日数",
        "ECカテゴリ",
        "スペック項目名1",
        "スペック項目値1",
        "スペック項目名2",
        "スペック項目値2",
        "スペック項目名3",
        "スペック項目値3",
        "スペック項目名4",
        "スペック項目値4",
        "スペック項目名5",
        "スペック項目値5",
        "スペック項目名6",
        "スペック項目値6",
        "スペック項目名7",
        "スペック項目値7",
        "スペック項目名8",
        "スペック項目値8",
        "スペック項目名9",
        "スペック項目値9",
        "スペック項目名10",
        "スペック項目値10",
    ],
    INVENTORY_SHEET_HEADER:["カインズ商品コード", "在庫数"],
    TIMER_COLOR_CHANGE_MIN: process.env.REACT_APP_TIMER_COLOR_CHANGE_MIN ?? 0,
    TIMER_COLOR_CHANGE_SEC: process.env.REACT_APP_TIMER_COLOR_CHANGE_SEC ?? 0,
    TIMER_VISIBILITY_MIN: process.env.REACT_APP_TIMER_VISIBILITY_MIN ?? 0,
    TIMER_VISIBILITY_SEC: process.env.REACT_APP_TIMER_VISIBILITY_SEC ?? 0,

    KATAKANA_FULL_WIDTH_ARRAY : [ 
        "０", "１", "２", "３", "４", "５", "６", "７", "８", "９",
        "ガ", "ギ", "グ", "ゲ", "ゴ", "ザ", "ジ", "ズ", "ゼ", "ゾ",
        "ダ", "ヂ", "ヅ", "デ", "ド", "バ", "ビ", "ブ", "ベ", "ボ", "ヴ", 
        "パ", "ピ", "プ", "ペ", "ポ", 
        "ァ", "ィ", "ゥ", "ェ", "ォ", "ャ", "ュ", "ョ", "ッ", "ー", "ヮ", 
        "ア", "イ", "ウ", "エ", "オ", "カ", "キ", "ク", "ケ", "コ", 
        "サ", "シ", "ス", "セ", "ソ", "タ", "チ", "ツ", "テ", "ト",
        "ナ", "ニ", "ヌ", "ネ", "ノ", "ハ", "ヒ", "フ", "ヘ", "ホ",
        "マ", "ミ", "ム", "メ", "モ", "ヤ", "ユ", "ヨ",
        "ラ", "リ", "ル", "レ", "ロ", "ワ", "ヰ", "ヲ", "ヱ", "ン" 
    ],
    KATAKANA_HALL_WIDTH_ARRAY : [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
        "ｶﾞ", "ｷﾞ", "ｸﾞ", "ｹﾞ", "ｺﾞ", "ｻﾞ", "ｼﾞ", "ｽﾞ", "ｾﾞ", "ｿﾞ",
        "ﾀﾞ", "ﾁﾞ", "ﾂﾞ", "ﾃﾞ", "ﾄﾞ", "ﾊﾞ", "ﾋﾞ", "ﾌﾞ", "ﾍﾞ", "ﾎﾞ", "ｳﾞ",
        "ﾊﾟ", "ﾋﾟ", "ﾌﾟ", "ﾍﾟ", "ﾎﾟ",
        "ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ", "ｰ", "ヮ",
        "ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ｶ", "ｷ", "ｸ", "ｹ", "ｺ",
        "ｻ", "ｼ", "ｽ", "ｾ", "ｿ", "ﾀ", "ﾁ", "ﾂ", "ﾃ", "ﾄ",
        "ﾅ", "ﾆ", "ﾇ", "ﾈ", "ﾉ", "ﾊ", "ﾋ", "ﾌ", "ﾍ", "ﾎ",
        "ﾏ", "ﾐ", "ﾑ", "ﾒ", "ﾓ", "ﾔ", "ﾕ", "ﾖ",
        "ﾗ", "ﾘ", "ﾙ", "ﾚ", "ﾛ", "ﾜ", "ヰ", "ｦ", "ヱ", "ﾝ"
    ],
    KATAKANA_FULL_WIDTH_ARRAY_FOR_PAYEE_INFO : [ 
        "０", "１", "２", "３", "４", "５", "６", "７", "８", "９",
        "ガ", "ギ", "グ", "ゲ", "ゴ", "ザ", "ジ", "ズ", "ゼ", "ゾ",
        "ダ", "ヂ", "ヅ", "デ", "ド", "バ", "ビ", "ブ", "ベ", "ボ", "ヴ", 
        "パ", "ピ", "プ", "ペ", "ポ", 
        "ァ", "ィ", "ゥ", "ェ", "ォ", "ャ", "ュ", "ョ", "ッ", "ー", "ヮ", 
        "ア", "イ", "ウ", "エ", "オ", "カ", "キ", "ク", "ケ", "コ", 
        "サ", "シ", "ス", "セ", "ソ", "タ", "チ", "ツ", "テ", "ト",
        "ナ", "ニ", "ヌ", "ネ", "ノ", "ハ", "ヒ", "フ", "ヘ", "ホ",
        "マ", "ミ", "ム", "メ", "モ", "ヤ", "ユ", "ヨ",
        "ラ", "リ", "ル", "レ", "ロ", "ワ", "ヰ", "ヲ", "ヱ", "ン" ,"（","）"
    ],
    KATAKANA_HALL_WIDTH_ARRAY_FOR_PAYEE_INFO : [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
        "ｶﾞ", "ｷﾞ", "ｸﾞ", "ｹﾞ", "ｺﾞ", "ｻﾞ", "ｼﾞ", "ｽﾞ", "ｾﾞ", "ｿﾞ",
        "ﾀﾞ", "ﾁﾞ", "ﾂﾞ", "ﾃﾞ", "ﾄﾞ", "ﾊﾞ", "ﾋﾞ", "ﾌﾞ", "ﾍﾞ", "ﾎﾞ", "ｳﾞ",
        "ﾊﾟ", "ﾋﾟ", "ﾌﾟ", "ﾍﾟ", "ﾎﾟ",
        "ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ", "ｰ", "ヮ",
        "ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ｶ", "ｷ", "ｸ", "ｹ", "ｺ",
        "ｻ", "ｼ", "ｽ", "ｾ", "ｿ", "ﾀ", "ﾁ", "ﾂ", "ﾃ", "ﾄ",
        "ﾅ", "ﾆ", "ﾇ", "ﾈ", "ﾉ", "ﾊ", "ﾋ", "ﾌ", "ﾍ", "ﾎ",
        "ﾏ", "ﾐ", "ﾑ", "ﾒ", "ﾓ", "ﾔ", "ﾕ", "ﾖ",
        "ﾗ", "ﾘ", "ﾙ", "ﾚ", "ﾛ", "ﾜ", "ヰ", "ｦ", "ヱ", "ﾝ","(",")"
    ]
};

export const LIST_LIMIT = {
    PAGINATION_LIST_LIMIT: 15,
    EXHIBITION_USER_LIST_LIMIT: 15,
    MANAGE_INVENTORY_LIST_LIMIT: 15,
    INVENTORY_REPORTS_LIST_LIMIT: 15,
    BULK_PRODUCT_UPLOAD_REPORTS: 15,
    SELLLER_PAYMENT_LIST_LIMIT: 15,
    OMS_MANAGE_LIST_LIMIT: 15,
    ACTIVITY_LOG_LIST_LIMIT: 15,
    OMS_SHIPPED_LIST_LIMIT: 15,
    OMS_MULTIPLE_ORDER_LIST_LIMIT: 15,
    BULK_PRODUCT_LIST: 15,
    SALES_HISTORY_LIST_LIMIT: 15,
    LIMIT_15: 15,
    LIMIT_25: 25,
    LIMIT_50: 50,
    LIMIT_100: 100,
};

export const OMS_MANAGE_LIST_CONSTANTS = {
    STATUS_PENDING: 0,
    STATUS_EXPIRED: 3,
    STATUS_ACCEPTED: 1,
};

export const BULK_PRODUCT_UPLOAD_REPORT_LIST_CONSTANTS = {
    STATUS_PENDING: 0,
    STATUS_EXPIRED: 3,
    STATUS_ACCEPTED: 1,
};

export const INVENTORY_MODULE_CONSTANTS = {
    STATUS_NO: 0,
    STATUS_YES: 1,
};

export const OMS_CONSTANTS = {
    STATUS_BACKORDER: 3,
    STATUS_PREPARE_TO_SHIP: 5,
    STATUS_SHIPPED: 6,
    STATUS_CANCELLED: 9,
};

export const PARAMS_CONSTANTS = {
    STATUS_ONE: 1,
    STATUS_TWO: 2,
    STATUS_THREE: 3,
};

export const SHEET_NAME = {
    BULK_PRODUCT_UPLOAD_REPORTS: "商品一覧_エラー内容",
    BULK_IMAGE_UPLOAD_REPORTS: "エラー内容",
    BULK_PRODUCT_UPLOAD_PRODUCT_LIST: "商品一覧",
    MANAGE_INVENTORY: "在庫一覧",
    MANAGE_INVENTORY_REPORTS: "在庫一覧_エラー内容",
    MANAGE_INVENTORY_TEMPLATE: "Sheet1",
    SALES_HISTORY: "売上入金履歴",
    SALES_LIST: "売上一覧",
    ACTIVITY_LOG: "Activity Log",
    OMS_DOWNLOAD:"注文一覧"
};

export const REFUND_REASON_ID = {
    UNABLE_TO_SHIP: "1",
    WRONG_PRODUCT_SHIPPED: "2",
    DELIVERY_DELAY: "3",
    OTHER_REASON_SELLER: "4",
    REFUSAL_TO_RECEIVE: "5",
    WRONG_ORDERED_PRODUCT: "6",
    OTHER_REASON_CUSTOMER: "7"
};

export const CONSUMPTION_TAX_CATEGORY = {
    TAX_CATEGORY_00: "00",
    TAX_CATEGORY_01: "01",
    TAX_CATEGORY_02: "02",
};

export const DATE_CONFIG = {
    GET_DATE_20: 20
};

export const ROLE_MANAGEMENT_SYSTEM = {
    PERMISSION_ALLOWED : 1,
    PERMISSION_NOTALLOWED : 0,  
};

export const IMAGE_UPLOAD = {
    IMGX_TYPE: 0,
    FILE_UPLOADED_TYPE:1,
    S3_TYPE:2,
    BULK_IAMGE_UPLOAD_TYPE:3
};

export const PRODUCT_LIST = {
    PRODUCT_LIST_DOWNLOAD_LIMIT_10000 :10000,
    PRODUCT_LIST_DOWNLOAD_LIMIT_50000 :50000,
    PRODUCT_LIST_DOWNLOAD_LIMIT_300000 :300000,    
};

export const SINGLE_PRODUCT_DETAILS = {
    // REQUIRED : "This field is required",
    // REQUIRED_JP : "JAPANI Required",
    CONSUMPTION_TAX_CATEGORY : [
        "Standard tax rate",
        "Reduced tax rate",
        "Tax exempt"
    ],
    CONSUMPTION_TAX_CATEGORY_JP : [
        "標準税率",
        "軽減税率",
        "非課税"
    ],
    LIQUOR_CATAGORY : [
        "Alcoholic beverages",
        "Other than alcoholic beverages"
    ],
    LIQUOR_CATAGORY_JP : [
        "酒類",
        "酒類以外"
    ],
    SALE_FLAG : [
        "Release",
        "Private"
    ],
    SALE_FLAG_JP : [
        "公開",
        "非公開"
    ],
    SPECIAL_CATEGORY : [
        "Body size - width",
        "Body size-depth",
        "Body size-height",
        "Body weight",
        "Material/raw materials",
        "Features",
        "How to care"
    ],
    SPECIAL_CATEGORY_JP : [
        "本体サイズ-幅",
        "本体サイズ-奥行",
        "本体サイズ-高さ",
        "本体重量",
        "材質・原材料",
        "特徴",
        "お手入れ方法"
    ],
    FORBIDDEN_CHARACTERS : /["'¥|\\㈱仿伃伋㒵冝刕剗咡咩嗎噶嚬垜﨏埇埵孁孖㞍岪峐嵓嵭廹弶徯扚挊揥朳杻极枛柼桵棈椊椵楉榀槏槶㯃槢槵橅橳檔檞檥櫤氐潡澍焏犾珏琯癭硑硨秈穝箞粠糙縨苕苽茭荃荕荢葈﨟薭褜諶跎鍰鎺雞雩霳霻靎靏䦰馞騂䰗魦魲魵魹鮊鯎鰀鰣鱏鱣鵃鵰鷀鸕龗丒儛劘坳垚垬埞堹墋㚑岈㟽挼㫪曬杔杶枒柈桉㭷梚梪椻榒榺㯍樚樿櫐橾汭洧湗溿瀊灶焇㹨珺瓺畈畬畾瘘祘䅣穪䆴笧䈇籮籰纚芎芓茰萗葚蒦䖸蠃貒觱迁鉙銫鋙鋷鎈閍靁靇頥馿䰠魣鯮鰘鰢鰩鵢䳄鷠鸙①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳]/,
};

export default CONSTANTS;
