import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../constants/constants";
import AuthService from "../services/AuthService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import { userActions } from "../../../redux/features/user/userSlice";
import ProfileService from "../../Profile/services/ProfileService";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import UnderMaintenanceService from "../../UnderMaintenance/services/UnderMaintenanceService";

const Login = (props: any) => {


    // if (window.location.pathname === ROUTES.INITIAL_PAGE) {
    //     window.history.pushState(null, window.location.href);
    //     window.onpopstate = function (e: any) {
    //         if (window.location.pathname === ROUTES.INITIAL_PAGE) {
    //             e.stopPropagation();
    //             window.history.go(1);
    //         }
    //     };
    // }

    useEffect(()=>{
        GetMaintenancePage();
    },[]);

    const reloadPage = useAppSelector((state) => state.common.isReloadPage) ?? false;
    const { login, showSnackbar, handleLoader, setIsMsgRequired, setMsgVisibility, isMsgRequired} = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    if (window.location.pathname === ROUTES.INITIAL_PAGE && reloadPage) {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        dispatch(commonActions.IS_RELOAD(false));
    }
    const GetMaintenancePage = () => {
        const isHitDummyURL = false;
        new UnderMaintenanceService({}, isHitDummyURL)
            .UnderMaintenanceGetService()
            .then((res: any)=>{
                dispatch(commonActions.IS_MAINTENANACE(res?.maintenanceMode));
                localStorage.setItem("systemUpdateNotificationVisibility",res?.systemUpdateNotificationVisibility ? String(res?.systemUpdateNotificationVisibility) : "0");
                localStorage.setItem("systemUpdateNotification", res.systemUpdateNotification ? res.systemUpdateNotification :"");
                (res?.maintenanceMode=="true")? window.location.href = ROUTES.UNDER_MAINTENANCE :  null;
                setIsMsgRequired(res.systemUpdateNotification);
                setMsgVisibility(res?.systemUpdateNotificationVisibility);
            })
            .catch((err)=>{
                showSnackbar(err?.message, false);
            });
    };


    const [passwordType, setPasswordType] = useState("password");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const  alertMessage = "";
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("login.please_enter_valid_email"),
                regex: t("login.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    
    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);

    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "email" ? setEmail(value) : setPassword(value);
        validator.current.hideMessageFor(name);

    };

    const getUserDetails = async () => {
        const isHitDummyURL = false;
        await new ProfileService({}, isHitDummyURL)
            .sellerInfo()
            .then((res) => {
                dispatch(userActions.USER_DETAILS(res));
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const handleSubmit = async () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email, password };
            const isHitDummyURL = false;
            await new AuthService(payload, isHitDummyURL)
                .login().then((res) => {
                    handleLoader(false);
                    const { name, email, userType, accessToken, isStoreCreated, storeId, storeName, storeCode, perPageLimit } = res;
                    if (isStoreCreated === false && accessToken && userType != CONSTANTS.USER_TYPE_EXHIBITOR) {
                        login(accessToken, isStoreCreated, userType, email, name, perPageLimit);
                        navigate(ROUTES.REGISTER_DETAILS);
                    } else {
                        setOpenSnackBar(true);
                        login(accessToken, true, userType, email, name, storeId, storeName, storeCode, perPageLimit);
                        if(userType!=CONSTANTS.USER_TYPE_EXHIBITOR){
                            getUserDetails();
                        }
                        navigate(ROUTES.OMS);
                    }
                    setSuccessAlert(true);
                }).catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            handleSubmit();
        }
    };

    // const handleClickShowPassword = () => {
    //     setPasswordType("password");
    // };

    const handleMouseDownPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            <Container sx={ isMsgRequired ? { mt: { xl: 8, lg: 8, md: 8, sm: 8, xs: 14 } } : {}}>
                <Grid container>
                    <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    {/* main grid column! */}
                    <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                        <img className="logo-img" alt="cainz"src={imagePath("logo-large.png")}  />
                        <Grid sx={{ mb: "1rem" }}>
                            <TextField
                                fullWidth
                                type="email"
                                name="email"
                                label={t("login.email_address")}
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => handleKeyPress(e)}
                                onBlur={(e)=>handleOnBlur(e)}
                                value={email}
                            />
                            {validator.current.message("email", email, [
                                "required",
                                "email",
                            ])}
                        </Grid>

                        <Grid sx={{ mb: "1rem" }}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                // onTouchStart={handleMouseDownPassword}
                                                // onTouchEnd={handleClickShowPassword}
                                                onClick={handleMouseDownPassword}
                                                // onMouseDown={handleMouseDownPassword}
                                            >
                                                { passwordType=="password" ? 
                                                    <VisibilityIcon /> : 
                                                    <VisibilityOffIcon />
                                                }
                                                        
                                                        
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                name="password"
                                type={passwordType}
                                label={t("login.password")}
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => handleKeyPress(e)}
                                onBlur={(e)=>handleOnBlur(e)}
                                value={password}
                            />

                            {validator.current.message("password", password, [
                                "required",
                                { regex: CONSTANTS.PASSWORD_REGEX },
                            ])}
                        </Grid>
               
                        <Typography sx={{my:2}}
                            className="forgot-text"
                            onClick={() => navigate(ROUTES.SEND_OTP)}
                        >
                            {t("login.forgot_password")}
                        </Typography>
                   

                        <Button fullWidth onClick={handleSubmit}>
                            {t("login.submit")}
                        </Button>
                            
                    </Grid>
                </Grid>
                {/* offset grid with column*/}

                <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                   
                <SnackbarAlert
                    openSnackBar={openSnackBar}
                    message={alertMessage}
                    success={successAlert}
                    handleCloseSnackBar={handleCloseSnackBar}
                />
            </Container>
            
        </>
    );
};

export default Login;
