import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class UnshippedOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async unshippedOrder() {
        let unshippedOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (unshippedOrderService = await new AxiosUtils(
                `${mockAPIPath.UNSHIPPED_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (unshippedOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.UNSHIPPED_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return unshippedOrderService;
    }
}

export default UnshippedOrderDetail;

