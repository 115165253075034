export const mockAPIPath = {
    // OMS_MANAGE_ORDER : "/data/oms_dev.json",
    OMS_MANAGE_ORDER: process.env.REACT_APP_OMS_DATA,
    OMS_REFUND_ORDER: "",
    OMS_SALES_DATA_SAVE: "./data/omsConfirmShipment.json",
    CONFIRM_SHIPMENT: "./data/confirmShip.json",
    SHIPMENT_DATA: "./data/shipmentData.json",
    OMS_SHIPPED: "./data/omsShippedData.json",
    OMS_REFUND: "./data/omsRefundData.json",
    REFUND_ORDER: "./data/refundOrderData.json",
    OMS_CANCEL_ORDER_REASON: "",
    OMS_REFUND_ORDER_REASON: "",
    OMS_CANCEL_ORDER: "",
    OMS_PREPARE_FOR_SHIPMENT: "",
    EDIT_SHIPMENT_DATA: "",
    SAVE_CONFIRM_SHIPMENT: "",
    ADMIN_NOTE: "",
    SELLER_MEMO:"",
    DOWNLOAD_TEMPLATE: "",
    PACKAGING_SLIP:"./data/packagingSlip.json",
    PACKING_SLIP:"/packing-slip"  
};

export const serverAPIPath = {
    OMS_MANAGE_ORDER: "/oms/orders",
    OMS_REFUND_ORDER: "/oms/orders/refund/list",
    OMS_SALES_DATA_SAVE: "/oms/sales",
    CONFIRM_SHIPMENT: "/oms/orders/",
    SHIPMENT_DATA: "/oms/orders/shipment/confirm",
    OMS_SHIPPED: "",
    OMS_REFUND: "/oms/orders/refund/list",
    OMS_REFUND_ORDER_REASON: "/master/order-refund-reason",
    REFUND_ORDER: "/oms/orders/refund/confirm",
    OMS_CANCEL_ORDER_REASON: "/master/order-cancel-reason",
    OMS_CANCEL_ORDER: "/oms/orders/shipment/cancel",
    OMS_PREPARE_FOR_SHIPMENT: "/oms/orders/shipment/prepare",
    EDIT_SHIPMENT_DATA: "/oms/orders/shipment/edit",
    SAVE_CONFIRM_SHIPMENT: "",
    ADMIN_NOTE: "/oms/orders/notes",
    SELLER_MEMO:"/oms/orders/memos",
    DOWNLOAD_TEMPLATE: "/oms/orders",
    PACKAGING_SLIP:"/oms/orders",
    PACKING_SLIP:"/oms/orders/packing-slip"  
};