export const ROUTES = {
    REGISTER: "/register",
    REGISTER_DETAILS: "/register-details",
    VERIFY_INVITATION: "/verify-invitation",
    FORGOT_PASSWORD: "/forgot-password",
    SEND_OTP: "/send-otp",
    VERIFY_OTP: "/verify-otp",
    CREATE_PASSWORD: "/create-password",
    INITIAL_PAGE: "/",
    PAGE_NOT_EXIST: "*",
    UPDATE_PASSWORD: "/update-password",
    VERIFY_PASSWORD: "/verify-password",
    PRODUCT_LIST: "/product-list",
    IMAGE_UPLOAD: "/image-upload",
    PROFILE: "/profile",
    EDIT_PROFILE: "/edit-profile",
    PRIVACY_POLICY: "/privacy-policy",
    SHIPPING_POLICY: "/shipping-policy",
    CUSTOM_POLICY: "/custom-policy",
    ABOUT_US: "/about-us",
    FAQS: "/faqs",
    INVITE_PAGE: "/invite-page",
    BULK_PRODUCT_UPLOAD: "/bulk-product-upload",
    BULK_IMAGE_UPLOAD: "/bulk-image-upload",
    EXHIBITION_USER_LIST: "/exhibition-user-list",
    EXHIBITION_USER_DETAIL: "/exhibition-user-details",
    MANAGE_INVENTORY: "/inventory-manage",
    MANAGE_ORDER: "/manage-order",
    ORDER_DECILNE: "/decline",
    UNAUTHORIZED_VISIT: "/403",
    STORE_DETAILS: "/store-details",
    BILLING_INFO: "/billing-information",
    PAYMENT_INFO: "/payment-information",
    PAYEE_INFO: "/payee-information",
    EMAIL_UPDATE: "/email-update",
    EMAIL_OTP: "/email-otp",
    ACCOUNTS_JOURNALS: "/accounts-journals",
    OMS: "/oms",
    OMS_CONFIRM_SHIPMENT: "/oms-confirm-shipment",
    SHIPPING_MULTIPLE_ORDER:"/shipping-multiple-order",
    PRINT_PACKING_SLIP:"/print-packing-slip",
    OMS_CONFIRM_SHIPMENT_PRINT_PACKING_SLIP:"/oms-confirm-shipment-slip",
    SELLER_PAYMENTS: "/seller-payments",
    ADMIN_AUTH: "/admin-authentication",
    ACTIVITY_LOG: "/activities",
    REFUND_ORDER: "/refund-order",
    PAYMENT_REPORTS: "/sales-list",
    SALES_HISTORY:"/sales-history",
    UNDER_MAINTENANCE:"/under-maintenance",
    SINGLE_PRODUCT_DETAILS: "/product-details",
    PRODUCT_DOWNLOAD:"/product-download",
    ERROR_DOWNLOAD_PRODUCT:"/error/product-download",
    REFRESH_TOKEN: "/seller/refresh/token",
    PACKING_SLIP:"/packing-slip"  
};