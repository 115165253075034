import { Snackbar, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

export const SnackbarAlert = (props: any) => {

    const { openSnackBar, message, success, handleCloseSnackBar } = props;

    const [show, setShow] = useState(openSnackBar);

    useEffect(() => {
        setShow(openSnackBar);
    },[openSnackBar]);

    const handleClose = () => {
        setShow(false);
        handleCloseSnackBar();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={5000}
            open={show}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={success ? "success" : "error"}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};