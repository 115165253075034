import axios from "axios";
import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { bulkProductTypeypeWithPaginationType } from "../../../interfaces/bulkProductListInterface";
import { SingleImageList } from "../../../interfaces/singleImageListInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

export class ImageUploadService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headersForFileUpload: object;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headersForFileUpload = { "Content-Type": "multipart/form-data","access-token ": new Cookies("access-token").read(),"accept": "application/zip" };
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async imageUpload() {
       
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.IMAGE_UPLOAD, this.payload,this.headersForFileUpload).get() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.IMAGE_UPLOAD}`, this.payload,this.headersForFileUpload).post();
        let fileupload: string; 
        if(response.data.success) {
            fileupload = response.data.message;
            return fileupload;
        }
        else {
            throw new Error(response.data.message);
        }

    }

    async imageList(
        page_id: number,
        search?: string,
        limit = LIST_LIMIT.BULK_PRODUCT_LIST,
        colName?:string,
        order?:string
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.IMAGE_LIST}`,
                this.payload,
                this.headers
            ).getNew()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.IMAGE_LIST}?page=${page_id}&limit=${limit}&search=${search}&column=${colName}&order=${order}&isProductCode=true`,
                this.payload,
                this.headers
            ).getNew();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =
        response.data.data;
            const bulkProductData: bulkProductTypeypeWithPaginationType = {
                bulkProductLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return bulkProductData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async singleImageList(
        productID:any,
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SINGLE_IMAGE_LIST}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SINGLE_IMAGE_LIST}${productID}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const singleImageListData :SingleImageList = response.data.data;          
            return singleImageListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async uploadImage() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.UPLOAD_IMAGE}`,
                this.payload,
                this.headersForFileUpload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.UPLOAD_IMAGE}`,
                this.payload,
                this.headersForFileUpload
            ).post();
        if (response?.data?.success) {
            const imageuploaded = response.data.data;          
            return imageuploaded;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async imageCollectionSubmit() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.IMAGE_SUBMIT}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.IMAGE_SUBMIT}`,
                this.payload,
                this.headers
            ).post();
        if (response?.data?.success) {
            const imageuploaded = response?.data?.message;          
            return imageuploaded;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async checkImageStatus(imageUrl:any) {
        const response = await axios({
            method: "GET",
            url: imageUrl,
            headers: { Accept: "*/*" }
        }).catch((error: any) => {          
            return error?.response;
        });

        return response;      
    }
    
    async UploadedImageStatus(productID:any) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.IMAGE_UPLOAD, this.payload,this.headers).get() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.IMAGE_UPLOAD}?productId=${productID}`,this.payload,this.headers).get();
        let fileupload: string;
        if(response.data.success) {
            fileupload = response.data;
            return fileupload;
        }
        else {
            throw new Error(response.data.message);
        }
    }

    async createFileObjectFromUrl(url:any) {
        const response = await new AxiosUtils(url).arrayBufferRequest();  
        if(response.status==200){
            return response;
        }
        else{
            console.log("Wrong Url Sent");
        }  
    }
}

