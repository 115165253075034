import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import PermissionType, { permissionInitialValue } from "../../../interfaces/permissionInterface";

const initialState: PermissionType = permissionInitialValue;

const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        SET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.permissionDetails = action.payload;
        },
        RESET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.permissionDetails = action.payload;
        }
    }
});

export default permissionSlice.reducer;
export const permissionActions = permissionSlice.actions;