import React, { useState, useRef, useEffect } from "react";

import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import PasswordService from "../services/PasswordService";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
const CreatePassword = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    window.onpopstate = function (e: any) {
        if (window.location.href.includes(ROUTES.VERIFY_OTP)) {
            const responseMessage = t("forgotpassword.are_you_sure_want_to_exit");
            const response = confirm(responseMessage);
            if (!response) {
                e.stopPropagation();
                window.history.forward();
            } else {
                navigate(ROUTES.INITIAL_PAGE);
            }
        }
    };
    /* Language translate function*/
    const [t] = useTranslation();

    /* Language translate function*/
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    //     null
    // );
    const location = useLocation();
    const navigate = useNavigate();
    const email = location?.state?.email ?? "";
    const  alertMessage = "";
    const successAlert = false;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);

    // validation
    const [, forceUpdate] = useState(0);
    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;
    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("forgotpassword.password_does_not_match"),
                email: t("forgotpassword.please_enter_valid_password"),
                regex: t("forgotpassword.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };


    useEffect(() => {
        !email ? navigate(ROUTES.INITIAL_PAGE) : null;
    });
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "password" ? setPassword(value) : setConfirmPassword(value);
        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = {
                newPassword: password,
                confirmPassword,
                email: email,
            };
            new PasswordService(payload)
                .reset()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.INITIAL_PAGE);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };



    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleClickShowPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    // const handleMouseDownPassword = () => {
    //     setPasswordType("text");
    // };

    const handleClickShowConfirmPassword = () => {
        confirmPasswordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password");
    };

    // const handleMouseDownConfirmPassword = () => {
    //     setConfirmPasswordType("text");
    // };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    // const open = Boolean(anchorEl);

    return (
        <>
            {/* Login page starts here */}
            <Grid>
                <Container>
                    <Grid container spacing={4}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img className="logo-img" alt="cainz"src={imagePath("logo-large.png")}  />
                            <Box className="logo-content">
                                <Grid item xs={12} md={12}>
                                    <Grid sx={{ mb: 2 }}>
                                        <TextField
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        // onTouchStart={handleMouseDownConfirmPassword}
                                                        // onTouchEnd={handleClickShowConfirmPassword}
                                                            onClick={handleClickShowPassword}
                                                        // onMouseDown={handleMouseDownConfirmPassword}
                                                        >
                                                            { passwordType=="password" ?
                                                                <VisibilityIcon /> :
                                                                <VisibilityOffIcon />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                           
                                            type={passwordType}
                                            fullWidth
                                            name="password"
                                            value={password}
                                            label={t("forgotpassword.new_password")}
                                            variant="outlined"
                                            onChange={(e) => handleChange(e)}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("password", password, [
                                            "required",
                                            CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                            { regex: CONSTANTS.PASSWORD_REGEX },
                                        ])}
                                    </Grid>

                                    <Grid sx={{ mb:1}}>
                                        <TextField
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        // onTouchStart={handleMouseDownConfirmPassword}
                                                        // onTouchEnd={handleClickShowConfirmPassword}
                                                            onClick={handleClickShowConfirmPassword}
                                                        // onMouseDown={handleMouseDownConfirmPassword}
                                                        >
                                                            { confirmPasswordType=="password" ?
                                                                <VisibilityIcon /> :
                                                                <VisibilityOffIcon />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type={confirmPasswordType}
                                            fullWidth
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            label={t("forgotpassword.confirm_password")}
                                            variant="outlined"
                                            onChange={(e) => handleChange(e)}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message(
                                            "confirmPassword",
                                            confirmPassword,
                                            [
                                                "required",
                                                CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                                { in: password },
                                                { regex: CONSTANTS.PASSWORD_REGEX },
                                            ]
                                        )}
                                    </Grid>

                                    <Typography sx={{width: "auto", color:"#201d1d94"}}>
                                        {t("updatepassword.password_suggestion")}
                                    </Typography>

                                    <Grid sx={{ my: 2 }}>
                                        <Button fullWidth onClick={handleSubmit}>
                                            {t("updatepassword.submit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                    <SnackbarAlert
                        openSnackBar={openSnackBar}
                        message={alertMessage}
                        success={successAlert}
                        handleCloseSnackBar={handleCloseSnackBar}
                    />
                </Container>
            </Grid>
            {/* Login page ends here */}
        </>
    );
};

export default CreatePassword;
