import React, { useState, useRef, useEffect } from "react";

import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    IconButton,
    InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import PasswordService from "../services/PasswordService";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { useAppSelector } from "../../../redux/hooks";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";

const UpdatePassword = (props: any) => {

    const { showSnackbar,handleLoader } = props;
    {
    /* Language translate function*/
    }
    const [t] = useTranslation();
    {
    /* Language translate function*/
    }

    const navigate = useNavigate();

    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [password, setPassword] = useState<string>();
    const [alertMessage, setAlertMessage] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    //     null
    // );

    const error = {
        passwordError: "",
        confirmPasswordError: "",
    };
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const successAlert = false;

    const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType);

    // validation
    const [, forceUpdate] = useState(0);

    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;
    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("updatepassword.password_does_not_match"),
                regex: t("updatepassword.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
 
    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "password" ? setPassword(value) : setConfirmPassword(value);
        validator.current.hideMessageFor(name);
    };
    

    const handleSubmit = () => {
        
        if(validator.current.allValid()) {
            handleLoader(true);
            const payload = { newPassword: password, confirmPassword };
            const isHitDummyURL = false;
            new PasswordService(payload, isHitDummyURL)
                .update()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    loggedInUserType == CONSTANTS.USER_TYPE_SELLER ? navigate(ROUTES.STORE_DETAILS) : navigate(ROUTES.OMS);
                })
                .catch((err) => {
                    handleLoader(false);
                    // const { success, message } = err.response.data;
                    showSnackbar(err?.message, false);
                });
        }else {
            validator.current.showMessages();
        }
    };


    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleClickShowPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    // const handleMouseDownPassword = () => {
    //     setPasswordType("text");
    // };

    const handleClickShowConfirmPassword = () => {
        confirmPasswordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password");
    };

    // const handleMouseDownConfirmPassword = () => {
    //     setConfirmPasswordType("text");
    // };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    // const open = Boolean(anchorEl);

    return (
        <>
            {/* Create password page starts here */}
            <Grid>
                <Container>
                    <Grid container spacing={4} sx={{ py:10 }}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}             
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <Box sx={{ textAlign: "center", my: "1rem" }}>
                                <Typography variant="h6">
                                    {t("updatepassword.update_password")}
                                </Typography>
                            </Box>
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                // onTouchStart={handleMouseDownConfirmPassword}
                                                // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleClickShowPassword}
                                                // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { passwordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={passwordType}
                                    fullWidth
                                    name="password"
                                    value={password ?? ""}
                                    label={t("updatepassword.new_password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={error.passwordError ? true : false}
                                    helperText={error.passwordError}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            <Grid sx={{ mb:1}}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                // onTouchStart={handleMouseDownConfirmPassword}
                                                // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleClickShowConfirmPassword}
                                                // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { confirmPasswordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={confirmPasswordType}
                                    fullWidth
                                    name="confirmPassword"
                                    value={confirmPassword ?? ""}
                                    label={t("updatepassword.confirm_password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={error.confirmPasswordError ? true : false}
                                    helperText={error.confirmPasswordError}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("confirmPassword", confirmPassword, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    { regex: CONSTANTS.PASSWORD_REGEX },{in:password}
                                ])}
                            </Grid>

                            <Typography sx={{width: "auto", color:"#201d1d94"}}>
                                {t("updatepassword.password_suggestion")}
                            </Typography>

                            <Grid sx={{ my: 2 }}>
                                <Button fullWidth onClick={handleSubmit}>
                                    {t("updatepassword.submit")}
                                </Button>
                            </Grid>
                        </Grid>
                           
                        {/* offset grid with column*/}
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                    <SnackbarAlert
                        openSnackBar={openSnackBar}
                        message={alertMessage}
                        success={successAlert}
                        handleCloseSnackBar={handleCloseSnackBar}
                    />
                </Container>
            </Grid>
            {/* Create password page ends here */}
        </>
    );
};

export default UpdatePassword;
