interface PermissionType {
    permissionDetails : {
        userType?: number | null,
        modules?: [{
            key?: string,
            name?: string,
            routes?: {
                add?: boolean,
                edit?: boolean,
                view?: boolean,
                delete?: boolean
            }
        }]
    }
}

export const permissionInitialValue: PermissionType = {
    permissionDetails : {
        userType: null,
        modules: [{
            key: "",
            name: "",
            routes: {
                add: false,
                edit: false,
                view: false,
                delete: false
            }
        }]
    }
};

export default PermissionType;