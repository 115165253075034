import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Typography,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import  PayeeInformationServices  from "../services/PayeeInformation";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import { useAppSelector } from "../../../redux/hooks";
import { ACCOUNT_TYPE } from "../constants";
import CommonButton from "../../common/Button";
import Utility from "../../../utils/Utility";

const PayeeInformation = (props: any) => {

    const navigate = useNavigate();
    
    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const isKatakana = (value: string) => { return /^[\u30A0-\u30FF\uFF65-\uFF9F]*$/.test(value);};
    const isKatakanaForPayeeInfo = (value: string) => { return /^[\u30A0-\u30FF\uFF65-\uFF9F（）()]*$/.test(value);};

    const payeeInformation = useAppSelector(state => state.user.userDetails?.store?.[0]?.payeeInfo?.[0]);

    const { handleLoader, showSnackbar } = props;
    const { t } = useTranslation();
    const [bankCode, setBankCode] = useState(payeeInformation?.bankCode?.toString());
    const [financialInstitutionName, setFinancialInstitutionName] = useState(payeeInformation?.financialInstitutionName);
    const [branchCode, setBranchCode] = useState(payeeInformation?.branchCode?.toString());
    const [branchName, setBranchName] = useState(payeeInformation?.branchName);
    const [accountType, setAccountType] = useState(payeeInformation?.accountType);
    const [accountNumber, setAccountNumber] = useState(payeeInformation?.accountNumber?.toString());
    const [payeename, setPayeename] = useState<any>(payeeInformation?.payeeName);
    // const [fullWidthL, setFullWidthL] = useState<number>(0);
    const utility = new Utility;

    const accountTypeList = [
        {
            id: 1,
            name: t("store_details.account_type_normal"),
            value: ACCOUNT_TYPE.NORMAL
        },
        {
            id: 2,
            name: t("store_details.account_type_check"),
            value: ACCOUNT_TYPE.CHECK
        },
        {
            id: 3,
            name: t("store_details.account_type_savings"),
            value: ACCOUNT_TYPE.SAVINGS

        }
    ];

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                maxChar200Validation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar30Validation: {
                    message: t("registerpage.max_char",{size:30}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                bankCodeValidation: {
                    message: t("registerpage.min_char",{size:4}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteBankCodeValidation: {
                    message: t("registerpage.min_char",{size:4}),
                    rule: (value) => isSingleByte(value),
                    
                },
                mustbe15Char:{
                    
                    message: t("registerpage.max_char",{size:15}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },

                SingleByte: {
                    message: t("registerpage.single_byte_characters"),
                    rule: (value) => isSingleByte(value),
                    
                },
                Katakana: {
                    message: t("registerpage.katakana_half_width"),
                    rule: (value) => isKatakana(value),
                    
                },
                KatakanaPayeeInfo: {
                    message: t("registerpage.katakana_half_width"),
                    rule: (value) => isKatakanaForPayeeInfo(value),
                    
                },

                branchCodeValidation: {
                    message: t("registerpage.min_char",{size:3}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteBranchCodeValidation: {
                    message: t("registerpage.min_char",{size:3}),
                    rule: (value) => isSingleByte(value),
                    
                },
                accountNumberValidation: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteAccountNumberValidation: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value) => isSingleByte(value),
                    
                }
            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation
    function replaceStringByArrayOfIndex(str:any, src:string[], dest:string[]) {
        const len = src.length;
        for (let i = 0; i < len; i++) {
            str = str.replaceAll(src[i], dest[i]);
        }
        return str;
    }

    const UpdatePayeeInformation = () =>{
        if(validator.current.allValid()) {
            handleLoader(true);
            const newFinancialInstitutionName=()=>{
                return replaceStringByArrayOfIndex(financialInstitutionName, utility.getKatakanaFullWidth() , utility.getKatakanaHalfWidth());
            };
            const newBranchName=()=>{
                return replaceStringByArrayOfIndex(branchName, utility.getKatakanaFullWidth(), utility.getKatakanaHalfWidth());
            };
            const newPayeeName=()=>{
                return replaceStringByArrayOfIndex(payeename, utility.getKatakanaFullWidthForPayeeInfo(), utility.getKatakanaHalfWidthForPayeeInfo());
            };
        
            const payload = {
                bankCode,
                financialInstitutionName:newFinancialInstitutionName(),
                branchCode,
                branchName:newBranchName(),
                accountType,
                accountNumber,
                "payeeName":newPayeeName()
            };
            const isHitDummyURL = false;
            new PayeeInformationServices(payload, isHitDummyURL)
                .savePayeeInfo(payeeInformation?.id ?? null)
                .then((res: any) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.PROFILE);
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }else{
            validator.current.showMessages();
        }
        
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };
    // const convertKatakanaToHalfWidth = (e:any,setState:any)=>{
    //     const katakanaRegex = /^[\u30A0-\u30FF\uFF65-\uFF9F0-9 ０-９]*$/;
    //     if (katakanaRegex.test(e.target.value)) {
    //         setState(e.target.value);
    //     }
    // };

    // const handleFinancialInstitutionInputChange = (e: any) => {
    //     convertKatakanaToHalfWidth(e,setFinancialInstitutionName);
    // };

    // const handleBranchInputChange = (e: any) => {
    //     convertKatakanaToHalfWidth(e,setBranchName);
    // };
    // const handlePayeenameInputChange = (e: any) => {
    //     convertKatakanaToHalfWidth(e,setPayeename);  
    // };
        
    return (
        <>
            {/* Page header title */}
            <HeaderTitle title={t("store_details.payee_information")} />
            {/* Page header title */}

            <Container>
                {/*Payee Information starts here */}
                <Box className="ragistration_box">
                    <Typography variant="h6">
                        {t("store_details.payee_information")}
                    </Typography>
                    <Box className="bg-gray">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    value={bankCode || ""}
                                    name="bank_code"
                                    type="text"
                                    label={t("store_details.bank_code")}
                                    onChange={(e) => {
                                        setBankCode(e.target.value);
                                        // validator.current.hideMessageFor("bank_code");
                                    }}
                                    onInput={(e) => handleInputType(e)}
                                    inputProps={{ maxLength: 4 }}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("bank_code", bankCode, [
                                    "required",
                                    {SingleByteBankCodeValidation: 4},
                                    {bankCodeValidation: 4}   
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    value={financialInstitutionName || ""}
                                    name="financial_institution_name"
                                    label={t("store_details.financial_institution_name")}
                                    onChange={(e) => {
                                        setFinancialInstitutionName(e.target.value);
                                        // handleFinancialInstitutionInputChange(e);
                                        // validator.current.hideMessageFor("financial_institution_name");
                                    }}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("financial_institution_name", financialInstitutionName, ["required","Katakana", {mustbe15Char: 15,}])}
                               
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    value={branchCode || ""}
                                    name="branch_code"
                                    label={t("store_details.branch_code")}
                                    onChange={(e) => {
                                        setBranchCode(e.target.value);
                                        // validator.current.hideMessageFor("branch_code");
                                    }}
                                    onInput={(e) => handleInputType(e)}
                                    inputProps={{ maxLength: 3 }}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("branch_code", branchCode, [
                                    "required",
                                    {SingleByteBranchCodeValidation: 3},
                                    {branchCodeValidation: 3}   
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    value={branchName || ""}
                                    name="branch_name"
                                    label={t("store_details.branch_name")}
                                    onChange={(e) => {
                                        setBranchName(e.target.value);
                                        // handleBranchInputChange(e);
                                        // validator.current.hideMessageFor("branch_code");
                                    }}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("branch_name", branchName, ["required","Katakana", {mustbe15Char: 15}])}
                                
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    className="bg-white"
                                    name="account_type"
                                    value={accountType || ""}
                                    onChange={(e) => {
                                        setAccountType(e.target.value);
                                        validator.current.hideMessageFor("account_type");
                                    }}
                                    select // tell TextField to render select
                                    label={t("store_details.account_type")}
                                    onBlur={(e) => handleOnBlur(e)}
                                >{
                                        accountTypeList?.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                                        ))
                                    }
                                </TextField>
                                {validator.current.message("account_type", accountType, ["required"])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    name="account_number"
                                    value={accountNumber || ""}
                                    label={t("store_details.account_number")}
                                    onChange={(e) => {
                                        setAccountNumber(e.target.value);
                                        // validator.current.hideMessageFor("account_number");
                                    }}
                                    onInput={(e) => handleInputType(e)}
                                    inputProps={{ maxLength: 7 }}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("account_number", accountNumber, [
                                    "required",
                                    {SingleByteAccountNumberValidation: 7},
                                    {accountNumberValidation: 7}   
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    name="payee_name"
                                    value={payeename || ""}
                                    label={t("store_details.payee_names")}
                                    onChange={(e) => {
                                        setPayeename(e.target.value);
                                        // handlePayeenameInputChange(e);
                                        // validator.current.hideMessageFor("payee_name");
                                    }}

                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("payee_name", payeename, ["required","KatakanaPayeeInfo", {maxChar30Validation: 30}])}
                                
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <CommonButton route={window.location.pathname} functionToExecute={UpdatePayeeInformation} className="wd_100 padding_1rem"  btnName={t("store_details.save")} disableCondition={false}/>
                        {/* <Button fullWidth onClick={UpdatePayeeInformation}>{t("store_details.save")}</Button> */}
                    </Grid>
                </Grid>
                {/*Payee Information starts here */}
            </Container>
        </>
    );
};

export default PayeeInformation;
