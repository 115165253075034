import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class PendingOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async pendingOrder() {
        let pendingOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (pendingOrderService = await new AxiosUtils(
                `${mockAPIPath.PENDING_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (pendingOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PENDING_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return pendingOrderService;
    }
}

export default PendingOrderDetail;

