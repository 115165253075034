import React from "react";
import { useEffect , useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";



const InternetConnection = () => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [message, setMessage] = useState("");
    const { t } = useTranslation();


    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setMessage(t("internet_connection.you_are_online").toString());
            setOpenSnackbar(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
            setMessage(t("internet_connection.you_are_offline").toString());
            setOpenSnackbar(true);
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        // Clean up the event listeners
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    // const handleCloseSnackbar = () => {
    //     setOpenSnackbar(false);
    // };

    useEffect(() => {
        if (isOnline) {
            const timeout = setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000); // Adjust the delay time (in milliseconds) as needed

            return () => {
                clearTimeout(timeout);
            };
        }
        
    }, [isOnline]);

    return (
        <div className="internet_connection_snackbar">
            <Snackbar open={openSnackbar} autoHideDuration={null} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert severity={isOnline ? "success" : "error"}>
                    {message}
                </Alert>
            </Snackbar>
            {/* Render your other components here */}
        </div>
    );
    
};

export default InternetConnection;