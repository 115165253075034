class IdNameModel {
    private _id!: number;
    private _name!: string;

    public static parseJson(item: IdNameModel) {
        const data: IdNameModel = new IdNameModel();

        data.id = item.id;
        data.name = item.name;
        
        return data;
    }

    public get id() {
        return this._id;
    }
    public set id(id: number) {
        this._id = id;
    }
    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
}

export default IdNameModel;