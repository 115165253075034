export const mockAPIPath = {
    SINGLE_PRODUCT: "./data/singleproductdetails.json",
    SAVE_PRODUCT_DETAILS: "./data/singleproductdetails.json",
    GET_PRODUCT_DETAILS: "/products?download=true&fileType=csv"
};

export const serverAPIPath = {
    SHIPPING_FEE_FILTER_OPTIONS: "/master/individual-shipping-fee",
    EC_CATEGORY_OPTIONS: "/master/categories",
    SAVE_PRODUCT_DETAILS: "/products",
    GET_PRODUCT_DETAILS: "/products"
};