import { pdf } from "@react-pdf/renderer";
import { API } from "../config";
import CONSTANTS from "../constants/constants";

class Utility {

    constructor() {
        //
    }

    // add commas between numbers
    numberWithCommas(num: string) {
        num = num?.toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern?.test(num))
            num = num?.replace(pattern, "$1,$2");
        return num;
    }

    //  get current Date and Time stamp
    getCurrentTimestamp = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };


    getFormattedDateTime = (requiredDate: Date, configDate?: number) => {
        const year = requiredDate.getFullYear();
        const month = String(requiredDate.getMonth() + 1).padStart(2, "0");
        const date = configDate ? configDate : String(requiredDate.getDate()).padStart(2, "0");
        return `${year}/${month}/${date}`;
    };

    toSeconds = (hrs: number, min: number, sec: number) => (hrs * 60 * 60 + min * 60 + sec);

    // give sum of quantities
    getQuantitySum = (quantity: string) => {
        const addQuantity = quantity;
        const plusQuantity = addQuantity.split("|");
        let sum = 0;
        for (let i = 0; i < plusQuantity.length; i++) {
            sum += parseInt(plusQuantity[i]);
        }
        return sum;
    };

    // convert the value into YYYY MM with JP Format
    getCustomDateYMDJP = (date: string) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const JPDate = year + " 年 " + month + " 月分 ";

        return JPDate;
    };

    // convert the value into YYYY MM with JP Format
    customDateJPYYMMDD(date: any) {
        const newDate = date.split(" ");
        const updatedDate = newDate[0];
        const year = updatedDate.slice(0, 4);
        const month = updatedDate.slice(5, 7);
        const day = updatedDate.slice(8, 10);
        return year + "年" + month + "月" + day + "日";
    }

    // convert the value into YYYY MM with JP Format current date and time
    customPublicationDateJPYYMMDD() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return year + "年" + month + "月" + day + "日";
    }
    // download PDF with view on new Tab on a click
    downloadPdfWithView = async (html: any, fileName: string) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        const newTab = document.createElement("a");
        link.href = pdfUrl;
        newTab.href = pdfUrl;
        link.setAttribute("download", fileName);
        newTab.setAttribute("target", "_blank");
        document.body.appendChild(link);
        document.body.appendChild(newTab);
        link.click();
        newTab.click();
    };

    // download PDF with a click
    downloadPdfWithoutView = async (html: any, fileName: string) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    // print PDF
    printPdf = async (html: any, filename: string, print: boolean) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        const pdfWindow = window.open(pdfUrl, filename, "popup");
        pdfWindow?.document.close();
        pdfWindow?.focus();
        if(print) {
            setTimeout(() => {
                pdfWindow?.print();
            }, 2000);
        }
        return false;
    };


    // server sent event 
    connectionToSSE = async (setIsMsgRequired:any,setMsgVisibility:any,setVersion:any,setIsUnderMaintenance:any) => {
        let eventSource: EventSource | null  = null;
 
        function connectToSSE() {
            eventSource = new EventSource(API.SSE_API_PATH);
            eventSource.addEventListener("notification", (e) => {
                const systemUpdateData = JSON.parse(e.data);
                setIsMsgRequired(systemUpdateData?.message);
                localStorage.setItem("systemUpdateNotification", systemUpdateData?.message);
                setMsgVisibility(systemUpdateData?.orientation);
                localStorage.setItem("systemUpdateNotificationVisibility", systemUpdateData?.orientation);
            });
            eventSource.addEventListener("version", (e) => {
                setVersion(e.data);
            });
            eventSource.addEventListener("maintenance", (e) => {
                setIsUnderMaintenance(e.data);
            });
            eventSource.addEventListener("error", () => {
                reconnectToSSE();
            });
        }
 
        function reconnectToSSE() {
            setTimeout(connectToSSE, 3000);
        }
        connectToSSE();
 
        return () => {
            if(eventSource != null) {            
                eventSource.close();
            }
        };
 
    };
    // katakana full width character
    getKatakanaFullWidth =()=>{
        const KatakanaFullWidthArray = CONSTANTS.KATAKANA_FULL_WIDTH_ARRAY;
        return KatakanaFullWidthArray;
    };

    // katakana half width character
    getKatakanaHalfWidth=()=>{
        const KatakanaHalfWidthArray = CONSTANTS.KATAKANA_HALL_WIDTH_ARRAY;
        return KatakanaHalfWidthArray;
    };
    // katakana full width character for payee info 
    getKatakanaFullWidthForPayeeInfo =()=>{
        const KatakanaFullWidthArray = CONSTANTS.KATAKANA_FULL_WIDTH_ARRAY_FOR_PAYEE_INFO;
        return KatakanaFullWidthArray;
    };
    // katakana half width character for payee info
    getKatakanaHalfWidthForPayeeInfo=()=>{
        const KatakanaHalfWidthArray = CONSTANTS.KATAKANA_HALL_WIDTH_ARRAY_FOR_PAYEE_INFO;
        return KatakanaHalfWidthArray;
    };

}
export default Utility;