import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class CompletedOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async completedOrder() {
        let campletedOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (campletedOrderService = await new AxiosUtils(
                `${mockAPIPath.CANCELED_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (campletedOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CANCELED_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return campletedOrderService;
    }
}

export default CompletedOrderDetail;

