export const mockAPIPath = {
    PRODUCT_LIST: "./data/productList.json",
    PRODUCT_LIST_NEW: process.env.REACT_APP_OMS_DATA,
    SELLER_REPORTS: "./data/sellerReoprts.json",
    SELLER_INFO_REPORTS: "./data/sellerInfo.json",
    TEMPLATE_DOWNLOAD: "./data/templateDownload.json",
    FILE_UPLOAD: "./data/fileUpload.json",
    PRODUCT_LOG:"/data/productLog.json"
};

export const serverAPIPath = {
    PRODUCT_LIST: "/products",
    SELLER_REPORTS: "",
    SELLER_INFO_REPORTS: "/products/upload/report",
    TEMPLATE_DOWNLOAD: "/products/template",
    ERROR_DOWNLOAD: "/products/upload/errors/download/",
    FILE_UPLOAD: "/products/upload/file",
    LATEST_SELLER_INFO_REPORTS: "/products/upload/report/latest",
    SHIPPING_FEE_FILTER_OPTIONS:"/master/individual-shipping-fee"
};





