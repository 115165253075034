export const permissionCategory = {
    STORE: "store_information",
    LISTING_USER: "listing_users",
    PRODUCT: "product_information",
    INVENTORY: "inventory_information",
    SETTING: "setting_term_and_condition",
    ORDER: "order_management",
    ACTIVITY_LOG: "activity_log",
    COMMON: "common",
};

export const newPermissionCategory = {
    COMMON: "common",
    SUPER_ADMIN: "super_admin",
    MERCHANDISE_MANAGEMENT: "merchandise_management",
    INVENTORY_CONTROL_MANAGEMENT: "inventory_control_management",
    ORDER_MANAGEMENT: "order_management",
    SALES_RECEIPT_MANAGEMENT: "sales_receipt_management",
    USER_MANAGEMENT: "user_management",
    STORE_DETAIL_MANAGEMENT:"store_detail_management"
};