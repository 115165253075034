import { API } from "../../../config";
import UserDetailInterface from "../../../interfaces/userDetailInterface";
import SellerModel from "../../../models/Seller/SellerModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath} from "../config/apiPath";


class ProfileService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async sellerBussinessInfo() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SELLER_BUSSINESS_INFO}`, this.payload, this.headers).put();
        let isBusinessInfoSaved: string;
        if(response?.data?.success==true) {
            isBusinessInfoSaved = response?.data?.message;
            return isBusinessInfoSaved;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
    async sellerInfo() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SELLER_INFO}`, this.payload, this.headers).getNew();
        if(response?.data?.success == true)
        {
            const userDetails: UserDetailInterface = response?.data?.data;
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
    async editProfile() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EDIT_PROFILE}`, this.payload,this.headers).put();
        let isProfileEdited: string;
        if(response?.data?.success==true) {
            isProfileEdited = response?.data?.message;
            return isProfileEdited;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

export default ProfileService;

//Dummy API Store detail start

//Dummy API Store detail end



//Dummy API payment information start

//Dummy API payment information end





//Dummy API Billing information start

//Dummy API Billing information end




//Dummy API payee information start

//Dummy API payee information end