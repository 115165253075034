import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";

class CsvfileUpload {
    // xlsxfile() {
    //     throw new Error("Method not implemented.");
    // }
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;

        this.headers = { "Content-Type": "multipart/form-data", "Access-Control-Allow-Origin": "*","access-token": new Cookies("access-token").read() };
    }

    async csvfile() {

        // let fileupload;
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.INVENTORY_FILE_UPLOAD, this.payload, this.headers).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.INVENTORY_FILE_UPLOAD}`, this.payload, this.headers).post();
        // const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.INVENTORY_FILE_UPLOAD}`, this.payload,this.headers).post();
        let fileupload: string;
        if(response?.data?.success) {
            fileupload = response?.data?.message;
            return fileupload;
        }
        else {
            throw new Error(response);
        }

    }
}

export default CsvfileUpload;

