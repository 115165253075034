import PaginationModel from "../Pagination/PaginationModel";
import ProductModel from "./ProductModel";

class ProductListWithPaginationModel {
    [x: string]: any;
    private _products!: ProductModel[];
    private _pagination!: PaginationModel;
  
    public static parseJson(products: ProductModel[], paginationItem: PaginationModel) {
        const productListWithPagination: ProductListWithPaginationModel = new ProductListWithPaginationModel();
        
        productListWithPagination.products = products;
        productListWithPagination.pagination = paginationItem;
    
        return productListWithPagination;
    }

    public get products() {
        return this._products;
    }
    public set products(products: ProductModel[]) {
        this._products = products;
    }

    public get pagination() {
        return this._pagination;
    }
    public set pagination(pagination: PaginationModel) {
        this._pagination = pagination;
    }

   
}

export default ProductListWithPaginationModel;