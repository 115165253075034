import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";


class sellerMemoService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    
    async SellerMemoPost() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SELLER_MEMO}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_MEMO}`,
                this.payload,
                this.headers
            ).post();
        let responseMessage: string;
        if(response?.data?.success==true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

}
export default sellerMemoService;