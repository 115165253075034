class LatestSellerModel {
    private _uploadType!: string;
    private _productApproved!: string;
    private _errorCount!: string;
    private _totalCount!: string;
    private _inProgressStatusCode!: string;
    private _StatusText!: string;
    private _dateTime!: string;

    public static parseJson(itemLatestSellers: LatestSellerModel) {
        const latestSeller: LatestSellerModel = new LatestSellerModel();
        latestSeller.uploadType = itemLatestSellers.uploadType;
        latestSeller.productApproved = itemLatestSellers.productApproved;
        latestSeller.errorCount = itemLatestSellers.errorCount;
        latestSeller.totalCount = itemLatestSellers.totalCount;
        latestSeller.inProgressStatusCode = itemLatestSellers.inProgressStatusCode;
        latestSeller.StatusText = itemLatestSellers.StatusText;
        latestSeller.dateTime = itemLatestSellers.dateTime; 
        return latestSeller;
    }

    public get uploadType() {
        return this._uploadType;
    }
    public set uploadType(uploadType: string) {
        this._uploadType = uploadType;
    }
    public get productApproved() {
        return this._productApproved;
    }
    public set productApproved(productApproved: string) {
        this._productApproved = productApproved;
    }
    public get errorCount() {
        return this._errorCount;
    }
    public set errorCount(errorCount: string) {
        this._errorCount = errorCount;
    }
    public get totalCount() {
        return this._totalCount;
    }
    public set totalCount(totalCount: string) {
        this._totalCount = totalCount;
    }
    public get inProgressStatusCode() {
        return this._inProgressStatusCode;
    }
    public set inProgressStatusCode(inProgressStatusCode: string) {
        this._inProgressStatusCode = inProgressStatusCode;
    }
    public get StatusText() {
        return this._StatusText;
    }
    public set StatusText(StatusText: string) {
        this._StatusText = StatusText;
    }
    public get dateTime() {
        return this._dateTime;
    }
    public set dateTime(dateTime: string) {
        this._dateTime = dateTime;
    }
}

export default LatestSellerModel;