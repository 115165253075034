import PaginationModel from "../Pagination/PaginationModel";
import SellerReportsModel from "./SellerReportsModel";


class SellerReportsPaginationModel {
    private _sellerReports!: SellerReportsModel[];
    private _pagination!: PaginationModel;
  
    public static parseJson(sellerReports: SellerReportsModel[],paginationItem: PaginationModel) {
        const sellerReportsWithPagination: SellerReportsPaginationModel = new SellerReportsPaginationModel();
        
        sellerReportsWithPagination.sellerReports = sellerReports;
        sellerReportsWithPagination.pagination = paginationItem;
    
        return sellerReportsWithPagination;
    }

    public get sellerReports() {
        return this._sellerReports;
    }
    public set sellerReports(sellerReports: SellerReportsModel[]) {
        this._sellerReports = sellerReports;
    }

    public get pagination() {
        return this._pagination;
    }
    public set pagination(pagination: PaginationModel) {
        this._pagination = pagination;
    }

   
}

export default SellerReportsPaginationModel;