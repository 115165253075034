import { Skeleton } from "@mui/material";
import React from "react";

function index(props:any) {
    const {variant, height, count, my} = props;
    const countArray = Array.from({length: count}, (_, i) => i + 1);
    return (
        <>
            {
                countArray.map((index: number)=>(
                    <Skeleton key={index} variant={variant} height={height}  sx={{my:my}} />
                ))
            }
      
        </>
    );
}

export default index;
