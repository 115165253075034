class Cookies {

    private name: string;
    private data: string;
    
    constructor(name: string, data = "") {
        this.name = name;
        this.data = data;
    }
    save() {
        document.cookie = this.name + "=" + String(this.data) + "=; Path=/;";
        // const expirationDate = new Date();
        // expirationDate.setTime(expirationDate.getTime() + (24* 60 * 1000)); // 60 seconds * 1000 milliseconds = 1 minute
        // document.cookie = this.name + "=" + String(this.data) + "; expires=" + expirationDate.toUTCString() + "; path=/";
    }
    read() {
        document.cookie.split(";").forEach((item) => {
            item.includes(this.name) ? this.data = item.split("=")[1] : null;
        });
        return this.data;
    }
    
    delete() {
        document.cookie = this.name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
}

export default Cookies;