import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdminAuthService from "../services/AdminAuthService";
import Cookies from "../../../utils/Cookies";
import { ROUTES } from "../../../constants/routes";
import jwt_decode from "jwt-decode";

const AdminAuthentication = (props: any) => {

    const {login, showSnackbar } = props;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [params, setParams] = useSearchParams();
    
    const adminLogin = async () => {
        new Cookies("access-token").delete();
        const code = params.get("code");
        const decodedCode: any = jwt_decode(code!);
        const { accessToken, storeID, userID } = decodedCode;
        const payload = {
            sellerId: userID,
            storeId: storeID
        };
        await new AdminAuthService(payload, accessToken).adminLogin().then(async (res) => {
            const { accessToken, userType, email, name, storeName, storeCode, perPageLimit,roleId} = res;
            await login(accessToken, null, userType, email, name, storeID, storeName, storeCode, perPageLimit,roleId);
            navigate(ROUTES.PROFILE);
        }).catch((err) => {
            showSnackbar(err?.message, false);
        });
    };

    useEffect(()=>{
        adminLogin();
    },[]);

    // setTimeout(() => {
    //     adminLogin();
    // }, 1500);

    return (
        <>
            <Grid container justifyContent={"center"} sx={{pt: 30}}>
                <Typography fontSize={50}>{t("verify_invitation.please_wait")}</Typography>
            </Grid>
        </>
    );
};

export default AdminAuthentication;