import ImageModel from "../Image/ImageModel";

class ProductModel {
    private _id!: number;
    private _productCode!: number;
    private _jan!: number;
    private _name!: string;
    private _price!: number;
    private _quantity!: number;
    private _images!: ImageModel[];

    public static parseJson(itemProduct: any) {
        const product: ProductModel = new ProductModel();

        product.id = itemProduct.id;
        product.productCode = itemProduct.productCode;
        product.jan = itemProduct.jan;
        product.name = itemProduct.name;
        product.price = itemProduct.price;
        product.quantity = itemProduct.quantity;
        const imageList: ImageModel[] = [];
        itemProduct.images.forEach((image: ImageModel) => {
            let imageModel: ImageModel = new ImageModel();
            imageModel = ImageModel.parseJson(image);
            imageList.push(imageModel);
        });
        product.images = imageList;
        return product;
    }

    public get id() {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }
    public get productCode() {
        return this._productCode;
    }

    public set productCode(productCode: number) {
        this._productCode = productCode;
    }

    public get jan() {
        return this._jan;
    }

    public set jan(jan: number) {
        this._jan = jan;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get price() {
        return this._price;
    }

    public set price(price: number) {
        this._price = price;
    }

    public get quantity() {
        return this._quantity;
    }

    public set quantity(quantity: number) {
        this._quantity = quantity;
    }

    public get images() {
        return this._images;
    }

    public set images(images: ImageModel[]) {
        this._images = images;
    }
}

export default ProductModel;