export const mockAPIPath = {
    SELLER_BUSSINESS_INFO: "./data/sellerBussinessInfo.json",
    EDIT_PROFILE:"./data/editProfile.json",
    PAYEE_INFORMATION: "./data/payeeInformation.json",
    PAYMENT_INFORMATION: "./data/paymentInformation.json",
    SELLER_INFO : "/user/details",
    SAVE_STORE_DETAILS:"./data/storeDetails.json"
};

export const serverAPIPath = {
    SELLER_BUSSINESS_INFO: "/user/policy",
    EDIT_PROFILE:"/user/edit-profile",
    SELLER_INFO : "/user/details",
    PAYEE_INFORMATION: "/user/stores/payee/",
    PAYMENT_INFORMATION: "./data/paymentInformation.json",
    SAVE_STORE_DETAILS:"/user/stores/details/",
    BILLING_ADDRESS: "/user/stores/billing/"
};
