import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath ,mockAPIPath} from "../config/apiPath";


class OTPService {

    private payload: object;
    private isHitDummyURL: boolean;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async generate(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(`${mockAPIPath.GENERATE_OTP}`, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.GENERATE_OTP}`, this.payload).post();
        let isOtpSent: string;
        if(response?.data?.success==true) {
            isOtpSent = response?.data?.message;
            return isOtpSent;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async verify(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(mockAPIPath.VERIFY_OTP, this.payload).getNew() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_OTP}`, this.payload).post();
        let isOtpVerified: string;
        if(response?.data?.success==true) {
            isOtpVerified = response?.data?.message;
            return isOtpVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

export default OTPService;