import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { TransactionLogWithPaginationType } from "../../../interfaces/transactionInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class TransactionService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async transactionList(
        page: number,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT,
        search?:string,
        transactionType?:string,
        transactionStatus?:string,
        pastNoOfDays?:string,
        toParam?:string,
        fromParam?:string,
        settlementPeriod?:string,
        depositDate?:string,
        colName?:string,
        order?:string     
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.TRANSACTION_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.TRANSACTION_LIST}?limit=${limit}&search=${search}&transactionType=${transactionType}&transactionStatus=${transactionStatus}&days=${pastNoOfDays}&to=${toParam}&from=${fromParam}&settlementPeriod=${settlementPeriod}&paymentDay=${depositDate}&page=${page}&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, configurationDate,firstSalesDate } = response.data.data;
            const TransactionsData: TransactionLogWithPaginationType = {
                transactionsLogs: data,
                configurationDate:configurationDate,
                firstSalesDate:firstSalesDate,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadPaymentReportList(
        search?:string,
        transactionType?:string,
        transactionStatus?:string,
        pastNoOfDays?:string,
        toParam?:string,
        fromParam?:string,
        settlementPeriod?:string,
        depositDate?:string,
        colName?:string,
        order?:string 
 
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.TRANSACTION_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.TRANSACTION_LIST}?search=${search}&transactionType=${transactionType}&transactionStatus=${transactionStatus}&days=${pastNoOfDays}&to=${toParam}&from=${fromParam}&settlementPeriod=${settlementPeriod}&paymentDay=${depositDate}&download=true&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, configurationDate} = response.data.data;
            const TransactionsData: TransactionLogWithPaginationType = {
                transactionsLogs: data,
                configurationDate:configurationDate,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    
}

export default TransactionService;