import React, { useEffect } from "react";
import { Grid, Typography, Button, Table, TableRow, TableCell, Box, TableHead, TableBody, TableContainer, Paper } from "@mui/material";
import {useTranslation} from "react-i18next";
import Utility from "../../../utils/Utility";
import CommonButton from "../../common/Button";

const ShippedPackage = (props: any) => {

    const {productSummary, editShipment, confirmShipmentData, itemProduct, shipProductIndex} = props;

    const [t] = useTranslation();

    let invoiceNumber: any = "";
    let shipmentDate: any = "";

    productSummary[itemProduct]?.products?.map((item: any) => {
        item?.invoiceNumber != "" ? invoiceNumber = item?.invoiceNumber : null;
        item?.shipmentCompletionDate != "" ? shipmentDate = item?.shipmentCompletionDate : null;
    });

    return (
        <>
            <Grid
                className="shipment_product_table"
                sx={{ mt: 5 }}
                key={shipProductIndex}
            >
                <Grid container sx={{ mt: 2 }}>
                    <Grid container sx={{ mb: 3 }}>
                        <Grid item lg={12}>
                            <Typography component="h6" sx={{ fontWeight: 700 }}>
                                {t("oms.package")} {shipProductIndex + 1}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        className="shipment-order-summary-detail"
                        sx={{
                            border: "#e4e4e4 2px solid",
                            borderRadius: "20px 20px 0px 0px",
                            p: 3,
                            pt: 1,
                            backgroundColor: "#f0f2f2",
                        }}
                    >
                        <Grid item lg={12}>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item lg={3} sx={{ pr: 2 }}>
                                    <Typography component="h6" sx={{ fontWeight: 700 }}>
                                        {t("oms.action_package")}{shipProductIndex + 1}{t("oms.operation_on")} 
                                    </Typography>
                                </Grid>
                                <Grid item lg={5}>
                                    <CommonButton route={window.location.pathname} functionToExecute={() => editShipment(itemProduct)} className="margin_right" btnName={t("oms.edit_shipment")} disableCondition={confirmShipmentData?.status == "6"}/>

                                    {/* <Button
                                        variant="contained"
                                        sx={{ mr: 1 }}
                                        onClick={() => editShipment(itemProduct)}
                                        disabled={confirmShipmentData?.status == "6"}
                                    >
                                        {t("oms.edit_shipment")}
                                    </Button> */}
                                </Grid>
                                <Grid item lg={4}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        className="shipment-order-summary-detail"
                        sx={{
                            border: "#e4e4e4 2px solid",
                            borderRadius: "0px 0px 20px 20px",
                            p: 3,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Grid item lg={12}>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item lg={7} sx={{ pr: 2 }}>
                                    <Grid  item sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {t("oms.ship_date")}
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                            {shipmentDate}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {t("oms.carrier")}
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                            {invoiceNumber.split("-")[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {t("oms.tracking_id")}
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                            {invoiceNumber.split("-")[1]}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <TableContainer component={Paper} className="shipment_product_table" sx={{ mt: 5 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="table_head">
                                    <TableCell>
                                        <Box>
                                            {t("oms.image")}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            {t("oms.product_information")}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>{t("oms.more_information")}</Box>
                                    </TableCell>

                                    <TableCell className="text_right">
                                        <Box>{t("oms.shipped_quantity")}</Box>
                                    </TableCell>

                                    <TableCell className="text_right">
                                        <Box>{t("oms.unit_price")}</Box>
                                    </TableCell>

                                    <TableCell className="text_right">
                                        <Box>
                                            {t("oms.process")}
                                        </Box>
                                    </TableCell>

                                    <TableCell className="text_right">
                                        <Box>
                                            {t("oms.shipping_fee")}
                                        </Box>
                                    </TableCell>

                                    <TableCell className="text_right">
                                        <Box>
                                            {t("oms.sales")}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="shipment-product-detail">
                                {
                                    productSummary[itemProduct]?.products?.map((item: any, indexes: number) => {
                                        return (
                                            <TableRow key={indexes}>
                                                <TableCell>
                                                    <Grid sx={{ mr: 2 }}>
                                                        <Box
                                                            component="img"
                                                            sx={{ width: 100 }}
                                                            src={item?.image}
                                                            alt="product image"
                                                        />
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Box className="shipment_product_table_box">
                                                        <Grid>
                                                            <Typography>
                                                                {t("oms.product_name")} : 
                                                                {item?.productName}
                                                            </Typography>
                                                            <Typography>
                                                                {t("oms.product_code")} : {item?.productCode}
                                                            </Typography>
                                                            <Typography>
                                                                {t("oms.cpid")}:{" "}
                                                                {item?.cainzProductCode}
                                                            </Typography>
                                                            <Typography>
                                                                {t("oms.sku")}: {item?.salesVolume}
                                                            </Typography>
                                                        </Grid>
                                                    </Box>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <Typography>
                                                        {item?.productDeliveryDate}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className="text_right">
                                                    <Typography>  {item?.shippedQuantity}</Typography>
                                                </TableCell>

                                                <TableCell className="text_right">
                                                    <Typography>
                                                    &yen; {new Utility().numberWithCommas(item?.productPriceIncludingTax)}
                                                    </Typography>
                                                            
                                                </TableCell>

                                                <TableCell className="text_right">
                                                    <Typography>
                                                        &yen; {" "}
                                                        {new Utility().numberWithCommas(item?.productPriceIncludingTaxSubtotal)}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className="text_right">
                                                    <Typography>
                                                              &yen; {new Utility().numberWithCommas(item?.individualShippingFeeIncludingTax)}
                                                    </Typography>
                                                           
                                                </TableCell>

                                                <TableCell className="text_right">
                                                    <Typography> &yen; {new Utility().numberWithCommas(item?.productPriceIncludingTaxSubtotal + item?.individualShippingFeeIncludingTax)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                        
                </Grid>
            </Grid>
           
        </>
    );
};

export default ShippedPackage;