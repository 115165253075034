import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordService from "../services/PasswordService";
import CONSTANTS from "../../../constants/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";

const VerifyPassword = (props: any) => {

    const { showSnackbar,handleLoader } = props;
    {
    /* Language translate function*/
    }
    const [t] = useTranslation();
    {
    /* Language translate function*/
    }

    const navigate = useNavigate();

    const [passwordType, setPasswordType] = useState("password");
    const [password, setPassword] = useState("");
    // const [error, setError] = useState("");

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                regex: t("verifypassword.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "password" ? setPassword(value) : null;
        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if(validator.current.allValid()){
            handleLoader(true);
            const payload = { password };
            const isHitDummyURL = false;
            new PasswordService(payload, isHitDummyURL)
                .verify()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.UPDATE_PASSWORD);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }else{
            validator.current.showMessages();
        }
    };

    const handleClickShowPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };
    
    // const handleMouseDownPassword = () => {
    //     setPasswordType("text");
    // };

    return (
        <>
            {/* VerifiedOTP page starts here */}
            <Grid>
                <Container>
                    <Grid container spacing={4} sx={{ py: 10 }}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <Box sx={{ textAlign: "center", my: 2 }}>
                                <Typography variant="h6">
                                    
                                    {t("verifypassword.verify_password")}
                                </Typography>
                                <Typography>
                                   
                                    {t("verifypassword.current_password_verify")}
                                </Typography>
                            </Box>
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownPassword}
                                                    // onTouchEnd={handleClickShowPassword}
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                >
                                                    { passwordType=="password" ? 
                                                        <VisibilityIcon /> : 
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    type={passwordType}
                                    value={password}
                                    name = "password"
                                    label={t("verifypassword.password")}
                                    variant="outlined"
                                            
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}

                            </Grid>
                            <Button fullWidth onClick={handleSubmit}>
                                {t("verifypassword.verify")}
                            </Button>
                        </Grid>
                           
                    </Grid>
                    {/* offset grid with column*/}
                    <Grid item xs={12} md={3}></Grid>
                </Container>
            </Grid>
            {/* VerifiedOTP page ends here */}
        </>
    );
};

export default VerifyPassword;
