interface UserDetailInterface {
    userDetails?:{
    id?: number | null,
    name?: string | null,
    email?: string | null,
    brands?: Array<string> | null,
    plateformFeeRate?: string | null,
    productRepresentativeInfo?: {
        id?: number | null,
        name?: string | null,
        surName?: string | null,
        phoneNumber?: number | null
    },
    store?: [{
        id?: number | null,
        sellerPersonalInfoId?: string | null,
        storeName?: string | null,
        storeCode?: string,
        companyName?: string | null,
        corporateName?: string | null,
        registrationNumber?: string | null,
        corporateNumber?: number | null,
        phoneNumber?: number | null,
        zipcode?: number | null,
        state?: string | null,
        city?: string | null,
        streetNumber?: string | null,
        address?: string | null,
        inquiryEmail?: string | null,
        inquiryPhoneNumber?: string | null,
        inquiryPossibleTime?: string | null,
        billingInfo?: [{
            id?: number | null,
            storeId?: number | null,
            zipcode?: number | null,
            state?: string | null,
            city?: string | null,
            streetNumber?: string | null,
            address?: string | null,
            landmark?: string | null
        }],
        payeeInfo?: [{
            id?: number | null,
            payeeName?: number | null,
            storeId?: number | null,
            bankCode?: number | null,
            financialInstitutionName?: string | null,
            branchCode?: number | null,
            branchName?: string | null,
            accountType?: string | null,
            accountNumber?: number | null
        }],
        policy?: {
            id?: number | null,
            faq?: string | null,
            aboutUs?: string | null,
            shippingPolicy?: string | null,
            customPolicyTitle?: string | null,
            customPolicyDescription?: string | null,
            privacyPolicy?: string | null
        },
        businessPartnerInfo: {
            mpFeeRate: string | null,
            partnerCode: string | null,
            partnerName: string | null
        }
    }] 
    }
}

export const userDetailsInitialValue: UserDetailInterface = {
    userDetails:{
        id: null,
        name:  null,
        email:  null,
        brands:  null,
        plateformFeeRate: null,
        productRepresentativeInfo: {
            id: null,
            name:  null,
            surName:  null,
            phoneNumber: null
        },
        store: [{
            id: null,
            sellerPersonalInfoId:  null,
            storeName:  null,
            companyName:  null,
            corporateName:  null,
            registrationNumber: null,
            corporateNumber: null,
            phoneNumber: null,
            zipcode: null,
            state:  null,
            city:  null,
            streetNumber:  null,
            address:  null,
            inquiryEmail: null,
            inquiryPhoneNumber: null,
            inquiryPossibleTime: null,
            billingInfo: [{
                id: null,
                storeId: null,
                zipcode: null,
                state:  null,
                city:  null,
                streetNumber:  null,
                address:  null,
                landmark:  null
            }],
            payeeInfo: [{
                id: null,
                payeeName:null,
                storeId: null,
                bankCode: null,
                financialInstitutionName:  null,
                branchCode: null,
                branchName:  null,
                accountType:  null,
                accountNumber: null,
            }],
            policy: {
                id: null,
                faq: null,
                aboutUs: null,
                shippingPolicy: null,
                customPolicyTitle: null,
                customPolicyDescription: null,
                privacyPolicy: null
            },
            businessPartnerInfo: {
                mpFeeRate: null,
                partnerCode: null,
                partnerName: null
            }
        }]
    }
};

export default UserDetailInterface;