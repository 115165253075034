import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { Container } from "@mui/system";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/routes";

const ErrorDownloadProduct = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();


    const handleNavigation = () => {
        navigate(ROUTES.PRODUCT_LIST);
    };

    return (
        <>
            <Container>
                <Grid container sx={{ py: 30 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box className="text_center">
                            <Typography  sx={{margin:2}}>{t("product_list.download_link_expired")}</Typography>
                            <Button 
                                variant="contained"
                                sx={{textTransform: "capitalize"}}
                                onClick={handleNavigation}>
                                {t("product_list.continue")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default ErrorDownloadProduct;
