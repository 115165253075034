export const mockAPIPath = {
    LOGIN : "",
    REGISTER : "./data/register.json",
    VERIFY_PASSWORD: "./data/register.json",
    UPDATE_PASSWORD: "./data/register.json",
    RESET_PASSWORD: "./data/register.json",
    GENERATE_OTP: "./data/register.json",
    VERIFY_OTP: "./data/register.json",
    VERIFY_EMAIL : "",
    STATES_LIST : "",
    CITY_LIST :"",
    BRAND_LIST : "",
    REGISTER_FIRST_STEP: "",
    ROLES_BASED_PERMISSION: "./data/exhibitionUserPermission.json",
    NEW_ROLES_BASED_PERMISSION: "./data/exhibitionUserPermission.json"

};

export const serverAPIPath = {
    LOGIN : "/seller/login",
    LOGOUT: "/seller/logout",
    REGISTER : "/seller/register/step-second",
    VERIFY_PASSWORD: "/seller/verify-old-password",
    UPDATE_PASSWORD: "/seller/update-password",
    RESET_PASSWORD: "/seller/reset-password",
    GENERATE_OTP: "/otp/generate",
    VERIFY_OTP: "/otp/verify",
    VERIFY_EMAIL : "/seller/verify-email",
    STATES_LIST : "/master/state",
    CITY_LIST :"/master/city",
    BRAND_LIST: "/master/brand",
    REGISTER_FIRST_STEP : "/seller/register/step-first",
    ROLES_BASED_PERMISSION: "/seller/permissions",
    ADMIN_AUTH_ON_SELLER_PANEL: "/seller/admin-login",
    NEW_ROLES_BASED_PERMISSION: "/rms/roles"

};
