import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import { LIST_LIMIT } from "../../../constants/constants";
import { OMSTypeWithPaginationType } from "../../../interfaces/manageOrderInterface";
import confirmShipmentType from "../../../interfaces/confirmShipmentInterface";
import { OMSReasonType } from "../../../interfaces/omsReasonInterface";


class confirmShipmentService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async confirmShipmentList(
        page_id: number,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const confirmShipmentData: confirmShipmentType = response?.data?.data;
            return confirmShipmentData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async cancelOrderReason() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_CANCEL_ORDER_REASON}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_CANCEL_ORDER_REASON}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const cancelOrderReasonData: OMSReasonType[] = response?.data?.data;
            return cancelOrderReasonData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async cancelOrder() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_CANCEL_ORDER}`,
                this.payload,
                this.headers
            ).put()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_CANCEL_ORDER}`,
                this.payload,
                this.headers
            ).put();
        let responseMessage: string;
        if(response?.data?.success==true) {
            responseMessage = response?.data?.message;
            return responseMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

}
export default confirmShipmentService;