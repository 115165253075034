import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const ScrollableInput = (props:any) => {

    const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);
    const checkTouchDevice = () => {        
        return "ontouchstart" in window || navigator.maxTouchPoints;
    };
    useEffect(() => {
        setIsTouchDevice(checkTouchDevice() !== 0 ? true : false);
    }, [isTouchDevice]);
   
 
    const {value,
        onChange,
        onBlur,
        name,
        label,
        className,
        disabled,
        required,
        type,
    } = props;
    return (
        <TextField
            className={`product_text_area ${isTouchDevice ? "product_text_area_with_out_hover" : "product_text_area"} ${className || ""}`}
            fullWidth
            disabled={disabled}
            InputProps={{
                rows: 1,
                multiline: false,
                inputComponent:"textarea",
                className:"input_scroll",
            }}
            type={type}
            value={value}
            name={name}
            label={label}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            
        />
    );
};
export default ScrollableInput;
