import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class ShippedOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async shippedOrder() {
        let shippedOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (shippedOrderService = await new AxiosUtils(
                `${mockAPIPath.SHIPPED_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (shippedOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SHIPPED_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return shippedOrderService;
    }
}

export default ShippedOrderDetail;

