import React,{useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    Grid,
    Typography,
    Box,
    MenuItem,
    OutlinedInput,
    Select,
    Divider,
    FormControl,
    InputLabel,
    Button
} from "@mui/material";
import confirmShipmentService from "../service/confirmShipmentService";

import SimpleReactValidator from "simple-react-validator";
import CommonButton from "../../common/Button";



const CancelOrder = (props: any) => {
    const [t] = useTranslation();
    const { productSummary, itemProduct, handleChange, setProductQuantityOption, quantity, setshowReasonModel, showSnackbar , setCancelOrderModel, cancelOrderId ,handleLoader, confirmedData} = props;
    const [selectReason, setSelectReason] = useState("");
    const [ordeId, setOrderId] = useState<any>();
    const [omsCancel , setOmsCancel] = useState<any>();
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [selectedReasonId, setSelectedReasonId] = useState<number>();

    const handleSelectReason = (event:any) => {
        setSelectReason(event.target.value);
        setSelectedReasonId(event.target.value);
    };

    useEffect(() => {
        omsCancelData();
    }, []);
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const omsCancelData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new confirmShipmentService(payload, isHitDummyURL)
            .cancelOrderReason()
            .then((res) => {
                setOmsCancel(res);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };


    const handleReasonSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const orderId:any = cancelOrderId ;
            const payload = {orderId, reasonId: selectedReasonId};
            const isHitDummyURL = false;
            new confirmShipmentService(payload, isHitDummyURL)
                .cancelOrder()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    confirmedData();
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }
        else {
            validator.current.showMessages();
        }
    };

    return (
        <>
            <Box className="oms_confirm">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography component="h6">
                                {t("oms.please_select_reason")}
                            </Typography>
                        </Grid>
                       
                        {productSummary[0]?.products?.map((item: any, index: number) => {
                            return (
                                <Grid item lg={4}  md={6} sm={12} xs={12} key={index}>
                                    <Box className="cancel_card"> 
                                        <Grid sx={{ mt: 1 }}>
                                            <Typography className="text_wrap_data">{item?.productName}</Typography>
                                            <Typography className="text_wrap_data">
                                                {t("oms.cpid")}: {item?.cainzProductCode}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {t("oms.sku")}: {item?.salesVolume}
                                            </Typography>
                                        </Grid>
                                    </Box>
                                </Grid>
                            );
                        })}
                    
                     
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}                       
                            className="cancel-order-detail">
                            <Typography component="h6" sx={{ mb:1, mt:1 }}>
                                {t("oms.cancel_order")}
                            </Typography>
                            
                            <Divider />
                            <Grid container spacing={2} sx={{my:4}}>
                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>      
                                    <Typography> {t("oms.why_you_are_canceling_the_order")} </Typography>
                                </Grid> */}
                                <Grid item lg={3} md={4} sm={7} xs={12}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label" >
                                            {t("oms.select_a_reason")}                                
                                        </InputLabel>
                                        <Grid>
                                            <Select fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={selectReason}
                                                label=  {t("oms.select_a_reason")}         
                                                onChange={handleSelectReason}>
                                            
                                            
                                                {
                                                    omsCancel?.map((item:any, index: number)=>{
                                                        return (
                                                            <MenuItem value={item?.id} key={index}> {item?.reasonDescription} </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                            {validator.current.message("required", selectReason, [
                                                "required",
                                            ])}
                                        </Grid>
                                       
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} md={2} sm={4} xs={12}>
                                    <CommonButton route={window.location.pathname} functionToExecute={()=>handleReasonSubmit()} className="background_color_red" btnName={t("oms.cancel_order")} disableCondition={false}/>
                                    {/* <Button variant="contained" color="error"  onClick={()=>handleReasonSubmit()}>{t("oms.cancel_order")}</Button> */}
                                </Grid>
                                <Grid item lg={7}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
           
        </>
    );
};

export default CancelOrder;