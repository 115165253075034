export const mockAPIPath = {
    PRODUCT_LIST: "",
    PRODUCT_LIST_NEW: process.env.REACT_APP_OMS_DATA,
    SELLER_REPORTS: "",
    SELLER_INFO_REPORTS: "",
    TEMPLATE_DOWNLOAD: "",
    FILE_UPLOAD: " ",
};

export const serverAPIPath = {
    PRODUCT_LIST: "",
    SELLER_REPORTS: "",
    SELLER_INFO_REPORTS: "/products/image/report",
    TEMPLATE_DOWNLOAD: "",
    ERROR_DOWNLOAD: "/products/image/errors/download/",
    FILE_UPLOAD: "/products/image/zip-file",
    LATEST_SELLER_INFO_REPORTS: "/products/image/report/latest"
};





