import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class CanceledOrderDetail  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async canceledOrder() {
        let canceledOrderService;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (canceledOrderService = await new AxiosUtils(
                `${mockAPIPath.CANCELED_ORDER_DETAIL}`,
                this.payload
            ).getNew())
            : (canceledOrderService = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CANCELED_ORDER_DETAIL}`,
                this.payload
            ).getNew());
        return canceledOrderService;
    }
}

export default CanceledOrderDetail;

