import React, { useEffect, useRef, useState } from "react";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import {
    Button,
    Container,
    Grid,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Typography,
    Box,
    MenuItem,
    TextareaAutosize,
    TableContainer,
    Paper,
    TableHead,
} from "@mui/material";
import OmsManageOrderService from "../service/OmsManageOrderService";
import Skeleton from "../../common/Skeleton";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import SimpleReactValidator from "simple-react-validator";
import ShipmentDataService from "../service/shipmentDataService";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import CancelOrder from "./CancelOrder";
import { useParams } from "react-router";
import CONSTANTS, { OMS_CONSTANTS } from "../../../constants/constants";
import prepareForShipmentServices from "../service/prepareShipment";
import ShippedPackage from "./ShippedPackage";
import ConfirmOrder from "./ConfirmOrder";
import adminNoteService from "../service/adminNoteService";
import Utility from "../../../utils/Utility";
import sellerMemoService from "../service/sellerMemoService";
import CommonButton from "../../common/Button";
import packagingSlipService from "../service/packagingSlipService";
import { pdf } from "@react-pdf/renderer";
import { PackagingSlip } from "../../common/PDFTemplates/PackagingSlip";
import { OMSConfig } from "../config/OMSConfig";
import DownloadButton from "../../common/DownloadBtn";
import Cookies from "../../../utils/Cookies";
import { API } from "../../../config";

const OMSConfirmShipment = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    const loggedInUserType = useAppSelector(
        (state) => state.permission.permissionDetails.userType
    );

    const [t, i18n] = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const searchParams = useLocation();

    const [skeleton, setSkeleton] = useState(true);
    const [confirmShipmentModel, setConfirmShipmentModel] = useState<any>([]);
    const [trackingId, setTrackingId] = useState<any>([]);
    const [others, setOthers] = useState<any>([]);
    const [shipDate, setShipDate] = useState<any>([]);
    const [carrier, setCarrier] = useState<any>([]);
    const [confirmShipmentData, setConfirmShipmentData] = useState<any>();
    const [productSummary, setProductSummary] = useState<any>([]);
    const [shipProduct, setShipProduct] = useState<any>([0]);
    const [openShipmentModal, setOpenShipmentModal] = useState(false);
    const [openEditShipmentModal, setOpenEditShipmentModal] = useState(false);
    const [count, setCount] = useState(1);
    const [quantity, setQuantity] = useState({ maxOption: "", product: "" });
    const [flag, setFlag] = useState(false);
    const [totalProductCount, setTotalProductCount] = useState<any>({});
    const [, forceUpdate] = useState(0);
    const [quantityCalculate, setQuantityCalculate] = useState<any>({});
    const [cancelOrderModel, setCancelOrderModel] = useState(false);
    const [showReasonModel, setshowReasonModel] = useState(false);
    const [cancelButtonVisible, setCancelButtonVisible] = useState(true);
    const [prepareToShipment, setprepareToShipment] = useState(true);
    const [shippedPackages, setShippedPackages] = useState<any>([]);
    const [numberOfShippedPackages, setNumberOfShippedPackages] = useState<any>(0);
    const [adminNote, setAdminNote] = useState<any>("");
    const [sellerMemo, setSellerMemo] = useState<any>("");
    const [vadFlag, setVadFlag] = useState<any>([false]);
    const [shipmentCompleted, setShipmentCompleted] = useState<any>(false);
    const utility = new Utility;
    let status: any;
    let CancelableFlag: any;

    useEffect(() => {
        confirmedData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     (confirmShipmentData?.status !== "5") ? null : handleShipment();
    //     const one = new Array(shipProduct.length).fill(false);
    //     setVadFlag(one);
    //     setShipmentCompleted(false);
    // }, [shipmentCompleted]);

    const isSingleByte = (value: string) => {
        // eslint-disable-next-line no-control-regex
        return /^[\x00-\xFF]*$/.test(value);
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                min_quantity: t("oms.min_quantity_validation"),
                max_quantity: t("oms.max_quantity_validation"),
                required: t("oms.required_field_validation"),
                regex: t("oms.invalid_character"),
            },
            validators: {
                maxChar50Validation: {
                    message: t("registerpage.max_char", { size: 50 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar100Validation: {
                    message: t("registerpage.max_char", { size: 100 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                SingleByte: {
                    message: t("registerpage.single_byte_characters"),
                    rule: (value) => isSingleByte(value),
                },
                // unique: {
                //     message: t("oms.tracking_id_unique_msg").toString(),
                //     rule: (value: any, params: any) => {
                //         return !params[0];
                //     },
                // }
            },
        })
    );

    const memoValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            validators: {
                maxChar2000Validation: {
                    message: t("registerpage.max_char", { size: 2000 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
            },
        })
    );

    const noteValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("oms.required_field_validation"),
            },
            validators: {
                maxChar1000Validation: {
                    message: t("registerpage.max_char", { size: 1000 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
            },
        })
    );

    const scrollIntoView = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element?.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        forceUpdate(1);
    });

    useEffect(() => {
        if (showReasonModel) {
            scrollIntoView("cancel_order_message");
        }
        if (cancelOrderModel) {
            scrollIntoView("cancel_order");
        }
        if (confirmShipmentModel) {
            scrollIntoView("confirm_shipment");
        }
        if (openShipmentModal) {
            scrollIntoView("confirm_shipment");
        }
    }, [cancelOrderModel, showReasonModel, confirmShipmentModel, openShipmentModal]);

    useEffect(() => {
        flagSet();
    }, [quantityCalculate]);

    const cancelId = params.id;
    const showModel = searchParams.search;

    const confirmedData = async (type = "confirm") => {
        // confirmShipmentData?.status == 3
        //     ? setprepareToShipment(true)
        //     : setprepareToShipment(false);
        const orderID = params.id;
        const payload = {};
        const isHitDummyURL = false;
        new OmsManageOrderService(payload, isHitDummyURL)
            .omsConfirmShipment(orderID)
            .then((res) => {
                CancelableFlag = res?.sellerCancelableFlag;
                status = res?.status;
                setConfirmShipmentData(res);
                setSellerMemo(res?.sellerMemo);
                setProductSummary(res?.shipmentPackages);
                setMaxOption(res?.shipmentPackages, type);
                if (status == "9") {
                    setOpenShipmentModal(false);
                    setCancelOrderModel(false);
                } else if (showModel == "?showModel=1" && CancelableFlag === "1" && res?.cancelFlag) {
                    setOpenShipmentModal(false);
                    setCancelOrderModel(true);
                } else if (showModel == "?showModel=2" && status == "5") {
                    handleShipment();
                }
                if (type == "shipped" && status == "5") {
                    handleShipment();
                }
                if (status == "3") {
                    setprepareToShipment(true);
                } else {
                    setprepareToShipment(false);
                }
                loggedInUserType == CONSTANTS.USER_TYPE_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? res?.status == "6" ? adminNoteGet() : null : null;
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const handlePrepareShipment = (id: number) => {
        const idArr: any = [];
        idArr.push(id);
        const payload = { orderId: idArr };
        const isHitDummyURL = false;
        handleLoader(true);
        new prepareForShipmentServices(payload, isHitDummyURL)
            .prepareForShipment()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res, true);
                setprepareToShipment(false);
                confirmedData();
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
                setprepareToShipment(true);
            });
    };

    const calculateQuantity = () => {
        let quantityCalc: any = {};
        let key: any, value: any;
        for ([key, value] of Object.entries(quantity?.product[0])) {
            quantityCalc = { ...quantityCalc, [key]: 0 };
        }
        shipProduct?.forEach((item: any) => {
            for ([key, value] of Object.entries(quantity?.product[item])) {
                quantityCalc = {
                    ...quantityCalc,
                    [key]:
                        Number(quantityCalc[key]) + Number(quantity?.product[item][key]),
                };
            }
        });
        setQuantityCalculate(quantityCalc);
    };

    // ***** Used to 3 Max limit for dropdown for first product  ***** //
    const setMaxOption = (product: any, type = "confirm") => {
        let option: any = [];
        let optionSaved: any = [];
        let maxOption: any = {};
        let quantityJson: any = {};
        const products: any = [];
        const shipProductQuantity: any = [];
        const getCarrier: any = [];
        const getOther: any = [];
        const getShipDate: any = [];
        const getTrackingId: any = [];
        let productArr: any = [];
        let counter = 0;
        let productCount = 0;

        const shipmentArray = [...confirmShipmentModel];

        product?.map((item: any, index: number) => {
            const trackingIdArr: any = [];
            const othersArr: any = [];
            const carrierArr: any = [];
            const shipDateArr: any = [];
            counter++;
            shipProductQuantity.push(index);
            shipmentArray[index] = true;
            productArr = [...item.products];
            item?.products?.forEach((prod: any) => {
                option = { ...option, [prod.cainzProductCode]: (Number(prod?.shippedQuantity)) };
                !prod?.isShipped ? optionSaved = { ...optionSaved, [prod.cainzProductCode]: Number(prod?.salesVolume) } : optionSaved = { ...optionSaved, [prod.cainzProductCode]: Number(prod?.shippedQuantity) };
                maxOption = { ...maxOption, [prod.cainzProductCode]: Number(prod?.salesVolume) };
                productCount += Number(prod.shippedQuantity);
                shipDateArr.push(prod?.shipmentCompletionDate);
                prod?.invoiceNumber ?
                    CONSTANTS.CARRIER_MENU_OMS.includes(prod?.invoiceNumber.split("-")[0]) ? carrierArr.push(prod?.invoiceNumber.split("-")[0]) : carrierArr.push("その他") && othersArr.push(prod?.invoiceNumber.split("-")[0])
                    : carrierArr.push("");
                trackingIdArr.push(prod?.invoiceNumber.split("-")[1]);
            });
            getShipDate.push(shipDateArr.filter((item: any) => item != "")[0]);
            getTrackingId.push(trackingIdArr.filter((item: any) => item != undefined)[0]);
            getCarrier.push(carrierArr.filter((item: any) => item != "")[0]);
            getOther.push(othersArr.filter((item: any) => item != undefined)[0]);
            products.push(option);
        });
        quantityJson = {
            maxOption: maxOption,
            product: products,
        };

        const shippedProductQuantity = [...shipProductQuantity];

        if (productCount) {
            setNumberOfShippedPackages(products.length);
            let quantityCalc: any = {};
            let key: any, value: any;
            for ([key, value] of Object.entries(quantityJson?.product[0])) {
                quantityCalc = { ...quantityCalc, [key]: 0 };
            }
            shippedProductQuantity?.map((item: any) => {
                for ([key, value] of Object.entries(quantityJson?.product[item])) {
                    quantityCalc[key] = Number(quantityCalc[key]) + Number(quantityJson?.product[item][key]);
                }
            });
            for ([key, value] of Object.entries(quantityCalc)) {
                quantityCalc[key] = Number(quantityJson?.maxOption[key]) - Number(quantityCalc[key]);
            }
            products.push(quantityCalc);

            quantityJson = {
                maxOption: maxOption,
                product: products,
            };
            shippedProductQuantity.push(counter);
            setShippedPackages(shipProductQuantity);
            product.push({ products: productArr });
            setProductSummary(product);
            counter++;
        } else {
            products[0] = optionSaved;
            quantityJson = {
                maxOption: maxOption,
                product: products,
            };
        }
        setQuantity(quantityJson);
        setTotalProductCount(quantityJson.product[0]);
        setShipProduct(shippedProductQuantity);
        setCount(counter);
        setConfirmShipmentModel(shipmentArray);
        setTrackingId(getTrackingId);
        setOthers(getOther);
        setCarrier(getCarrier);
        setShipDate(getShipDate);

        //Validation enable for last package
        const one = new Array(shippedProductQuantity.length).fill(false);
        validator.current.hideMessages();
        one[one.length - 1] = true;
        setVadFlag(one);
    };

    const flagSet = () => {
        let key: any, value: any;
        setFlag(false);
        const quantityJson: any = { ...quantity };
        for ([key, value] of Object.entries(quantityJson?.maxOption)) {
            if (quantityJson.maxOption[key] != quantityCalculate[key]) {
                setFlag(true);
            }
        }
    };

    // ***** Used to set Quantity from dropdown to each products  ***** //
    const handleChange = (event: any, productId: any, productIndex: number) => {
        setFlag(false);
        let key: any, value: any;
        const quantityJson: any = { ...quantity };
        const maxOptionJson: any = { ...quantityJson.maxOption };
        const changedQuantity: any = { ...quantityJson.product[productIndex] };

        for ([key, value] of Object.entries(quantity?.product[productIndex])) {
            if (key == productId) {
                changedQuantity[key] = event.target.value.toString();
            }
        }
        quantityJson.product[productIndex] = changedQuantity;
        quantityJson.maxOption = maxOptionJson;

        setQuantity(quantityJson);
        calculateQuantity();
        flagSet();
    };

    // ***** Used to Add Package and update Main JSON  ***** //
    // const handleProductChanges = (productindex: any) => {
    //     if (flag) {
    //         validator.current.hideMessages();
    //         const shipProductArr = [...shipProduct];
    //         setCount(count + 1);
    //         shipProductArr.push(count);
    //         setShipProduct(shipProductArr);
    //         updateQuantity(productindex + 1);
    //     }
    // };
    const handleOrderShipment = (updatedAt: any, sellerCancellableFlag: any) => {

        const disabledSingle = checkDisabledSing(updatedAt, sellerCancellableFlag);

        if (disabledSingle.flag) {
            // alert(disabledSingle.messageVar);
            showSnackbar(disabledSingle.messageVar, false);
        } else {
            handleShipment();
        }
    };
    const checkDisabledSing = (updatedAt: any, sellerCancellableFlag: any) => {

        let flag = false;
        let message = "";
        if (sellerCancellableFlag == 1) {
            const currentTime = new Date();
            const updatedAtTime = new Date(updatedAt);

            let timeDifferenceInMinutes = (currentTime.getTime() - updatedAtTime.getTime()) / (1000 * 60);

            const thresholdTimeInMinutes = 5;

            if (timeDifferenceInMinutes <= thresholdTimeInMinutes) {
                flag = true;
            }

            if (timeDifferenceInMinutes == 0) {

                timeDifferenceInMinutes = (currentTime.getTime() - updatedAtTime.getTime()) / (1000);
            }

            const minutesRemaining = Math.floor((5 - timeDifferenceInMinutes));
            const secondsRemaining = Math.floor(((5 - timeDifferenceInMinutes) - minutesRemaining) * 60);

            message = `${t("oms.order_can_be_shipped")} ${minutesRemaining} ${t("oms.minutes")} ${secondsRemaining} ${t("oms.secound")}`;

            // message = "order can be shipped after" + (5 - timeDifferenceInMinutes) + " minutes ";
        }
        return {
            flag: flag,
            messageVar: message
        };
    };

    // ***** Confirm shipment button  ***** //
    const handleShipment = (model = []) => {
        scrollIntoView("confirm_shipment");
        setOpenShipmentModal(true);
        setCancelOrderModel(false);
        setshowReasonModel(false);
        if (model.length > 0) {
            const shippedPackage = new Array(model.length).fill(true);
            setConfirmShipmentModel(shippedPackage);
        }
    };

    // ***** delete package and update main json  ***** //
    const deleteShipment = (productIndex: number) => {
        const quantityJson: any = { ...quantity };
        const changedQuantity: any = { ...quantityJson.product[productIndex] };
        const shipProductArr: any[] = shipProduct;
        const model = new Array(confirmShipmentModel.length).fill(true);
        setConfirmShipmentModel(model);
        if (productIndex == numberOfShippedPackages) {
            setOpenShipmentModal(false);
            // changedQuantity[productIndex] = quantityJson?.maxOption;
            changedQuantity[productIndex] = quantityJson.product[productIndex];
            // quantityJson.product[productIndex] = changedQuantity[productIndex];
            setQuantity(quantityJson);
            const trackingIdArr = [...trackingId];
            trackingIdArr[productIndex] = "";
            setTrackingId(trackingIdArr);
            const carrierArr = [...carrier];
            carrierArr[productIndex] = "";
            setCarrier(carrierArr);
            const otherArr = [...others];
            otherArr[productIndex] = "";
            setOthers(otherArr);
        } else {
            shipProductArr.splice(productIndex, 1);
            setCount(count - 1);
            setShipProduct(shipProductArr);
            deleteQuantity(productIndex);
        }
    };

    // ***** Back button  ***** //
    const handleOrderList = () => {
        navigate(ROUTES.OMS);
    };

    // ***** Add Package as Product to main JSON  ***** //
    // const updateQuantity = (productIndex: number) => {

    //     //Validation enable for last package
    //     const one = new Array(shipProduct.length).fill(false);
    //     one[productIndex] = true;
    //     setVadFlag(one);
    //     //Update Product JSON
    //     const quantityJson: any = { ...quantity };
    //     const maxOptionJson: any = { ...quantityJson.maxOption };
    //     const changedQuantity: any = { ...quantityJson.product };

    //     let productCount = 0;
    //     let NewProductJson = {};
    //     let key: any, value: any;

    //     for ([key, value] of Object.entries(changedQuantity[productIndex - 1])) {
    //         let totalQuantity = 0;
    //         for (let i = 0; i < productIndex; i++) {
    //             totalQuantity = totalQuantity + Number(quantity?.product[i][key]);
    //         }
    //         productCount = Number(maxOptionJson[key]) - Number(totalQuantity);
    //         NewProductJson = { ...NewProductJson, [key]: productCount.toString() };
    //     }
    //     changedQuantity[productIndex] = NewProductJson;
    //     quantityJson.product[productIndex] = changedQuantity[productIndex];
    //     quantityJson.maxOption = maxOptionJson;
    //     setQuantity(quantityJson);
    //     const updateProductSummary = [...productSummary];
    //     updateProductSummary.push(productSummary[0]);
    //     setProductSummary(updateProductSummary);
    //     calculateQuantity();
    // };

    // ***** Delete Package and remove Product from main JSON  ***** //
    const deleteQuantity = (productIndex: number) => {

        //Delete Product JSON
        const quantityJson: any = { ...quantity };
        const maxOptionJson: any = { ...quantityJson.maxOption };
        const changedQuantity: any = { ...quantityJson.product };
        const updateProductSummary = [...productSummary];
        if (productIndex == 0) {
            setOpenShipmentModal(false);
        } else {
            delete changedQuantity[productIndex];
            updateProductSummary.pop();
        }
        quantityJson.product[productIndex] = changedQuantity[productIndex];
        quantityJson.maxOption = maxOptionJson;
        setQuantity(quantityJson);
        setProductSummary(updateProductSummary);
        calculateQuantity();
    };

    //dropdown creation for Product quantity
    const setProductQuantityOption = (product_id: any, productIndex: number) => {
        const menuItem: any = [];
        let totalQuantity = 0;

        for (let i = 0; i < productIndex; i++) {
            totalQuantity = totalQuantity + Number(quantity?.product[i][product_id]);
        }
        for (
            let index = 1;
            index <= Number(quantity?.maxOption[product_id]) - Number(totalQuantity);
            index++
        ) {
            menuItem.push(
                <MenuItem key={index} value={index}>
                    {index}
                </MenuItem>
            );
        }
        return menuItem;
    };

    // ***** Save all information as state array  ***** //
    const handleChanges = (e: any, itemProduct: number) => {
        const { name, value } = e.target;
        let inputValue: any = [];

        if (name == "ship-date" + itemProduct) {
            inputValue = [...shipDate];
            inputValue[itemProduct] = value;
            setShipDate(inputValue);
        }
        if (name == "carrier" + itemProduct) {
            inputValue = [...carrier];
            inputValue[itemProduct] = value;
            setCarrier(inputValue);
        }
        if (name == "tracking-id" + itemProduct) {
            inputValue = [...trackingId];
            inputValue[itemProduct] = value.toString();
            setTrackingId(inputValue);
        }
        if (name == "others" + itemProduct) {
            inputValue = [...others];
            inputValue[itemProduct] = value.toString();
            setOthers(inputValue);
        }
        validator.current.hideMessageFor(name);
    };

    // ***** Confirm shipment button to send shipment detail for each package  ***** //
    const handleFinalShipment = (
        shippedQuantity: any,
        shipmentDate: any,
        carrier: any,
        trackingId: any,
        productIndex: number,
        orderId: any
    ) => {
        const responseArr: any = [];
        Object.keys(shippedQuantity).map(function (key) {
            responseArr.push({
                orderId: orderId,
                productCode: key,
                shipmentDate: shipmentDate,
                carrier: carrier,
                shippedQuantity: shippedQuantity[key].toString(),
                trackingId: trackingId,
            });
        });
        const one = new Array(shipProduct.length).fill(false);
        one[productIndex] = true;
        setVadFlag(one);
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = responseArr;
            const isHitDummyURL = false;
            new ShipmentDataService(payload, isHitDummyURL)
                .confirmShipmentData()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    confirmedData("shipped");
                    const shipmentArray = confirmShipmentModel;
                    shipmentArray[productIndex] = true;
                    setConfirmShipmentModel(shipmentArray);
                    setOpenEditShipmentModal(true);
                    setOpenShipmentModal(false);
                    setShipmentCompleted(true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    // ***** disable confirmed shipment model  ***** //
    const disableConfirmShipment = (itemProduct: number) => {
        let total = 0;
        for (const [key, value] of Object.entries(quantity?.product[itemProduct])) {
            total = total + Number(value);
        }
        if (total == 0) {
            return true;
        } else {
            return false;
        }
    };

    // ***** disable confirmed shipment model  ***** //
    const disableEditShipment = (itemProduct: number) => {
        let total = 0;
        for (const [key, value] of Object.entries(quantity?.product[itemProduct])) {
            total = total + Number(value);
        }
        if (total == 0) {
            return true;
        } else {
            return false;
        }
    };

    // const handleSlip = (orderId: any) => {
    //     const orderIdData = [];
    //     orderIdData.push(orderId);
    //     dispatch(commonActions.SAVE_ORDER_ID(orderIdData));
    //     window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
    // };

    const editShipment = (productIndex: number) => {
        const updateVadFlag = new Array(vadFlag.length).fill(false);
        updateVadFlag[productIndex] = true;
        setVadFlag(updateVadFlag);
        const model = new Array(confirmShipmentModel.length).fill(true);
        model[productIndex] = false;
        setConfirmShipmentModel(model);
        setOpenShipmentModal(false);
    };

    //======Cancel order page function============
    const handleCancelOrder = () => {
        setCancelOrderModel(true);
        setOpenShipmentModal(false);
        scrollIntoView("cancel_order");

    };

    const handleRefund = (id: number) => {
        navigate(`${ROUTES.REFUND_ORDER}/${id}`);
    };


    // ***** Admin Note Get Function  ***** //
    const adminNoteGet = () => {
        const orderID = params.id;
        const payload = {};
        const isHitDummyURL = false;
        new adminNoteService(payload, isHitDummyURL)
            .adminNoteGet(orderID)
            .then((res) => {
                setAdminNote(res[0].adminNote);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    // ***** Admin Note Post Function  ***** //
    const adminNotePost = () => {
        if (noteValidator.current.allValid()) {
            handleSubmit();
        }
        else {
            noteValidator.current.showMessages();
        }
    };

    const handleSubmit = () => {
        handleLoader(true);
        const orderID = params.id;
        const payload = { orderId: orderID, note: adminNote };
        const isHitDummyURL = false;
        new adminNoteService(payload, isHitDummyURL)
            .AdminNotePost()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res, true);
            })
            .catch((err: any) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    // ***** Seller Memo Post Function  ***** //
    const handleSellerMemo = () => {
        if (memoValidator.current.allValid()) {
            handleLoader(true);
            const orderID = params.id;
            const payload = { orderId: orderID, memo: sellerMemo };
            const isHitDummyURL = false;
            new sellerMemoService(payload, isHitDummyURL)
                .SellerMemoPost()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    confirmedData();
                })
                .catch((err: any) => {
                    showSnackbar(err?.message, false);
                    setSkeleton(false);
                    handleLoader(false);
                });
        }
        else {
            memoValidator.current.showMessages();
        }
    };

    const singleOrderPackingSlip = async(orderId:any) => {
        handleLoader(true);
        await new packagingSlipService().refreshAccessToken();
        const selectedOrders = orderId.toString();
        const screenWidth = window.screen.width;
        const payload = {};
        const isHitDummyUrl = false;
        new packagingSlipService(payload, isHitDummyUrl)
            .packingSlip(
                selectedOrders
            )
            .then(async (res: any) => {
                const pdfWindow = window.open(res, "", `width=1200,height=1000,left=${screenWidth - 1000}`);
                pdfWindow?.document.close();
                pdfWindow?.focus();
                setTimeout(() => {
                    pdfWindow?.print();
                }, 2000);
                handleLoader(false);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };


    return (
        <>
            <HeaderTitle title={t("oms.order_details")} />

            <Container className="oms-Shipment-table">
                {skeleton ? (
                    <Skeleton varient="rectangular" width={0} height={253} count="10" />
                ) : (
                    <>
                        <Grid container className="shipment-detail" sx={{ mb: 4 }}>
                            <Grid item lg="auto" sx={{ mx: 1 }}>
                                <Typography component="h2" className="text_wrap_data">
                                    {t("oms.order_id")} &#x3A; {confirmShipmentData?.orderId}
                                </Typography>
                                <Typography component="h2" className="text_wrap_data">
                                    {t("oms.purchase_date")} &#x3A; {confirmShipmentData?.orderDate}
                                </Typography>


                            </Grid>
                        </Grid>
                        <Grid container sx={{ mb: 3 }}>
                            <Grid item lg={6}>
                                <Button
                                    sx={{ mr: 2, mb: 1 }}
                                    onClick={handleOrderList}
                                    variant="contained"
                                >
                                    {t("oms.back_to_order_list")}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                sx={{ display: "flex", mb: 1 }}
                                justifyContent="right"
                            >
                                {prepareToShipment ? (

                                    <CommonButton route={window.location.pathname} functionToExecute={() => handlePrepareShipment(confirmShipmentData?.orderId)} className="" btnName={t("oms.preparing_for_shipment")} disableCondition={false} />

                                // <Button
                                //     // sx={{ mr: 1 }}
                                //     variant="contained"
                                //     onClick={() =>
                                //         handlePrepareShipment(confirmShipmentData?.orderId)
                                //     }
                                // >
                                //     {t("oms.preparing_for_shipment")}
                                // </Button>
                                ) : null}

                                {
                                    confirmShipmentData?.status == OMS_CONSTANTS.STATUS_PREPARE_TO_SHIP || confirmShipmentData?.status == OMS_CONSTANTS.STATUS_SHIPPED ?
                                        (<Box>
                                            <DownloadButton route={window.location.pathname} functionToExecute={() =>singleOrderPackingSlip(confirmShipmentData?.orderId)} className=""
                                                btnName={t("packaging_slip.print_the_delivery_note")} disableCondition={false} />
                                        </Box>) : null
                                }


                                {confirmShipmentData?.status == 6 ? (
                                    <CommonButton route={window.location.pathname} functionToExecute={() => handleRefund(confirmShipmentData?.orderId)} className="background_color_red margin_left" btnName={t("oms.refund_order")} disableCondition={false} />
                                    // <Button
                                    //     variant="contained"
                                    //     color="error"
                                    //     sx={{ ml: 2 }}
                                    //     onClick={() => handleRefund(confirmShipmentData?.orderId)}
                                    // >
                                    //     {t("oms.refund_order")}
                                    // </Button>
                                ) : (
                                    confirmShipmentData?.sellerCancelableFlag == 0 || confirmShipmentData?.status == 9 || confirmShipmentData?.cancelFlag == false ? null :
                                        <CommonButton route={window.location.pathname} functionToExecute={() => handleCancelOrder()} className="background_color_red margin_left" btnName={t("oms.cancel_order")} disableCondition={false} />
                                // <Button
                                //     variant="contained"
                                //     color="error"
                                //     sx={{ ml: 2 }}
                                //     onClick={() => handleCancelOrder()}
                                // >
                                //     {t("oms.cancel_order")}
                                // </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Box className="ship_card">
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography component="h4">{t("oms.ship_to")}</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Grid>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingPostalCode}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingPrefecture}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingAddress1}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingAddress2}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingAddress3}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {confirmShipmentData?.shippingSurname}   {confirmShipmentData?.shippingFirstName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography>{t("oms.contact_buyer")} : </Typography>
                                                </Grid>

                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography className="text_wrap_data">{`${confirmShipmentData?.ordererName}  ${confirmShipmentData?.nameOfOrderer} `}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography>{t("oms.phone")} : </Typography>
                                                </Grid>

                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography className="text_wrap_data">{confirmShipmentData?.ordererPhoneNumber}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography>{t("oms.email")} : </Typography>
                                                </Grid>

                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Typography className="text_wrap_data">{confirmShipmentData?.ordererEmail}</Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>


                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <Box className="confirm_card">
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography component="h4" className="text_wrap_data">
                                                {t("oms.order_sales")}
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item lg={7} md={8} sm={8} xs={8}>
                                                    <Typography >{t("oms.products_subtotal")} :</Typography>
                                                </Grid>
                                                <Grid item lg={5} md={4} sm={4} xs={4} >
                                                    <Typography className="flex-end">
                                                        &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalProductPrice)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container >
                                                <Grid item lg={8} md={8} sm={8} xs={8}>
                                                    <Typography className="text_wrap_data">{t("oms.individual_shipping")} :</Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={4} >
                                                    <Typography className="flex-end">
                                                        &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalShippingPrice)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {confirmShipmentData?.totalRefundAmount !== undefined ?
                                                <>
                                                    <Grid container >
                                                        <Grid item lg={7} md={8} sm={8} xs={8}>
                                                            <Typography className="text_wrap_data">{t("oms.refund_total")} :</Typography>
                                                        </Grid>
                                                        <Grid item lg={5} md={4} sm={4} xs={4} >

                                                            <Typography className="flex-end refund_order_text_color">
                                                                -&yen; {new Utility().numberWithCommas((-confirmShipmentData?.totalRefundAmount).toString())}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container >
                                                        <Grid item lg={7} md={8} sm={8} xs={8}>
                                                            <Typography className="text_wrap_data fw">{t("oms.total")} :</Typography>
                                                        </Grid>
                                                        <Grid item lg={5} md={4} sm={4} xs={4} >

                                                            <Typography className="flex-end fw">
                                                                &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalOrderAmount + confirmShipmentData?.totalRefundAmount)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                :
                                                <Grid container >
                                                    <Grid item lg={7} md={8} sm={8} xs={8}>
                                                        <Typography className="text_wrap_data fw">{t("oms.total")} :</Typography>
                                                    </Grid>
                                                    <Grid item lg={5} md={4} sm={4} xs={4} >
                                                        <Typography className="flex-end fw">
                                                            &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalOrderAmount)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid container>
                                                <Grid item lg={7} md={8} sm={8} xs={8}>

                                                    <Typography className="text_wrap_data">{t("oms.total_point_awarded")} :</Typography>
                                                </Grid>
                                                <Grid item lg={5} md={4} sm={4} xs={4} >
                                                    <Typography className="flex-end">
                                                        {new Utility().numberWithCommas(confirmShipmentData?.totalPointAwarded)}{t("oms.points")}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                            {confirmShipmentData?.totalRefundPoints !== undefined ?
                                                <Grid container>
                                                    <Grid item lg={7} md={8} sm={8} xs={8}>
                                                        <Typography className="text_wrap_data">{t("oms.total_returned_points")} :</Typography>
                                                    </Grid>
                                                    <Grid item lg={5} md={4} sm={4} xs={4} >
                                                        <Typography className="flex-end">
                                                            {new Utility().numberWithCommas(confirmShipmentData?.totalRefundPoints == 0 ? "0" : ((-confirmShipmentData?.totalRefundPoints).toString()))}{t("oms.points")}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <Box className="ship_card">
                                    <Typography component="h4" sx={{ mb: 2 }} >{t("oms.seller_memo")}</Typography>
                                    <TextareaAutosize
                                        className="textarea"
                                        maxRows={6}
                                        minRows={6}
                                        name="sellerMemoMsg"
                                        value={loggedInUserType == CONSTANTS.USER_TYPE_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? "" : sellerMemo}
                                        onChange={(e) => {
                                            setSellerMemo(e.target.value);
                                        }}
                                    />
                                    {memoValidator.current.message("sellerMemoMsg", sellerMemo, [
                                        { maxChar2000Validation: 2000 }
                                    ])}
                                    <Box sx={{ float: "right", mr: 1 }}>
                                        <CommonButton route={window.location.pathname} functionToExecute={() => handleSellerMemo()} className="textform_capitalize" btnName={t("oms.save")} disableCondition={loggedInUserType == CONSTANTS.USER_TYPE_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN} />
                                        {/* <Button
                                            variant="contained"
                                            sx={{textTransform: "capitalize"}}
                                            onClick={()=>handleSellerMemo()}
                                            disabled={loggedInUserType == CONSTANTS.USER_TYPE_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN}

                                        >
                                            {t("oms.save")}
                                        </Button> */}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 5, mb: 5 }}>
                            <Grid item lg={12}>
                                <CommonButton route={window.location.pathname} functionToExecute={() => handleOrderShipment(confirmShipmentData?.updatedAt, confirmShipmentData?.sellerCancelableFlag)} className="" btnName={t("oms.to_confirm_shipment")} disableCondition={confirmShipmentData?.status != "5"} />
                                {/* <Button
                                    variant="contained"
                                    onClick={()=>handleOrderShipment(confirmShipmentData?.updatedAt, confirmShipmentData?.sellerCancelableFlag)}
                                    // onClick={() => handleShipment(confirmShipmentModel)}
                                    disabled={confirmShipmentData?.status !== "5"}
                                >
                                    {t("oms.to_confirm_shipment")}
                                </Button> */}
                            </Grid>
                        </Grid>


                        <Grid container className="shipment_product_table">
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="table_head">
                                            <TableCell>
                                                <Box>
                                                    {t("oms.status")}
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                <Box>
                                                    {t("oms.image")}
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                <Box>
                                                    {t("oms.product_information")}
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                <Box>
                                                    {t("oms.more_information")}
                                                </Box>
                                            </TableCell>

                                            <TableCell className="text_right">
                                                <Box>{t("oms.quantity")}</Box>
                                            </TableCell>

                                            <TableCell className="text_right">
                                                <Box>
                                                    {t("oms.unit_price")}
                                                </Box>
                                            </TableCell>

                                            <TableCell className="text_right">
                                                <Box>
                                                    {t("oms.process")}
                                                </Box>
                                            </TableCell>

                                            <TableCell className="text_right">
                                                <Box>
                                                    {t("oms.shipping_fee")}
                                                </Box>
                                            </TableCell>

                                            <TableCell className="text_right">
                                                <Box>
                                                    {t("oms.sales")}
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="shipment-product-detail">
                                        {productSummary[0]?.products?.map(
                                            (item: any, index: number) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Button
                                                                variant="outlined"
                                                                sx={{ wordBreak: "keep-all" }}
                                                            >
                                                                {confirmShipmentData?.status == 6
                                                                    ? t("oms.shipped")
                                                                    : confirmShipmentData?.status == 9
                                                                        ? t("oms.cancel")
                                                                        : confirmShipmentData?.status == 5
                                                                            ? t("oms.preparing_for_shipment")
                                                                            : t("oms.unshipped")}
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid sx={{ mr: 2 }}>
                                                                <Box
                                                                    component="img"
                                                                    sx={{ width: 100 }}
                                                                    src={item?.image}
                                                                    alt="product image"
                                                                />
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="shipment_product_table_box">
                                                                <Grid>
                                                                    <Typography >{t("oms.product_name")} : {item?.productName}</Typography>
                                                                    <Typography >
                                                                        {t("oms.product_code")} : {item?.productCode}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {t("oms.cp_id")} : {item?.cainzProductCode}
                                                                    </Typography>
                                                                </Grid>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {item?.productDeliveryDate}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="text_right">
                                                            <Typography>
                                                                {item?.salesVolume}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="text_right" >
                                                            <Typography> &yen; {new Utility().numberWithCommas(item?.productPriceIncludingTax)}</Typography>
                                                        </TableCell>
                                                        <TableCell className="text_right">
                                                            <Typography>
                                                                &yen;{" "}
                                                                {new Utility().numberWithCommas(item?.productPriceIncludingTaxSubtotal)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="text_right" >
                                                            <Typography>  &yen; {new Utility().numberWithCommas(item?.individualShippingFeeIncludingTax)}</Typography>
                                                        </TableCell>
                                                        <TableCell className="text_right">
                                                            <Typography>&yen; {new Utility().numberWithCommas((item?.productPriceIncludingTaxSubtotal) + (item?.individualShippingFeeIncludingTax))}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>



                        {/* Shipment Products */}
                        {shipProduct?.map((itemProduct: any, shipProductIndex: number) => {
                            return (
                                <div key={shipProductIndex}>
                                    {
                                        shippedPackages.includes(itemProduct) ?
                                            confirmShipmentModel[itemProduct] ? (
                                                <ShippedPackage
                                                    key={shipProductIndex}
                                                    productSummary={productSummary}
                                                    confirmShipmentModel={confirmShipmentModel}
                                                    editShipment={editShipment}
                                                    // handleSlip={handleSlip}
                                                    confirmShipmentData={confirmShipmentData}
                                                    itemProduct={itemProduct}
                                                    shipProductIndex={shipProductIndex}
                                                    validator={validator}
                                                />
                                            ) : (
                                                <ConfirmOrder
                                                    key={shipProductIndex}
                                                    openShipmentModal={openShipmentModal}
                                                    itemProduct={itemProduct}
                                                    shipProduct={shipProduct}
                                                    deleteShipment={deleteShipment}
                                                    productSummary={productSummary}
                                                    quantity={quantity}
                                                    handleChange={handleChange}
                                                    setProductQuantityOption={setProductQuantityOption}
                                                    handleChanges={handleChanges}
                                                    shipDate={shipDate}
                                                    carrier={carrier}
                                                    others={others}
                                                    trackingId={trackingId}
                                                    confirmShipmentData={confirmShipmentData}
                                                    flag={flag}
                                                    // handleProductChanges={handleProductChanges}
                                                    handleFinalShipment={handleFinalShipment}
                                                    productCount={itemProduct}
                                                    disableConfirmShipment={disableConfirmShipment}
                                                    confirmShipmentModel={confirmShipmentModel}
                                                    isEdit={true}
                                                    showSnackbar={showSnackbar}
                                                    handleLoader={handleLoader}
                                                    setConfirmShipmentModel={setConfirmShipmentModel}
                                                    setOpenEditShipmentModal={setOpenEditShipmentModal}
                                                    setOpenShipmentModal={setOpenShipmentModal}
                                                    confirmedData={confirmedData}
                                                    validator={validator}
                                                    disableEditShipment={disableEditShipment}
                                                    vadFlag={vadFlag}
                                                />
                                            )
                                            : openShipmentModal ? (
                                                <>
                                                    <ConfirmOrder
                                                        key={shipProductIndex}
                                                        openShipmentModal={openShipmentModal}
                                                        itemProduct={itemProduct}
                                                        shipProduct={shipProduct}
                                                        deleteShipment={deleteShipment}
                                                        productSummary={productSummary}
                                                        quantity={quantity}
                                                        handleChange={handleChange}
                                                        setProductQuantityOption={setProductQuantityOption}
                                                        handleChanges={handleChanges}
                                                        shipDate={shipDate}
                                                        carrier={carrier}
                                                        others={others}
                                                        trackingId={trackingId}
                                                        confirmShipmentData={confirmShipmentData}
                                                        flag={flag}
                                                        // handleProductChanges={handleProductChanges}
                                                        handleFinalShipment={handleFinalShipment}
                                                        productCount={itemProduct}
                                                        disableConfirmShipment={disableConfirmShipment}
                                                        isEdit={false}
                                                        confirmedData={confirmedData}
                                                        validator={validator}
                                                        disableEditShipment={disableEditShipment}
                                                        vadFlag={vadFlag}
                                                    />
                                                    <Grid id="confirm_shipment"></Grid>
                                                </>
                                            ) : null
                                    }
                                </div>
                            );

                        })}
                        {cancelOrderModel ? (
                            <CancelOrder
                                productSummary={productSummary}
                                itemProduct={0}
                                handleChange={handleChange}
                                setProductQuantityOption={setProductQuantityOption}
                                quantity={quantity}
                                showSnackbar={showSnackbar}
                                setshowReasonModel={setshowReasonModel}
                                setCancelButtonVisible={setCancelButtonVisible}
                                setCancelOrderModel={setCancelOrderModel}
                                cancelOrderId={cancelId}
                                handleLoader={handleLoader}
                                confirmedData={confirmedData}
                            />
                        ) : null}
                        <Grid id="cancel_order"></Grid>
                    </>
                )}
            </Container>

            {loggedInUserType == CONSTANTS.USER_TYPE_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? (confirmShipmentData?.status == 6) ? (
                <Container>
                    <Grid container sx={{ mt: 2 }}>
                        <HeaderTitle title={t("oms.admin_note")} />
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextareaAutosize
                                className="textarea"
                                maxRows={10}
                                minRows={10}
                                name="reasonMessage"
                                style={{ width: "100%" }}
                                value={adminNote ?? ""}
                                onChange={(e) => {
                                    setAdminNote(e.target.value);
                                }}
                            />
                            {noteValidator.current.message("reasonMessage", adminNote, [
                                "required",
                                { maxChar1000Validation: 1000 }
                            ])}

                            <Box>
                                <CommonButton route={window.location.pathname} functionToExecute={adminNotePost} className="" btnName={t("oms.save")} disableCondition={false} />
                                {/* <Button variant="contained" onClick={adminNotePost}>
                                    {t("oms.save")}
                                </Button> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            ) : null : null}
        </>
    );
};

export default OMSConfirmShipment;
