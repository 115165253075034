export const mockAPIPath = {
    EXHIBITOR_USERS : "./data/exhibitionUsers.json",
    DELETE_EXHIBITOR: "./data/exhibitionUsersDelete.json",
    EDITED_EXHIBITOR: "./data/exhibitionUserDetails.json",
    INVITE_USER: "",
    VERIFY_INVITE_LINK: "",
    UPDATE_USER: "./data/updateUser.json",
    UPDATE_EMAIL: "./data/updateUser.json"
};

export const serverAPIPath = {
    EXHIBITOR_USER_DETAILS: "/user/exhibitors/",
    EXHIBITOR_USERS : "/user/exhibitors",
    DELETE_INVITED_EXHIBITOR: "/invite/user-list/",
    DELETE_EXHIBITOR: "/user/exhibitors/",
    INVITE_USER: "/invite/user",
    VERIFY_INVITE_LINK: "/invite/verify-invite-link/",
    UPDATE_NAME: "/user/edit-profile",
    UPDATE_USER: "",
    UPDATE_EMAIL: "/user/email",
    EMAIL_VERIFY: "/user/is-email-available"
};