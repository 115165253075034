import { Box, Button, Container } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

function RichTextEditor(props: any) {

    const { t } = useTranslation();
    
    const { data, saveData } = props;
    const [value, setValue] = useState(data);

    const toolbarOptions = [
        { "header": [1, 2, 3, 4, 5, 6, false] },
        "bold", "italic", "underline", "strike", "blockquote",  
        { "list": "ordered"}, 
        { "list": "bullet" },
        { "script": "sub"}, 
        { "script": "super" }, 
        { "color": [] }, { "background": [] },
        { "font": [] },
        { "align": [] }
    ];

    const handleChange = (data: any) => {
        setValue(data);
    };

    const handleClick = () => {
        saveData(value);
    };

    const handleBlur = () => {
        const myElement = document.getElementById("rte");
        myElement?.addEventListener("paste", e => e.preventDefault());
    };

    return (
        <>
            <Container sx={{ backgroundColor: "#f7f7f7", p: 4 }}>
                <Box
                    sx={{
                        background: "white",
                        // border: "1px solid gray",
                        pt: 2,
                        pb: 10,
                        px: 2,
                        minHeight: "20vh",
                    }}
                    onDrop={(e) => e.preventDefault()}
                >
                    <ReactQuill 
                        id="rte" 
                        modules={{ toolbar: toolbarOptions,
                            clipboard: {
                                matchVisual: false,
                            } }} 
                        className="rte-style" 
                        value={value} 
                        onChange={handleChange} 
                        onBlur={handleBlur}
                    />
                </Box>
            </Container>
            <Button sx={{ my: 2, px: 3, py: 1 }} onClick={handleClick}>
                {t("profile.save")}
            </Button>
        </>
    );
}

export default RichTextEditor;