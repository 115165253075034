import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import BillingInformationServices from "../services/BillingInformation";
import { useAppSelector } from "../../../redux/hooks";
import GoogleMapServices from "../../../utils/GoogleMapServices";
import CommonButton from "../../common/Button";

const BillingInformation = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const billingInfo = useAppSelector(
        (state) => state.user.userDetails?.store?.[0]?.billingInfo?.[0]
    );

    const [zipcode, setZipcode] = useState(billingInfo?.zipcode?.toString());
    const [city, setCity] = useState(billingInfo?.city);
    const [state, setState] = useState(billingInfo?.state);
    const [streetNumber, setStreetNumber] = useState(billingInfo?.streetNumber);
    const [address, setAddress] = useState(billingInfo?.address);
    const [billingAddress, setBillingAddress] = useState(billingInfo?.landmark);
    // const [fullWidthL, setFullWidthL] = useState<number>(0);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const [, forceUpdate] = useState(0);

    useEffect(() => {
        forceUpdate(1);
    });

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                maxChar200Validation: {
                    message: t("registerpage.max_char", { size: 200 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar100Validation: {
                    message: t("registerpage.max_char", { size: 100 }).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar10Validation: {
                    message: t("registerpage.max_char", { size:10}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar20Validation: {
                    message: t("registerpage.max_char", { size:20}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                corporateNumber: {
                    message: t("registerpage.min_char", { size: 13 }),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                phoneNumber: {
                    message: t("registerpage.min_11_max_12_char", {
                        min: 11,
                        max: 12,
                    }).toString(),
                    rule: (value: number, params: Array<number>) => {
                        return params.includes(value.toString().length);
                    },
                },

                SingleByteNumberzipcode: {
                    message: t("registerpage.min_char", { size: 7 }),
                    rule: (value) => isSingleByte(value),                
                },
                zipcode: {
                    message: t("registerpage.min_char", { size: 7 }),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
            },
        })
    );

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };

    const saveBillingInfo = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = {
                zipcode,
                state,
                city,
                streetNumber,
                address,
                landmark: billingAddress,
            };
            const isHitDummyURL = false;
            new BillingInformationServices(payload, isHitDummyURL)
                .saveBillingInfo(billingInfo?.id ?? null)
                .then((res: any) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.PROFILE);
                })
                .catch((err: any) => {
                    handleLoader(true);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };



    const getStateAndCityFromZipCode = (postalCode: string) => {
        setZipcode(postalCode);
        // validator.current.hideMessageFor("zip_code");
        new GoogleMapServices().getAddressByZipCode(parseInt(postalCode!), i18n.language).then(res => {
            if(res[0]){
                const { address_components} = res[0];
                for ( let i = 0; i < address_components.length; i++){
                    if(address_components[i].types.includes("sublocality")){
                        setStreetNumber(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("locality")){
                        setCity(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("administrative_area_level_1")){
                        setState(address_components[i].long_name);
                    }
                }
            }
        }).catch(err => {
            showSnackbar(err?.message, false);
        });
    };
    

    return (
        <>
            <div className="main">
                {/* Page header title */}
                <HeaderTitle title={t("store_details.billing_information")} />
                {/* Page header title */}


                <Container>
                    {/* Billing information starts here */}
                    <Box className="ragistration_box">
                        <Typography variant="h6">
                            {t("store_details.billing_information")}
                        </Typography>
                        <Box className="bg-gray">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={zipcode || ""}
                                        name="zip_code"
                                        label={t("store_details.zip_code")}
                                        required
                                        inputProps={{ maxLength: 7 }}
                                        onChange={(e) => {
                                            getStateAndCityFromZipCode(e.target.value);
                                        }}                  
                                        onInput={(e) => handleInputType(e)}                  
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("zip_code", zipcode, [
                                        "required",
                                        {SingleByteNumberzipcode: 7},
                                        { zipcode: 7 },
                                    ])}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={state || ""}
                                        name="state"
                                        label={t("store_details.state")}
                                        required
                                        onChange={(e) => {
                                            setState(e.target.value);
                                            validator.current.hideMessageFor("state");
                                        }}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("state", state, ["required", {maxChar10Validation:10}])}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={city || ""}
                                        name="city"
                                        label={t("store_details.city")}
                                        required
                                        onChange={(e) => {
                                            setCity(e.target.value);
                                            validator.current.hideMessageFor("city");
                                        }}                                    
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("city", city, ["required", {maxChar20Validation:20}])}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={streetNumber || ""}
                                        name="street_address"
                                        label={t("store_details.street_address")}
                                        required
                                        onChange={(e) => {
                                            setStreetNumber(e.target.value);
                                            validator.current.hideMessageFor("street_address");
                                        }}                                    
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("street_address", streetNumber, [
                                        "required", {maxChar100Validation: 100 }
                                    ])}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={address || ""}
                                        name="complete_address"
                                        label={t("store_details.address_building_name")}
                                        // required
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                            validator.current.hideMessageFor("complete_address");
                                        }}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message(
                                        "complete_address",
                                        address,
                                        [ { maxChar100Validation:100 }]
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        name="billing_address"
                                        label={t("store_details.billing_address")}
                                        required
                                        value={billingAddress || ""}
                                        onChange={(e) => {
                                            setBillingAddress(e.target.value);
                                            validator.current.hideMessageFor("billing_address");
                                        }}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message(
                                        "billing_address",
                                        billingAddress,
                                        ["required", { maxChar200Validation: 200 }]
                                    )}
                                </Grid>
                            </Grid>

                        </Box>
                        {/* Billing information ends here */}

                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <CommonButton route={window.location.pathname} functionToExecute={saveBillingInfo} className="wd_100 padding_1rem"  btnName={t("store_details.save")} disableCondition={false}/>
                                {/* <Button fullWidth onClick={saveBillingInfo}>
                                    {t("store_details.save")}
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Box>
                    {/*Payee Information starts here */}
                </Container>
            </div>
        </>
    );
};

export default BillingInformation;
