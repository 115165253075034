import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button
} from "@mui/material";

import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { SnackbarAlert } from "../SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import ExhibitionUserListService from "../../ExhibitionUser/service/ExhibitionUserListService";
import { snackbarActions } from "../../../redux/features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../redux/hooks";

const InviteUserSeller = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    {
    /* Language translate function*/
    }
    const [t] = useTranslation();
    {
    /* Language translate function*/
    }

    const dispatch = useAppDispatch();

    const [email, setEmail] = useState("");
    const alertMessage = "";
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const error = "";
    // const [url, setUrl] = useState("");
    // const [modalOpen, setModalOpen] = useState(false);
    // const [copyUrl, setCopyUrl] = useState("");
    const userType = parseInt(localStorage.getItem("userType") ?? "");

    // validation
    const [, forceUpdate] = useState(0);


    useEffect(() => {   
        dispatch(snackbarActions.HIDE_SNACKBAR(false));
    },[]);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("invitation_page.please_enter_valid_email"),
                max: t("invitation_page.max_80_char"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "email" ? setEmail(value) : null;
        validator.current.hideMessageFor(name);
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    //validation

    // useEffect(() => {
    //     if (url != "") {
    //         // const domain = location.protocol + "//" + location.host;
    //         // const domain = userType==1 ? "https://admin.evssolution.co.in" : "https://seller.evssolution.co.in";
    //         const copyUrl = url;
    //         setCopyUrl(copyUrl);
    //         setModalOpen(!modalOpen);
    //     }
    // }, [url]);
    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email: email, userType: userType };
            const isHitDummyURL = false;
            new ExhibitionUserListService(payload, isHitDummyURL)
                .inviteExhibitionUser()
                .then((res) => { 
                    handleLoader(false);
                    setSuccessAlert(true);
                    showSnackbar(res.message, true);
                    // setUrl(res.data.url);
                    setTimeout(()=>close(), 1000);
                })
                .catch((err) => {
                    handleLoader(false);
                    // const { success, message } = err.response.data;
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };
    // const closeModal = () => {
    //     setModalOpen(false);
    //     setUrl("");
    // };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    // const handleCloseTab = async() => {
    //     await navigator.clipboard.writeText(copyUrl).then(() => {
    //         // close();
    //     });
    // };

    return (
        <>
            {/* ForgotPassword page starts here */}
            <Grid>
                <Container>
                    <Grid container spacing={4}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img className="logo-img" alt="cainz"src={imagePath("logo-large.png")}  />
                            <Box className="logo-content">
                                <Grid item xs={12} md={12}>
                                    <Grid sx={{ mb: 2 }}>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            value={email}
                                            name="email"
                                            label={t("invitation_page.email_address")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={error ? true : false}
                                            helperText={error}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("email", email, [
                                            "required",
                                            { max: 80 },
                                            "email",
                                        ])}
                                    </Grid>
                                    <Button fullWidth onClick={handleSubmit}>
                                        {t("invitation_page.invite")}
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                    <SnackbarAlert
                        openSnackBar={openSnackBar}
                        message={alertMessage}
                        success={successAlert}
                        handleCloseSnackBar={handleCloseSnackBar}
                    />
                </Container>
            </Grid>
            {/* <Modal
                open={modalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="center-model">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    ></Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {copyUrl}
                    </Typography>
                    <Button
                        sx={{ mt: 2 }}
                        onClick={handleCloseTab}
                    >
                        {t("invitation_page.Please_copy_this_link_to_register")}
                    </Button>
                </Box>
            </Modal> */}
            {/* ForgotPassword page ends here */}
        </>
    );
};

export default InviteUserSeller;
