import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import snackbarReducer from "./features/snackbar/snackbarSlice";
import loaderReducer from "./features/loader/loaderSlice";
import commonReducer from "./features/common/commonSlice";
import permissionReducer from "./features/roleBasedPermission/permissionSlice";
import newPermissionReducer from "./features/newRoleBasedPermission/newPermissionSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// import {createLogger} from "redux-logger";

// const logger = createLogger();

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["loaderss"],
};

const rootReducer = combineReducers({
    user: userReducer,
    snackbar: snackbarReducer,
    loaderss : loaderReducer,
    common: commonReducer,
    permission: permissionReducer,
    newPermission: newPermissionReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);