export const mockAPIPath = {
    ORDER_INFO: "./data/orderInfo.json",
    PENDING_ORDER_DETAIL: "./data/pandingOrder.json",
    UNSHIPPED_ORDER_DETAIL: "./data/unshippedOrder.json",
    SHIPPED_ORDER_DETAIL: "./data/shippedOrder.json",
    CANCELED_ORDER_DETAIL: "./data/canceledOrder.json",
    RETURN_ORDER_DETAIL: "./data/returnOrder.json",
    COMPLETED_ORDER_DETAIL: "./data/completedOrder.json",
    DECLINE_ORDER: "./data/declineOrder.json",
    LOGISTIC_PROVIDOR: "./data/logisticProvider.json",
};

export const serverAPIPath = {
    ORDER_INFO: "./data/orderInfo.json",
    PENDING_ORDER_DETAIL: "./data/pandingOrder.json",
    UNSHIPPED_ORDER_DETAIL: "./data/unshippedOrder.json",
    SHIPPED_ORDER_DETAIL: "./data/shippedOrder.json",
    CANCELED_ORDER_DETAIL: "./data/canceledOrder.json",
    RETURN_ORDER_DETAIL: "./data/returnOrder.json",
    COMPLETED_ORDER_DETAIL: "./data/completedOrder.json",
    DECLINE_ORDER: "./data/declineOrder.json",
    LOGISTIC_PROVIDOR: "./data/logisticProvider.json",
   
};





