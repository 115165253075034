import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
class ShipmentDataService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async confirmShipmentData() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SHIPMENT_DATA}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SHIPMENT_DATA}`,
                this.payload,
                this.headers
            ).post();
        if (response?.data?.success) {
            const ShipmentData = response?.data?.message;
            return ShipmentData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async editShipmentData() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.EDIT_SHIPMENT_DATA}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.EDIT_SHIPMENT_DATA}`,
                this.payload,
                this.headers
            ).put();
        if (response?.data?.success) {
            const ShipmentData = response?.data?.message;
            return ShipmentData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async RefundOrder(page_id: number) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.REFUND_ORDER}`,
                this.payload,
                this.headers
            ).post()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.REFUND_ORDER}`,
                this.payload,
                this.headers
            ).post();
        if (response?.data?.success) {
            const ShipmentData = response?.data?.message;
            return ShipmentData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}
export default ShipmentDataService;