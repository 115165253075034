class SellerReportsModel {
    private _id!: number;
    private _uploadDateTime!: number;
    private _fileName!: string;
    private _batchId!: string;
    private _status!: number;
    private _statusText!: string;
    private _productFailedCount!: number;
   
    public static parseJson(itemBusiness: SellerReportsModel ) {
        const sellerReports: SellerReportsModel = new SellerReportsModel();
        sellerReports.id = itemBusiness.id;
        sellerReports.uploadDateTime = itemBusiness.uploadDateTime;
        sellerReports.fileName = itemBusiness.fileName;
        sellerReports.batchId = itemBusiness.batchId;
        sellerReports.status = itemBusiness.status;
        sellerReports.statusText = itemBusiness.statusText;
        sellerReports.productFailedCount = itemBusiness.productFailedCount;
        return sellerReports;
    }

    public get id() {
        return this._id;
    }
    public set id(id: number) {
        this._id = id;
    }
    public get uploadDateTime() {
        return this._uploadDateTime;
    }
    public set uploadDateTime(uploadDateTime: number) {
        this._uploadDateTime = uploadDateTime;
    }

    public get fileName() {
        return this._fileName;
    }
    public set fileName(fileName: string) {
        this._fileName = fileName;
    }

    public get batchId() {
        return this._batchId;
    }
    public set batchId(batchId: string) {
        this._batchId = batchId;
    }

    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }
    
    public get statusText() {
        return this._statusText;
    }
    public set statusText(statusText: string) {
        this._statusText = statusText;
    }
    public get productFailedCount() {
        return this._productFailedCount;
    }
    public set productFailedCount(productFailedCount: number) {
        this._productFailedCount = productFailedCount;
    }
}

export default SellerReportsModel;