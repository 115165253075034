import axios from "axios";
import CONSTANTS from "../constants/constants";
import { API } from "../config";

class GoogleMapServices {

    async getAddressByZipCode(zipcode: number, selectedLang: string) {
        const lang = selectedLang == "jp" ? "ja" : "en";
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?language=${lang}&region=${CONSTANTS.REGION}&key=${API.GOOGLE_MAP_API_KEY}&address=${zipcode}`);
        return response.data.results;
    }
}

export default GoogleMapServices;