import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";

class StoreDetailsService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async saveStoreDetails(storeID: number | null) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.SAVE_STORE_DETAILS, this.payload,this.headers).get() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SAVE_STORE_DETAILS}${storeID}`, this.payload,this.headers).put();
        let saveStoreDetails: string;
        if(response?.data?.success) {
            saveStoreDetails = response?.data?.message;
            return saveStoreDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}
export default StoreDetailsService;
