import React from "react";
import SellerPassword from "./SellerPassword";

const Register = (props:any) => {
    const {login,handleLoader,showSnackbar} = props;
    return (
        <>
            <SellerPassword login={login} handleLoader={handleLoader} showSnackbar={showSnackbar} />
        </>
    );
};

export default Register;
