class LoginModel {
    private _name!: string;
    private _email!: string;
    private _userType!: number;
    private _accessToken!: string;
    private _isStoreCreated!: boolean;
    private _storeId!: number;
    private _storeName!: string;
    private _storeCode!: number;
    private _perPageLimit!: number;
    private _roleId!: number;

    public static parseJson(itemUser: LoginModel) {
        const sellerUsers: LoginModel = new LoginModel();
        sellerUsers.name = itemUser.name;
        sellerUsers.email = itemUser.email;
        sellerUsers.userType = itemUser.userType;
        sellerUsers.accessToken = itemUser.accessToken;
        sellerUsers.isStoreCreated = itemUser.isStoreCreated;
        sellerUsers.storeId = itemUser.storeId;
        sellerUsers.storeName = itemUser.storeName;
        sellerUsers.storeCode = itemUser.storeCode;
        sellerUsers.perPageLimit = itemUser.perPageLimit;
        sellerUsers.roleId = itemUser.roleId;
        return sellerUsers;
    }

    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
    public get email() {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }
    public get userType() {
        return this._userType;
    }
    public set userType(userType: number) {
        this._userType = userType;
    }
    public get accessToken() {
        return this._accessToken;
    }
    public set accessToken(accessToken: string) {
        this._accessToken = accessToken;
    }
    public get isStoreCreated() {
        return this._isStoreCreated;
    }
    public set isStoreCreated(isStoreCreated: boolean) {
        this._isStoreCreated = isStoreCreated;
    }
    public get storeId() {
        return this._storeId;
    }
    public set storeId(storeId: number) {
        this._storeId = storeId;
    }
    public get storeName() {
        return this._storeName;
    }
    public set storeName(storeName: string) {
        this._storeName = storeName;
    }
    
    public get storeCode() {
        return this._storeCode;
    }
    public set storeCode(storeCode: number) {
        this._storeCode = storeCode;
    }
    public get perPageLimit() {
        return this._perPageLimit;
    }
    public set perPageLimit(perPageLimit: number) {
        this._perPageLimit = perPageLimit;
    }
    public get roleId() {
        return this._roleId;
    }
    public set roleId(roleId: number) {
        this._roleId = roleId;
    }
}

export default LoginModel;