import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath , mockAPIPath } from "../config/singleProductDetailsApi";
import { shippingFeeOptionsType } from "../../../interfaces/shippingFeeOptionsInterface";
import Cookies from "../../../utils/Cookies";
import { ecCategoryOptionsType } from "../../../interfaces/ecCategoryOptions";

class SingleProductDetailsService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    // then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    // async saveSingleProductDetails() {
    //     const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
    //         await new AxiosUtils(mockAPIPath.SINGLE_PRODUCT, this.payload,this.headers).get() :
    //         await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SHIPPING_FEE_FILTER_OPTIONS}`, this.payload,this.headers).put();
    //     let saveSingleProductDetails: string;
    //     if(response?.data?.success) {
    //         saveSingleProductDetails = response?.data?.message;
    //         return saveSingleProductDetails;
    //     }
    //     else if(response?.success == false)
    //     {   
    //         throw new Error(response?.message);        
    //     }else{
    //         throw new Error(response);
    //     } 
    // }

    async ecCategoryFilterOptions() {   
        const response = await new AxiosUtils(
            `${API.BASE_URL}${serverAPIPath.EC_CATEGORY_OPTIONS}`,
            this.payload,
            this.headers
        ).get();
        if (response?.data?.success) {
            const ecCategoryOptions : ecCategoryOptionsType = response?.data?.data;
            return ecCategoryOptions;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async shippingFeeFilterOptions() {   
        const response = await new AxiosUtils(
            `${API.BASE_URL}${serverAPIPath.SHIPPING_FEE_FILTER_OPTIONS}`,
            this.payload,
            this.headers
        ).get();
        if (response?.data?.success) {
            const shippingFeeOptions : shippingFeeOptionsType = response?.data?.data;
            return shippingFeeOptions;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async saveSingleProductDetails(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.SAVE_PRODUCT_DETAILS, this.payload).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SAVE_PRODUCT_DETAILS}`, this.payload,this.headers).post();
        let isProductDetails : string;
        if(response?.data?.success==true) {
            isProductDetails = response?.data?.message;
            return isProductDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async showSingleProductDetails(id:number) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.GET_PRODUCT_DETAILS, this.payload,this.headers).get() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.GET_PRODUCT_DETAILS}/${id}`, this.payload,this.headers).get();
        let showSingleProductDetails: string;
        if(response?.data?.success) {
            showSingleProductDetails = response?.data?.data;
            return showSingleProductDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

export default SingleProductDetailsService;
