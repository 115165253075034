import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import UserDetailInterface, { userDetailsInitialValue } from "../../../interfaces/userDetailInterface";

const initialState: UserDetailInterface = userDetailsInitialValue;
    
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        CLEAR_USER_REDUX: () => initialState,
        
        USER_DETAILS: (state, action: PayloadAction<object>) => {
            state.userDetails = action.payload;
        },
        SAVE_PRIVACY_POLICY: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.privacyPolicy = action.payload;
            }
        },
        SAVE_ABOUT_US: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.aboutUs = action.payload;
            }
        },
        SAVE_SHIPPING_POLICY: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.shippingPolicy = action.payload;
            }
        },
        SAVE_FAQ: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.faq = action.payload;
            }
        },
        SAVE_CUSTOM_TITLE: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.customPolicyTitle = action.payload;
            }
        },
        SAVE_CUSTOM_DESCRIPTION: (state, action: PayloadAction<string>) => {
            if(state?.userDetails?.store?.[0].policy){
                state.userDetails.store[0].policy.customPolicyDescription = action.payload;
            }
        },
    }
});

export default userSlice.reducer;
export const userActions = userSlice.actions;