import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    Box,
} from "@mui/material";
import AuthService from "../services/AuthService";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import GoogleMapServices from "../../../utils/GoogleMapServices";

const SellerRegisterDetails = (props: any) => {
//     // language conversion js
    const { login, showSnackbar,handleLoader,userInfo } = props;
    const [t, i18n] = useTranslation();
    //     const location = useLocation();
    const navigate = useNavigate();
    //     const dispatch = useAppDispatch();

    const [companyName, setCompanyName] = useState<string>();
    const [corporateName, setCorporateName] = useState<string>();
    const [corporateNumber, setCorporateNumber] = useState<string>();
    const [mainPhoneNumber, setMainPhoneNumber] = useState<string>();
    const [postalCode, setPostalCode] = useState<string>("");
    const [state, setState] = useState<string>();
    const [city, setCity] = useState<string>();
    const [streetAddress, setStreetAddress] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [telephone, setTelephone] = useState<string>();
    const [storeName, setStoreName] = useState<string>();
    // const [userDetails, setUserDetails] = useState({});
    const [inquiryEmailAddress, setEnquiryEmailAddress] = useState<string>("");
    const [inquiryPhoneNumber, setInquiryPhoneNumber] = useState<string>("");
    const [inquiryAvailableHours, setInquiryAvailableHours] = useState<string>("");
    const [eligibleInvoiceIssueNumber, setEligibleInvoiceIssueNumber] = useState<string>("");
    // const [fullWidthL, setFullWidthL] = useState<number>(0);
    
    // const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };
    
    // validation
    const [, forceUpdate] = useState(0);
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("registerpage.please_enter_valid_email"),
                required: t("validations.this_field_cannot_be_empty"),
            },
            validators: {
                companyNameValidation: {
                    message: t("registerpage.max_char_single_byte",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                corporateNameValidation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                corporateNumberValidation: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    }   
                },
                SingleByteCorporateNumberValidation: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value) => isSingleByte(value),                  
                },
                mainPhoneNumberValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: any) => {
                        return  params.includes(value.toString().length);
                    }
                },
                SingleByteMainPhoneNumberValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),                  
                },
                postalCodeValidation: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    }
                },
                SingleBytePostalCodeValidation: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value) => isSingleByte(value),                  
                },
                streetAddressValidation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                SingleByte: {
                    message: t("registerpage.single_byte_characters"),
                    rule: (value) => isSingleByte(value),
                    
                },
                addressValidation: {
                    message: t("registerpage.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                firstNameValidation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                lastNameValidation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                telephoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: any) => {
                        return  params.includes(value.toString().length);
                    }
                },
                SingleByteTelephoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),                  
                },
                storeNameValidation: {
                    message: t("registerpage.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    }
                },
                maxChar80Validation: {
                    message: t("registerpage.max_char",{size:80}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                phoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: any) => {
                        return  params.includes(value.toString().length);
                    }
                },
                SingleBytePhoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),                  
                },
                maxChar200Validation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar20Validation: {
                    message: t("registerpage.max_char",{size:20}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar100Validation: {
                    message: t("registerpage.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar10Validation: {
                    message: t("registerpage.max_char",{size:10}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                corporateNumber: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteCorporateNumber: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value) => isSingleByte(value),                  
                },

            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
  

    // useEffect(() => {
    //     if(validator.current.fieldValid("zipcode")){
    //         getStateAndCityFromZipCode();
    //     }
    //     else {
    //         setCity("");
    //         setState("");
    //     }
    // }, [validator.current.fieldValid("zipcode")]);

    const getStateAndCityFromZipCode = (postalCode: string) => {
        setPostalCode(postalCode);
        new GoogleMapServices().getAddressByZipCode(parseInt(postalCode!), i18n.language).then(res => {
            if(res[0]){
                const { address_components} = res[0];
                for ( let i = 0; i < address_components.length; i++){
                    if(address_components[i].types.includes("sublocality")){
                        setStreetAddress(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("locality")){
                        setCity(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("administrative_area_level_1")){
                        setState(address_components[i].long_name);
                    }
                }
            }
        }).catch(err => {
            showSnackbar(err?.message, false);
        });
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };
    const handleSubmit = () => {
        if(validator.current.allValid()){
            handleLoader(true);
            const isHitDummyURL = false;
            const payload={
                "code": userInfo.code,
                "userType": userInfo.userType,
                "fullName": userInfo.fullName,
                "password": userInfo.password,
                "confirmPassword": userInfo.confirmPassword,
                "storeName": storeName,
                "companyInfo": {
                    "companyName": companyName,
                    "corporateName": corporateName,
                    "corporateNumber": corporateNumber,
                    "phoneNumber": mainPhoneNumber,
                    "zipcode": postalCode,
                    "state": state,
                    "city": city,
                    "streetNumber": streetAddress,
                    "address": address,
                    "inquiryEmailAddress":inquiryEmailAddress,
                    "inquiryPhoneNumber":inquiryPhoneNumber,
                    "inquiryAvailableHours":inquiryAvailableHours,
                },
                "productRepresentativeInfo": {
                    "name": firstName,
                    "surname": lastName,
                    "phoneNumber": telephone,
                    "eligibleInvoiceIssueNumber":eligibleInvoiceIssueNumber,
                }
            };

            
            new AuthService(payload, isHitDummyURL)
                .registerStepSecond()
                .then((res) => {
                    const { isStoreCreated, accessToken, userType, email, name ,  storeId, storeName, storeCode} = res.userDetails;
                    handleLoader(false);
                    // setUserDetails(res.isStoreRegister);
                    navigate(ROUTES.OMS);
                    login(accessToken, isStoreCreated, userType, email, name,  storeId, storeName, storeCode);
                    showSnackbar(res.isStoreRegister, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    // const { success, message } = err.response.data;
                    showSnackbar(err?.message, false);
                });
        }
        else{
            validator.current.showMessages();
        }
    };
    
    return (
        <>
            <Container>
                <Grid container>
                    {/*Logo starts here */}
                    <Grid item xs={12} md={12}>
                        <img
                            className="logo-img"
                            alt="cainz"
                            src={imagePath("logo-large.png")}
                        />
                    </Grid>
                    {/*Logo Ends here */}
                </Grid>
            </Container>

            <Container>
                {/*Company Information start here */}
                <Box className="ragistration_box">
                    <Typography variant="h6"> {t("register_seller.company_information")} </Typography>
                    <Box className="bg-gray">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="companyName"
                                    required
                                    onChange={(e) => {
                                        setCompanyName(e.target.value);
                                        // validator.current.hideMessageFor("companyName");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.company_name")}
                                />
                                {validator.current.message("companyName", companyName, ["required",{companyNameValidation:200}])}
                                {validator.current.message("companyName", companyName, [
                                    "SingleByte"
                                   
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="corporateName"
                                    required
                                    onChange={(e) => {
                                        setCorporateName(e.target.value);
                                        validator.current.hideMessageFor("corporateName");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.corporate_name")}
                                />
                                {validator.current.message("corporateName", corporateName, ["required",{corporateNameValidation:200}])}

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="string"
                                    name="corporateNumber"
                                    onChange={(e) => {
                                        setCorporateNumber(e.target.value);
                                        // validator.current.hideMessageFor("corporateNumber");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    onInput={(e) => handleInputType(e)}
                                    label={t("register_seller.corporate_number")}
                                />
                                {validator.current.message("corporateNumber", corporateNumber, [{SingleByteCorporateNumberValidation: 13},{corporateNumberValidation:13}])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="string"
                                    name="mainPhoneNumber"
                                    required
                                    onChange={(e) => {
                                        setMainPhoneNumber(e.target.value);
                                        // validator.current.hideMessageFor("mainPhoneNumber");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    onInput={(e) => handleInputType(e)}
                                    label={t("register_seller.main_phone_number")}
                                />
                                {validator.current.message("mainPhoneNumber", mainPhoneNumber, ["required",{SingleByteMainPhoneNumberValidation: [10,11]},{mainPhoneNumberValidation:[10,11]}])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    required
                                    type="string"
                                    name="zipcode"
                                    value={postalCode}
                                    onChange={(e) => {
                                        getStateAndCityFromZipCode(e.target.value);

                                        // validator.current.hideMessageFor("zipcode");
                                    }}
                                    label={t("register_seller.postal_code")}
                                    variant="outlined"
                                    className="bg-white"
                                    inputProps={{ maxLength: 7 }}
                                    onInput={(e) => handleInputType(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("zipcode", postalCode, ["required",{SingleBytePostalCodeValidation: 7},{postalCodeValidation:7}])}
                            </Grid>
                            
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="state"
                                    required
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.state")}
                                    value={state || ""}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                        validator.current.hideMessageFor("state");
                                    }}
                                />
                                {validator.current.message("state", state, ["required", {maxChar10Validation:10}])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="city"
                                    required
                                    label={t("register_seller.city")}
                                    value={city || ""}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                        validator.current.hideMessageFor("city");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                />
                                {validator.current.message("city", city, ["required", {maxChar20Validation:20}])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="streetAddress"
                                    required
                                    onChange={(e) => {
                                        setStreetAddress(e.target.value);
                                        validator.current.hideMessageFor("streetAddress");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.street_address")}
                                    value={streetAddress || ""}
                                />
                                {validator.current.message("streetAddress", streetAddress, ["required", {maxChar100Validation: 100}])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="address"
                                    // required
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        validator.current.hideMessageFor("address");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.address_building_name")}
                                />
                                {validator.current.message("address", address, [{addressValidation:100}])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    name="email"
                                    label={t("store_details.inquiry_email_address")}
                                    onChange={(e) => {
                                        setEnquiryEmailAddress(e.target.value);
                                        validator.current.hideMessageFor("email");
                                    }}                                    
                                    onBlur={(e)=>handleOnBlur(e)}/>
                                
                                {validator.current.message("email", inquiryEmailAddress, ["required", "email", {maxChar80Validation: 80 } ])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    name="inquiry_phone_number"
                                    label={t("store_details.inquiry_phone_number")}
                                    onChange={(e) => {
                                        setInquiryPhoneNumber(e.target.value);
                                        // validator.current.hideMessageFor("inquiry_phone_number");
                                    }}                              
                                    onInput={(e) => handleInputType(e)}       
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("inquiry_phone_number", inquiryPhoneNumber, [
                                    "required",
                                    {SingleBytePhoneValidation: [10,11]},
                                    { phoneValidation: [10, 11] },
                                ])}
                            </Grid>

                            <Grid item xs={0.7} sm={1} md={0.2}>
                                <Typography variant="h4" className="store_text_margin_left">T</Typography>
                            </Grid>
                            <Grid item xs={11} sm={5} md={5.8}>
                                        
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="qualified_billing_business_registration_number"
                                    label={t("store_details.qualified_billing_business_registration_number")}
                                    onChange={(e) => {
                                        setEligibleInvoiceIssueNumber(e.target.value);
                                        // validator.current.hideMessageFor("qualified_billing_business_registration_number");
                                    }}                                    
                                    onInput={(e) => handleInputType(e)}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("qualified_billing_business_registration_number", eligibleInvoiceIssueNumber, [{SingleByteCorporateNumber: 13},{ corporateNumber: 13 }])}
                                    
                               
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="inquiry_possible_time"
                                    label={t("store_details.inquiry_possible_time")}
                                    onChange={(e) => {

                                        setInquiryAvailableHours(e.target.value);
                                        validator.current.hideMessageFor("inquiry_possible_time");
                                    }}                                    
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message(
                                    "inquiry_possible_time",
                                    inquiryAvailableHours,
                                    [ { maxChar200Validation: 200 }]
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/*Company Information ends here */}

                {/* Product Representative Information start here */}
                <Box className="ragistration_box">
                    <Typography variant="h6">
                        {t("register_seller.product_representative")}
                    </Typography>
                    <Box className="bg-gray">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="lastName"
                                    required
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        validator.current.hideMessageFor("lastName");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.surname")}
                                />
                                {validator.current.message("lastName", lastName, ["required",{lastNameValidation:200}])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="firstName"
                                    required
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        validator.current.hideMessageFor("firstName");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.name")}
                                />
                                {validator.current.message("firstName", firstName, ["required",{firstNameValidation:200}])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    type="string"
                                    name="telephone"
                                    required
                                    onChange={(e) => {
                                        setTelephone(e.target.value);
                                        // validator.current.hideMessageFor("telephone");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    onInput={(e) => handleInputType(e)}
                                    label={t("register_seller.telephone_number")}
                                />
                                {validator.current.message("telephone", telephone, ["required",{SingleByteTelephoneValidation: [10,11]},{telephoneValidation:[10,11]}])}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* Product Representative Information ends here */}

                {/* Store Information starts here */}
                <Box className="ragistration_box">
                    <Typography variant="h6">
                        {t("register_seller.store_information")}
                    </Typography>
                    <Box className="bg-gray">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="storeName"
                                    required
                                    onChange={(e) => {
                                        setStoreName(e.target.value);
                                        validator.current.hideMessageFor("storeName");
                                    }}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    label={t("register_seller.store_name")}
                                />
                                {validator.current.message("storeName", storeName, ["required",{storeNameValidation:100}])}
                            </Grid>
                        </Grid>
                    </Box>
                    <Button className="fix_width_button" onClick={()=>handleSubmit()}>
                        {t("register_seller.register")}
                    </Button>
                </Box>
                {/* Store Information starts here */}
            </Container>
        </>
    );
};

export default SellerRegisterDetails;
